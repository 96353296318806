<!--<mat-card>-->
<!--  <mat-card-title><h3 class="center">{{title}}</h3></mat-card-title>-->
<!--  <div class="container-fluid">-->
<!--    <div class="row">-->
<!--      <div class="col-3">-->
<!--        <h1><b>457</b></h1>-->
<!--      </div>-->
<!--      <div class="col-9">-->
<!--        <p>libri nel giro 1</p>-->
<!--      </div>-->
<!--    </div>-->
<!--  </div>-->
<!--</mat-card>-->



<div class="ep_card">
  <div class="ep_card-header ep_card-header-icon ep_card-header-warning" [ngClass]="type">
    <div class="ep_card-icon"><i class="material-icons">{{icon}}</i></div>
    <p class="ep_card-category">{{title}}</p>
    <div class="row">
      <div class="col-2">
        <h3 class="ep_card-title">{{name}}</h3>
      </div>
      <div class="col-6">
        <h3 class="ep_card-title">{{number}}</h3>
      </div>
    </div>
  </div>
  <div class="ep_card-footer" *ngIf="subtitle">
    <mat-spinner *ngIf="loading" [diameter]="30" class="mx-auto"></mat-spinner>
    <div *ngIf="!loading">
      {{subtitle}}
    </div>
  </div>
</div>
