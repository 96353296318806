import { LocalStorageService } from './services/storage.service';
import { CarrelloService } from './services/carrello.service';
import { OauthService } from './services/oauthservice.service';
import { ActivatedRoute, Router } from '@angular/router';
import { MenuItem } from './components/nav-menu/nav-menu.component';
import { Component, OnInit } from '@angular/core';
import { eventModel, MessageService } from './services/message.service';
import { CartItem } from './models/cartItem.model';
import { SuccessOautResponse } from './models/oauth.model';

@Component({
  selector: 'ali-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit { 
  title = '';
  menuOpen = true;
  carrelloItems: Array<CartItem>;
  loadingCart = false;
  logged = false;
  constructor(
    private router: Router, 
    private carrelloservice: CarrelloService,
    private messageservice: MessageService,
    private outhservice: OauthService  
   
    ) {  
      if (this.outhservice.checkTokenIsValid()){
        this.getCarrello();
        this.logged = true;
      } else {
        this.logged = false;
      }
   // this.title = window.location.pathname.replace('/', '');  
    
    
    this.messageservice.listenEvent().subscribe((data:eventModel) => { 
      console.log(data);
        if (data.type==='cart') { 
          this.carrelloItems = data.event.items;
        } 
        if (data.type==='riepilogo-ordine') { 
          this.menuOpen = false;
          this.title = "Riepilogo ordine";
        } 
    });
  } 

  ngOnInit(): void {
    //Called after the constructor, initializing input properties, and the first call to ngOnChanges.
    //Add 'implements OnInit' to the class.
    
  }
  
  getCarrello() {
    this.loadingCart = true;
    this.carrelloservice.getCart().subscribe((data : any) => {
      if (data.success) {
        this.carrelloItems = data.items;
      }
      this.loadingCart = false;
    });
  }



  menuClicked($event: MenuItem) { 
    this.title = $event.label;
    this.router.navigateByUrl($event.routerLink);
  }
 

}
