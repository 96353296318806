import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import { MediaService } from 'src/app/services/media.service';
import {ToastrService} from 'ngx-toastr';
import * as _ from 'lodash';
import {eventModel, MessageService} from '../../../../services/message.service';

@Component({
  selector: 'ali-admin-media-manager',
  templateUrl: './admin-media-manager.component.html',
  styleUrls: ['./admin-media-manager.component.css']
})
export class AdminMediaManagerComponent implements OnInit {
  @Input() libro: any ;
  @Output() copertinaCaricata: EventEmitter<string> = new EventEmitter<string>();
  subscribed = false;
  deleting = false;
  media: any;
  fileTypes = [
    {
      title: 'PDF',
      type: 'file',
      mime: 'application/pdf',
      color: '#f9bb0b',
      icon: 'pdf'
    },
    {
      title: 'AUDIO',
      type: 'file',
      mime: 'audio/mpeg',
      color: '#4685F4',
      icon: 'mp3'
    },
    {
      title: 'VIDEO',
      type: 'VIDEO',
      mime: null,
      color: '#F34949',
      icon: 'mp4'
    }
  ];
  uploadLoading = false;
  uploadType = '';

  constructor(
    private mediaservice: MediaService,
    private messageservice: MessageService,
    private toastservice: ToastrService
  ) { }

  ngOnInit(): void {
    this.getMediaSize();
  }

  getMediaSize(){
    this.mediaservice.getMediaCollection(this.libro.codice).subscribe((data: any) => {
     this.media = data.data;
     const copertina = _.find(this.media, (o) => o.tipo_doc === 'ARTIMG');
     if (copertina){
       this.copertinaCaricata.emit(copertina.path);
     }
    });
  }

  submit(event){
    this.uploadLoading = true;
    this.uploadType = event.title;
    this.mediaservice.uploadMedia(event.content, event.type, this.libro.codice).subscribe(resp => {
      this.toastservice.success('File caricato con successo.');
      this.uploadLoading = false;
      this.uploadType = '';
      this.getMediaSize();
    }, (error) => {
      this.toastservice.error('Errore durante il caricamento del file, Riprova.');
      this.uploadLoading = false;
      this.uploadType = '';
    });
  }

  deleteItem(type, path){
    this.deleting = true;
    const index = _.findIndex(this.media, {path});
    this.media[index].path = 'eliminazione in corso...';
    const pathToDelete = path.replace('https://amazonstorageali.s3.eu-south-1.amazonaws.com/', '');
    this.mediaservice.deleteMedia(this.libro.codice, pathToDelete).subscribe(resp => {
      this.toastservice.success('File eliminato con successo.');
      this.media.splice(index, 1);
      this.deleting = false;
    }, (error) => {
      this.toastservice.error('Errore durante l\'eliminazione del file, Riprova.');
      this.deleting = false;
    });
  }

  basename(path){
    var base = new String(path).substring(path.lastIndexOf('/') + 1);
    if(base.lastIndexOf(".") != -1){
      base = base.substring(0, base.lastIndexOf("."));
    }
   return base.replace(/[^a-zA-Z0-9]/g,' ');
  }

  getIcon(ext: string) {
    return '/assets/file-iconset/32px/' + ext + '.png';
  }

}
