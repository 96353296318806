import { MediaBlockComponent } from './components/media-manager/media-block/media-block.component';
import { RicercaComponent } from './components/ricerca/ricerca.component';
import { LoginComponent } from './pages/login/login.component';
import { CatalogoFooterComponent } from './components/catalogo-footer/catalogo-footer.component';
import { SafeUrlPipe } from './pipes/safe-resource-url.pipe';
import {BrowserModule} from '@angular/platform-browser';
import {NgModule,LOCALE_ID} from '@angular/core';
import {AppRoutingModule} from './app-routing.module';
import {AppComponent} from './app.component';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {SharedModule} from './shared/shared.module';
import {ToastrModule} from 'ngx-toastr';
import {NgxEchartsModule} from 'ngx-echarts';
import { NavMenuComponent } from './components/nav-menu/nav-menu.component';
import { NavBarComponent } from './components/nav-bar/nav-bar.component';
import { CatalogoComponent } from './pages/catalogo/catalogo.component';
import { OrdiniComponent } from './pages/ordini/ordini.component';
import { ElencoDdtComponent } from './pages/elenco-ddt/elenco-ddt.component';
import { ElencoFattureComponent } from './pages/elenco-fatture/elenco-fatture.component';
import { ScadenziarioComponent } from './pages/scadenziario/scadenziario.component';
import { GestioneResiComponent } from './pages/gestione-resi/gestione-resi.component';
import { ImportaOrdineComponent } from './pages/importa-ordine/importa-ordine.component';
import { CartComponent } from './components/cart/cart.component';
import { CartItemComponent } from './components/cart-item/cart-item.component';
import { ImageComponent } from './components/image/image.component';
import { CatalogoGridComponent } from './components/catalogo-grid/catalogo-grid.component';
import { CatalogoTableComponent } from './components/catalogo-table/catalogo-table.component';
import { OrderQuantityComponent } from './components/order-quantity/order-quantity.component';
import {HomeComponent} from './pages/home/home.component';
import { NovitaHomeComponent } from './components/novita-home/novita-home.component';
import { NovitaItemComponent } from './components/novita-home/novita-item/novita-item.component';
import { CurrencyPipe } from './pipes/currency.pipe';
import { registerLocaleData } from '@angular/common';
import localeIt from '@angular/common/locales/it';
import { InSpedizioneComponent } from './components/ordini/in-spedizione/in-spedizione.component';
import { RimanenzaComponent } from './components/ordini/rimanenza/rimanenza.component';
import { CalendarComponent } from './components/calendar/calendar.component';
import { CalendarModule, DateAdapter } from 'angular-calendar';
import { adapterFactory } from 'angular-calendar/date-adapters/date-fns';
import { FormatDatePipe } from './pipes/format-date.pipe';
import { DateDiffPipe } from './pipes/date-diff.pipe';
import { TabellaScadenzeComponent } from './pages/scadenziario/tabella-scadenze/tabella-scadenze.component';
import { ViewOrderComponent } from './pages/ordini/view-order/view-order.component';
import { RiepilogoComponent } from './pages/catalogo/riepilogo/riepilogo.component';
import { ProfiloComponent } from './pages/profilo/profilo.component';
import { RigheDdtComponent } from './pages/elenco-ddt/righe-ddt/righe-ddt.component';
import { ElencoOrdiniComponent } from './pages/elenco-ordini/elenco-ordini.component';
import { DisplayTableComponent } from './components/display-table/display-table.component';
import {IvyCarouselModule} from 'angular-responsive-carousel';
import { ImageCarouselComponent } from './components/image-carousel/image-carousel.component';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { LibroCedolaComponent } from './components/libro-cedola/libro-cedola.component';
import {CarouselModule} from 'ngx-owl-carousel-o';
import { HomeModalComponent } from './components/home-modal/home-modal.component';
import { ArchivioResiComponent } from './pages/gestione-resi/archivio-resi/archivio-resi.component';
import { MAT_DATE_LOCALE } from '@angular/material/core';
import { DettaglioComponent as DettaglioFatture  } from './pages/elenco-fatture/dettaglio/dettaglio.component';
import { DettaglioComponent } from './pages/catalogo/dettaglio/dettaglio.component';
import { ResaConfirmComponent } from './pages/resa-confirm/resa-confirm.component';
import { HttpConfigInterceptor } from './intreceptor/httpconfig.interceptor';
import { BookTitlePipe } from './pipes/book-title.pipe';
import { StatoDescrizionePipe } from './pipes/stato-descrizione.pipe';
import { StatoArticoloPipe } from './pipes/stato-articolo.pipe';
import { ScontoPipe } from './pipes/sconto.pipe';
import { MediaManagerComponent } from './components/media-manager/media-manager.component';
import { NovitaInOrdineComponent } from './pages/novita-in-ordine/novita-in-ordine.component';
import { ImagesListComponent } from './components/images-list/images-list.component';
import { ImageUploadComponent } from './components/image-upload/image-upload.component';
import { ProgressBarComponent } from './components/media-manager/progress-bar/progress-bar.component';
import { AdminComponent } from './pages/home/admin/admin.component';
import { FormatFileSizePipe } from './pipes/format-file-size.pipe';
import { AdminMediaManagerComponent } from './pages/catalogo/dettaglio/admin-media-manager/admin-media-manager.component';

registerLocaleData(localeIt);

@NgModule({
  declarations: [
    AppComponent,
    SafeUrlPipe,
    FormatDatePipe,
    DateDiffPipe,
    NavMenuComponent,
    NavBarComponent,
    CatalogoComponent,
    OrdiniComponent,
    ElencoDdtComponent,
    ElencoFattureComponent,
    ScadenziarioComponent,
    GestioneResiComponent,
    ImportaOrdineComponent,
    CartComponent,
    CartItemComponent,
    ImageComponent,
    CatalogoGridComponent,
    CatalogoTableComponent,
    OrderQuantityComponent,
    HomeComponent,
    NovitaHomeComponent,
    NovitaInOrdineComponent,
    NovitaItemComponent,
    CatalogoFooterComponent,
    LoginComponent,
    CurrencyPipe,
    InSpedizioneComponent,
    RimanenzaComponent,
    CalendarComponent,
    TabellaScadenzeComponent,
    ViewOrderComponent,
    RiepilogoComponent,
    ProfiloComponent,
    RigheDdtComponent,
    ElencoOrdiniComponent,
    DisplayTableComponent,
    ImageCarouselComponent,
    LibroCedolaComponent,
    HomeModalComponent,
    RicercaComponent,
    ArchivioResiComponent,
    DettaglioFatture,
    DettaglioComponent,
    ResaConfirmComponent,
    BookTitlePipe,
    StatoDescrizionePipe,
    StatoArticoloPipe,
    ScontoPipe,
    MediaManagerComponent,
    NovitaInOrdineComponent,
    ImagesListComponent,
    ImageUploadComponent,
    MediaBlockComponent,
    ProgressBarComponent,
    AdminComponent,
    FormatFileSizePipe,
    AdminMediaManagerComponent,
  ],
    imports: [
        BrowserModule,
        AppRoutingModule,
        BrowserAnimationsModule,
        AppRoutingModule,
        SharedModule,
        ToastrModule.forRoot({
            timeOut: 10000,
            positionClass: 'toast-top-center',
        }),
        CalendarModule.forRoot({
          provide: DateAdapter,
          useFactory: adapterFactory,
        }),
        NgxEchartsModule.forRoot({
            echarts: () => import('echarts'),
        }),
        IvyCarouselModule,
        CarouselModule
    ],
  providers: [
    { provide: LOCALE_ID, useValue: 'it-IT'},
    {provide: MAT_DATE_LOCALE, useValue: 'it-IT'},
    { provide: HTTP_INTERCEPTORS, useClass: HttpConfigInterceptor, multi: true }
],
  bootstrap: [AppComponent]
})
export class AppModule {}
