import { CheckResponse, Articolo } from './../../models/catalogo.model';
import { MessageService } from './../../services/message.service';
import { CatalogoService } from './../../services/catalogo.service';
import { Component, OnInit, ViewChild } from '@angular/core'; 
import * as XLSX from 'xlsx';
import { CSVRecord } from './ordine.model';
import * as _ from 'lodash'; 
import { MatSnackBar } from '@angular/material/snack-bar';

@Component({
  selector: 'ali-importa-ordine',
  templateUrl: './importa-ordine.component.html',
  styleUrls: ['./importa-ordine.component.css']
})
export class ImportaOrdineComponent implements OnInit {
  import = 0;
  isbns = [];
  unvalidISBNS:Array<string> = [];
  public records = []; 
  validElements = []; 
  displayedColumns: string[] = ['codiceISBN', 'quantita'];
  /**  *public codiceISBN: any;
    public quantita: any;
     */
  constructor(
    private catalogoservice: CatalogoService,
    private _snackBar: MatSnackBar,
    private messageservice : MessageService
  ) { }
   
  ngOnInit(): void {
    this.import= 0;
  }

  undoImport() {
    this.import= 0;
    this.records = [];
  }

  /**
   * onFileChange
   * analizza il file lato client
   * @param evt 
   */

  onFileChange(evt: any) {
    this.records = [];
    this.unvalidISBNS = [];
    this.isbns = [];
    /* wire up file reader */
    const target: DataTransfer = <DataTransfer>(evt.target);
    if (target.files.length !== 1) throw new Error('Cannot use multiple files');
    const reader: FileReader = new FileReader();
    reader.onload = (e: any) => {
      /* read workbook */
      const bstr: string = e.target.result;
      const wb: XLSX.WorkBook = XLSX.read(bstr, {type: 'binary'}); 
      /* grab first sheet */
      const wsname: string = wb.SheetNames[0];
      const ws: XLSX.WorkSheet = wb.Sheets[wsname];
      const data = XLSX.utils.sheet_to_json(ws, {header: 1});
      data.forEach((item: CSVRecord) => {
        this.isbns.push(item[0]);
        this.records.push({  
          codiceISBN: item[0], 
          quantita: item[1],  
        });
      });
      this.checkIbsns();
    };
    reader.readAsBinaryString(target.files[0]);
  }
  /**
   * checkIbsns
   */
  public checkIbsns() {
    this.catalogoservice.checkISBNS(this.isbns).subscribe((data: CheckResponse) => {
          if(data.errors.length) {
            data.errors.forEach((element) => {
                this.unvalidISBNS.push(element);
            });
          }
          this.validElements = data.valid;
    });
  }

  public doImport() {
    let items = [];  
    this.validElements.forEach((element:Articolo) => {
          const e = _.find(this.records, { 'codiceISBN': Number(element.ean) });
          items.push({cod : element.codice, qta: e.quantita});
      });
     
   this.catalogoservice.doImport(items).subscribe((data: any) => {
      this.messageservice.setEvent({type:'adj-cart', event: data});
      this.messageservice.setEvent({type : 'cart', event: data});
      this.import = 1;
      this.openSnackBar("L'importazione ha avuto successo", 'OK');

    }, (error) => { 
      this.openSnackBar("Si sono verificati dei problemi durante l'importazione dell'ordine", 'OK');
    }); 
  }

  isValid(isbn):boolean {
    const i = _.indexOf(this.unvalidISBNS, isbn);
    if (i > -1) {
      return false;
    }
    return true;
  }


  openSnackBar(message: string, action: string) {
    this._snackBar.open(message, action);
  }
}
