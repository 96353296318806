import { MatTableDataSource } from '@angular/material/table';
import { Component, Inject, Input, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import * as moment from 'moment';
import * as _ from 'lodash'; 
import { formatCurrency } from '@angular/common';
import { Utilities } from 'src/app/utilities/utilities.class';
export interface DisplayTableData {
  data:Array<any>;
  displayNames: Array<string>;
  fieldNames: Array<string>;
  title: '';
  numbers: Array<string>;
  totali: Array<string>;
}
@Component({
  selector: 'ali-display-table',
  templateUrl: './display-table.component.html',
  styleUrls: ['./display-table.component.css']
})

export class DisplayTableComponent implements OnInit {
  dataExport = [];
  datasource;

  constructor(public dialogRef: MatDialogRef<DisplayTableComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DisplayTableData) {}

  ngOnInit(): void {
    this.datasource = new MatTableDataSource(this.data.data);
    this.dataExport = this.buildDataExport(this.data.data);
  }
  
  sortData($event) {}


  formatNumber(value, fieldname: string) {
    if (this.data.numbers.indexOf(fieldname) > -1) {
      return formatCurrency(value, 'IT', ''); 
    }
    return value;
  }

  totale(fieldname: string) {
   if (this.data.totali.indexOf(fieldname) > -1) {
      const data = Utilities.castToNumber(this.datasource.data, [fieldname]);  
      return this.formatNumber(_.sumBy(data, fieldname), fieldname); 
   }
    return '';
  }

  format(value) {
    if (this.isNumber(Number(value))) {
        return 'text-right';
    }
    if (this.isDate(value)) {
        return 'text-center';
    }
    return 'text-left';
}

isDate(value) {
    if (moment(value, "DD-MM-YYYY", true).isValid()){
        return true;
    }
    return false;
}

isNumber(value) {
    return Number(value) === value
}

buildDataExport(collection:any){
 let dataExport = [];
  let row= {};
  collection.forEach(element =>{
    this.data.fieldNames.forEach((column, index) => {
      row[this.data.displayNames[index]]=element[column];
    });
    dataExport.push(row);
    row = {};
  });
  return dataExport; 
}
 
}

 