import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import {MatCheckboxModule} from '@angular/material/checkbox';
import {MatSliderModule} from '@angular/material/slider';
import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {DateAdapter, MatCommonModule, MatLineModule, MatNativeDateModule, MatOptionModule, MatPseudoCheckboxModule, MatRippleModule, MAT_DATE_LOCALE} from '@angular/material/core';
import {MatInputModule} from '@angular/material/input';
import {MatCardModule} from '@angular/material/card';
import {MatToolbarModule} from '@angular/material/toolbar';
import {MatButtonModule} from '@angular/material/button';
import {MatTabsModule} from '@angular/material/tabs';
import {MatGridListModule} from '@angular/material/grid-list';
import {MatProgressSpinnerModule} from '@angular/material/progress-spinner';
import {MatTooltipModule} from '@angular/material/tooltip';
import {MatPaginatorModule} from '@angular/material/paginator';
import {MatDividerModule} from '@angular/material/divider';
import {MatAutocompleteModule} from '@angular/material/autocomplete';
import {MatIconModule} from '@angular/material/icon';
import {MatDialogModule} from '@angular/material/dialog';
import {MatListModule} from '@angular/material/list';
import {MatChipsModule} from '@angular/material/chips';
import {MatMenuModule} from '@angular/material/menu';
import {MatDatepickerModule} from '@angular/material/datepicker';
import {MatProgressBarModule} from '@angular/material/progress-bar';
import {MatRadioModule} from '@angular/material/radio';
import {MatSelectModule} from '@angular/material/select';
import {MatSidenavModule} from '@angular/material/sidenav';
import {MatBadgeModule} from '@angular/material/badge';
import {MatTableModule} from '@angular/material/table';
import {MatButtonToggleModule} from '@angular/material/button-toggle';
import { MomentDateAdapter } from '@angular/material-moment-adapter';  
import { MatSortModule } from '@angular/material/sort';
import {MatSnackBarModule} from '@angular/material/snack-bar';

export const MY_FORMATS = {
  parse: {
      dateInput: 'DD/MM/YYYY',
  },
  display: {
      dateInput: 'DD/MM/YYYY',
      monthYearLabel: 'MM YYYY',
      dateA11yLabel: 'DD/MM/YYYY',
      monthYearA11yLabel: 'MM YYYY',
  },
};

const MATMODULES = [
  MatCommonModule,
  MatSliderModule,
  MatNativeDateModule,
  MatOptionModule,
  MatPseudoCheckboxModule,
  MatLineModule,
  MatRippleModule,
  MatInputModule,
  MatCardModule,
  MatToolbarModule,
  MatButtonModule,
  MatTabsModule,
  MatGridListModule,
  MatProgressSpinnerModule,
  MatProgressBarModule,
  MatTooltipModule,
  MatPaginatorModule,
  MatDividerModule,
  MatAutocompleteModule,
  MatIconModule,
  MatDialogModule,
  MatListModule,
  MatChipsModule,
  MatMenuModule,
  MatDatepickerModule,
  MatCheckboxModule,
  MatRadioModule,
  MatSelectModule,
  MatSidenavModule,
  MatBadgeModule, 
  MatButtonToggleModule,
  MatRippleModule,
  MatTableModule,
  MatSortModule,
  MatSnackBarModule
];

const COMPONENTS = [];
@NgModule({
  declarations: [],
  imports: [
    CommonModule,BrowserAnimationsModule, ...MATMODULES
  ],
  providers: [
    {
      provide: MAT_DATE_LOCALE,
      useValue: {
        provide: DateAdapter,
        useClass: MomentDateAdapter,
        deps: [MAT_DATE_LOCALE]
     }}
  ],
  exports: [
    CommonModule,
    ...COMPONENTS, ...MATMODULES
  ],
})

export class MaterialModule {}
