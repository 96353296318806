import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Catalogo } from 'src/app/models/catalogo.model';
import moment from 'moment';
import { ArticoloTools } from 'src/app/models/articolo.model';
import { iUser } from 'src/app/models/user.model';

@Component({
  selector: 'ali-catalogo-table',
  templateUrl: './catalogo-table.component.html',
  styleUrls: ['./catalogo-table.component.css']
})
export class CatalogoTableComponent implements OnInit {
  @Input() catalogo: Catalogo;
  @Input() user:iUser;
  @Output() carrelloChange = new EventEmitter<any>();
  @Output() clickScheda = new EventEmitter<any>();
  @Output() manageMedia = new EventEmitter<any>();
  articoloTools: ArticoloTools = new ArticoloTools();
  constructor() { }

  ngOnInit(): void {
  }

  formatData(data) {
    return moment(data, 'YYYYMMDD').format('DD-MM-YYYY');
  }


  schedaProdotto($event) {
     this.clickScheda.emit($event);
  }

  openMediaManager($event) {
    this.manageMedia.emit($event);
  }

  setQta(event) {
    this.carrelloChange.emit(event);
  }
}
