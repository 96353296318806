import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import { ToastrService } from 'ngx-toastr';
import { MediaService } from 'src/app/services/media.service';
import {DisplayTableData} from '../../pages/catalogo/dettaglio/dettaglio.component';

@Component({
  selector: 'ali-media-manager',
  templateUrl: './media-manager.component.html',
  styleUrls: ['./media-manager.component.css']
})
export class MediaManagerComponent implements OnInit {
   flagaudio=false;
   flagimage=false;
   flagpdf=false;
  images = [];
  mediaInfo;
  progressBar = [
    {field: 'ARTIMG',color: '#36a954', label: 'Immagini', value: 0},
    {field: 'AUDIO',color: '#4685F4', label: 'Audio', value: 0},
    {field: 'PDF',color: '#f9bb0b', label: 'Pdf', value: 0},
  ]
  private additionalImages = new FormData();
  uploadLoading = false;
  constructor(private mediaservice: MediaService,private toastservice: ToastrService,public dialogRef: MatDialogRef<MediaManagerComponent>,
              @Inject(MAT_DIALOG_DATA) public data: DisplayTableData) {
  }

  ngOnInit(): void {
    this.getMediaSize();
  }

  getMediaSize(){
    this.mediaservice.getMediaCollection(this.data.element.codice).subscribe(data=>{
      console.log(this.mediaInfo);
      this.mediaInfo = data;
      this.progressBar.forEach((item, index)=>{
        item.value = this.mediaInfo.sizes[item.field];
      })
    });
  }

  submit(event){
    this.uploadLoading = true;
    this.mediaservice.uploadMedia(event.content,event.type,this.data.element.codice).subscribe(resp =>{
      this.toastservice.success('File caricato con successo.');
      console.log(event.type);
      this.uploadLoading = false;
    },(error)=>{
      this.toastservice.error('Errore durante il caricamento del file, Riprova.');
      this.uploadLoading = false;
    });
  }

}
