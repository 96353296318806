<ng-container *ngIf="!user.isAdmin()">
  <ali-novita-home  *ngIf="!loader && !firstLogin" ></ali-novita-home> 


  <mat-card class="m-3" *ngIf="spediti_oggi && spediti_oggi.length" >
    <mat-card-header>
      <mat-card-title   class="mat-card-title fs16 colorPrimary">Hai della merce spedita oggi ! ( {{spediti_oggi.length}} <span *ngIf="spediti_oggi.length===1" > documento </span><span *ngIf="spediti_oggi.length > 1" > documenti </span>  ) </mat-card-title>
      <mat-card-subtitle>Clicca <a href="/elenco-ddt">qui</a> per vedere l'elenco completo</mat-card-subtitle>
    </mat-card-header> 
  </mat-card>
  <mat-card class="m-3" *ngIf="spediti_ieri && spediti_ieri.length && spediti_oggi.length===0"  > <!--  -->
    <mat-card-header>
      <mat-card-title   class="mat-card-title fs16 colorPrimary">Hai della merce spedita ieri !  ( {{spediti_ieri.length}} <span *ngIf="spediti_ieri.length===1" > documento </span><span *ngIf="spediti_ieri.length > 1" > documenti </span>  ) </mat-card-title>
      <mat-card-subtitle>Clicca <a href="/elenco-ddt">qui</a> per vedere l'elenco completo</mat-card-subtitle>
    </mat-card-header> 
  </mat-card>

  <mat-card class="m-3" *ngIf="in_spedizione && in_spedizione.length" >
    <mat-card-header>
      <mat-card-title   class="mat-card-title fs16 colorPrimary">Hai della merce in spedizione</mat-card-title>
      <mat-card-subtitle>Clicca <a href="/articoli-in-ordine/in_spedizione">qui</a> per vedere l'elenco completo</mat-card-subtitle>
    </mat-card-header> 
  </mat-card>



  <div *ngIf="!loader && !firstLogin" >
      <ali-image-carousel   *ngFor="let cedola of cedole" [items]="cedola" ></ali-image-carousel>
  </div>
  <div class="loading-shade" *ngIf="loader">
      <mat-spinner></mat-spinner>
  </div>

 <div class="card" *ngIf="firstLogin"> 
    <mat-card class="example-card mat-elevation-z8">
        <mat-card-header>
          <mat-card-title>Benvenuti</mat-card-title> 
        </mat-card-header>
        <mat-card-content> 
        <p>
          Benvenuti nella nuova piattaforma librerie di A.L.I. Srl Agenzia Libraria International
          All’interno troverete tante novità e aggiornamenti continui.
          Grazie come sempre per la fiducia che ci accordate. Vi auguriamo una buona navigazione!
        </p> 
        <p>
          Sarai rediretto alla tua pagina del profilo per cambiare la password.
        </p>
        </mat-card-content>
        <mat-card-actions>
          <button mat-raised-button color="primary" (click)="onSubmit()">Ho Capito!</button>
        </mat-card-actions>
    </mat-card>
  </div>
</ng-container>
<ng-container *ngIf="user.isAdmin()">
  <ali-admin></ali-admin>
</ng-container>