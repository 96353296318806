<div [ngClass]="type && type==='file' ? 'block' : 'blockAlt'" [style.background-color]="color" class="{{myclass}}">
  <ng-container *ngIf="type && type==='file'">
    <!-- <img src="assets/images/{{icon}}.png" alt="" height=100px > -->
    <button (click)="file.click()" class="buttone pointer">
      {{loading && loadingType === title ? 'Caricamento in corso...' : 'Aggiungi file'}}
    </button>
    <input class="hide" [style.background-color]="myclass" [type]="type"
           (change)="setContent(type,$event.target.files[0])" #file [accept]="acceptedMimeType">
    <div *ngIf="filename.length > 0" class="input-file-upload">
      <span>{{filename}}</span>
      <button mat-icon-button (click)="undoFile(type)">
        <mat-icon>close</mat-icon>
      </button>
    </div>
  </ng-container>

  <ng-container *ngIf="type && type==='VIDEO'">

    <mat-form-field class="example-full-width">
      <mat-label>Aggiungi link video</mat-label>
      <input
        matInput
        placeholder="https://..."
        [type]="type"
        [formControl]="formCtrl">

    </mat-form-field>

  </ng-container>
  <button type="submit" class="submit-button"
          [ngClass]="!disableSubmit ? 'pointer' : ''"
          [ngStyle]="{'background-color': disableSubmit ? 'lightgrey' : myclass}"
          (click)="submit(type)"
          [disabled]="disableSubmit">Invia
  </button>
<!--  <mat-progress-spinner mode="indeterminate" diameter="20" *ngIf="!loading"></mat-progress-spinner>-->
</div>
