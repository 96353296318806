import { Pipe, PipeTransform } from '@angular/core';
import {formatCurrency} from '@angular/common';

@Pipe({
  name: 'sconto'
})
export class ScontoPipe implements PipeTransform {

  transform(value: number, ...args: unknown[]): unknown {
    // return formatCurrency(value, 'IT', '€');
    const app = '' + value;
    return '' + app.replace('.', ',') + '%';
  }

}
