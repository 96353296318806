import { BookService } from 'src/app/services/book.service';
import { LocalStorageService } from './../../services/storage.service';
import { MessageService, eventModel } from './../../services/message.service';
import { CarrelloService } from './../../services/carrello.service';
import {CatalogoService, Filter} from './../../services/catalogo.service';
import {Component, Input, OnChanges, OnInit, ViewChild} from '@angular/core';
import {Book, Catalogo} from 'src/app/models/catalogo.model';
import {animate, state, style, transition, trigger} from '@angular/animations';
import {Ricerca} from './../../models/ricerca.model';
import * as _ from 'lodash';
import { CartItem } from 'src/app/models/cartItem.model';
import { SimpleChanges } from '@angular/core';
import { DettaglioComponent } from './dettaglio/dettaglio.component';
import { MatDialog } from '@angular/material/dialog';
import {MediaManagerComponent} from '../../components/media-manager/media-manager.component';
import {MediaService} from '../../services/media.service';
import { iUser } from 'src/app/models/user.model';

@Component({
  selector: 'ali-catalogo',
  templateUrl: './catalogo.component.html',
  styleUrls: ['./catalogo.component.css'],
  animations: [
    trigger('detailExpand', [
      state('collapsed', style({height: '0px', minHeight: '0'})),
      state('expanded', style({height: '*'})),
      transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
    ]),
  ],
})


export class CatalogoComponent implements OnInit {
  public search = '';
  public searchField = 'titolo';
  public defaultOrder = 'data_pubblicazione';
  public defaultSort = 'desc';
  public advancedSearch: boolean;
  public autore = '';
  @Input() cartItems: CartItem[] = [];
  catalogo: Catalogo;
  public loader = false;
  public page = 1;
  public view = 'grid';
  public order = '-df';
  public searchFilters = [];
  user:iUser = new iUser(this.storage.get('usr'));
  public orderType = [
     { cod : 'prezzo', descrizione: 'prezzo crescente' },
     { cod : '-prezzo', descrizione: 'prezzo decrescente' },
     { cod : '-disponibilita', descrizione: 'maggiore disponibilità' },
     { cod : '-df', descrizione: 'più recenti' },
  ];

  ricerca: Ricerca = {
    fields: [
      {
        field: 'titolo',
        option: {
          type: 'text'
      }
      },
      {
        field: 'ean',
        option: {
          type: 'text'
      }
      },
      {
        field: 'autore',
        option: {
            type: 'text'
        }
      },
      {
        field: 'editore',
        option: {
          type: 'text'
        }
      },
      {
          field: 'collana',
          option: {
            type: 'text'
          }
      }
    ]
  };


  constructor(
    private catalogoservice: CatalogoService,
    private carrelloservice: CarrelloService,
    private messageservice: MessageService,
    private storage: LocalStorageService,
    private bookservice: BookService,
    private mediaService: MediaService,
    public dialog: MatDialog
    ) {
      const prefView = this.storage.get('prefView');
      if (prefView) {
        this.view = prefView;
      }

  }

  ngOnInit(): void {
    this.advancedSearch = false;
    this.getData(this.page);
    /* this.getEditori();
    this.getCollane(); */
  }

  public cercaCatalogo() {
    this.getData(1, this.catalogo.per_page);
  }


  public changePage($event) {
    this.getData($event.pageIndex, $event.pageSize);
  }

  public changeView($event) {
    this.view = $event;
  }

  public addToChart($event) {
      this.carrelloservice.setItemToCart($event.codice, $event.qta).subscribe((data) => {
        this.messageservice.setEvent({type : 'cart', event: data});
      });
  }

  public dettaglio($event) {
    this.bookservice.dettaglio($event.codice).subscribe((data) => {
      const dialogRef = this.dialog.open(DettaglioComponent, {
        data: {
          info: data,
          element: $event
        },
        width: '60%',
      });
  });
}

  mediaManager($event) {
    // this.mediaService.list($event.codice).subscribe( (data) => {});
    const dialogRef = this.dialog.open(MediaManagerComponent, {
      data: {
        info: $event,
        element: $event
      },
      height: '80%',
      width: '50%', 
    });
  }

  public getData(page, size = 10) { 
    this.loader = true;
    this.catalogoservice.getCollectionPaginated('catalogo', page, size, this.searchFilters, this.order).subscribe((r: Catalogo) => {
      this.catalogo = r;
      this.loader = false;
    });
  }

  orderBy(order) {
    this.order = order;
    this.getData(1);
  }

  // riceve l'outuput del componente ricerca
  getSearchFilters(data: any) {
    if(data.event === 'search'){
      this.searchFilters = data.value;
    }
    if(data.event === 'order'){
      this.order = data.value;
    }
    this.cercaCatalogo();
  }

   getEditori(){
     this.bookservice.editori().subscribe((editori:any) =>{
      this.loadLista(editori, 'editore');
     })
   }

   getCollane(){
    this.bookservice.collane('').subscribe((collane:any) =>{
     this.loadLista(collane, 'collana');
    })
  }
  /**
   * Funzione che reimposta i filtri delle ricerca
   */
  resetForm(event){
    if (event){
      this.defaultOrder = '-df';
      this.defaultSort = 'desc';
      this.searchFilters = [];
    }
  }

  ricercaAvanzata(e) {
    console.log(e);
    this.advancedSearch = e;
  }

  loadLista(data, field) {
    const i = _.findIndex(this.ricerca.fields, {field : field});
    this.ricerca.fields[i].option.list = [];
    data.forEach(element => {
      this.ricerca.fields[i].option.list.push(element);
    });
  }
}
