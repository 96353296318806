import { Component, Input, OnInit } from '@angular/core';
import * as XLSX from 'xlsx';
import * as moment from 'moment';
import pdfMake from 'pdfmake/build/pdfmake';
import pdfFonts from 'pdfmake/build/vfs_fonts';
import * as config from './config.json';

@Component({
  selector: 'ali-export-table',
  templateUrl: './export-table.component.html',
  styleUrls: ['./export-table.component.css']
})
export class ExportTableComponent implements OnInit {
  @Input() data: Array<any> = [];
  @Input() filename: string = 'export';
  @Input() title: string = '';

  constructor() {
    pdfMake.vfs = pdfFonts.pdfMake.vfs;
  }
  /**Oggetto di configurazione base del pdf, i valori sono modificabili dal file config.json */
  docDefinition = {
    pageSize: config.pageSize,
    pageOrientation: config.pageOrientation,
    content: [
      {
        "text" :'',
        "fontSize" : config.title.fontSize,
        "lineHeight" :config.title.lineHeight,
        "bold" : config.title.bold,
        "italics" : config.title.italics,
        "alignment" : config.title.alignment,
        "characterSpacing" : config.title.characterSpacing,
      },
      {
        layout: (config.doubleColor) ? 'doubleColor' : 'lightHorizontalLines',
        table: {
          headerRows: 1,
          widths: [],
          body: []
        },
      }
    ]
  };
  values = [];
  excelHeader = [];


  ngOnInit(): void {

    this.docDefinition.content[0].text = this.title;
    this.buildHeaders(this.data[0]);
    this.buildRows(this.data);
   
  }
  /**Costriusco la prima riga della tabella che rappresenta gli headers
   * (leggo soltanto le chiavi di un singolo oggetto dato in input) */
  buildHeaders(headerCols:any)  {
    let header = [];
    let keys = Object.keys(headerCols);
    keys.forEach((item)=>{
      item = item.replace('_', ' ');
      this.format(item);
      header.push({
        "text" : item,
        "fontSize" : config.header.fontSize,
        "lineHeight" :config.header.lineHeight,
        "bold" : config.header.bold,
        "italics" : config.header.italics,
        "alignment" : config.header.alignment,
        "characterSpacing" : config.header.characterSpacing,
      });
      this.docDefinition.content[1].table.widths.push('auto');
    })
    this.docDefinition.content[1].table.body.push(header);
    this.excelHeader.push(header);
  }

  /**
   * Per ogni riga, creo un oggetto apposito impostando valore e stili
   */
  buildRows(data:Array<any>): void  {
    data.forEach((item)=> {
      //if(index > 0){
        let row = [];
        let keys = Object.keys(item);
        keys.forEach((chiave)=>{
          this.format(item[chiave]);
          row.push({
            "text" : item[chiave],
            "fontSize" : config.body.fontSize,
            "lineHeight" :config.body.lineHeight,
            "bold" : config.body.bold,
            "italics" : config.body.italics,
            "alignment" : config.body.alignment,
            "characterSpacing" : config.body.characterSpacing
          });
        });
        this.docDefinition.content[1].table.body.push(row);
      this.values.push(row);
     // }
    });
  }

  /**
   * Funzione che converte la tabella Html in excel
   */
  public exportexcel(): void {
    const ws: XLSX.WorkSheet =XLSX.utils.json_to_sheet(this.data);

    const wb: XLSX.WorkBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');

    XLSX.writeFile(wb, `${this.filename}.xlsx`);
  }

  /**
   * Creo un nuovo layout per la tabella e genero il pdf
   */
  public generatePdf(): void{
    if(Object.keys(this.data[0]).length > 8){
      this.docDefinition.pageOrientation = 'landscape';
    }
    pdfMake.tableLayouts = {
      doubleColor: {
        fillColor: function (i) {
          return i % 2 === 0 ?   '#d4d4d4' : '#ffffff';
        }
      }
    };
    pdfMake.createPdf(this.docDefinition).open();
  }

  /**
   * Formatta l'allineamento in base al valore dato
   */
  public format(value): string {
    if (this.isNumber(value)) {
        config.body.alignment = 'right';
        return 'right';
    }
    if (this.isDate(value)) {
        config.body.alignment = 'center';
        return 'center';
    }
    config.body.alignment = 'left';
    return 'left';
  }

  /**
   * Controlla se il valore inserito è una data
   */
  private isDate(value): boolean {
      if (moment(value, "DD-MM-YYYY", true).isValid()){

          return true;
      }
      return false;
  }

  /**
   * Controlla se il valore inserito è di tipo numerico
   */
  private isNumber(value): boolean{
      return Number(value) === value
  }

}
