import {Injectable, Injector} from '@angular/core';
import {Router} from '@angular/router';
import {environment} from 'src/environments/environment';
import {LocalStorageService} from './storage.service';
import {SuccessOautResponse} from '../models/oauth.model';
import {HttpHeaders, HttpClient, HttpParams} from '@angular/common/http';
import {Subscribable} from 'rxjs';

export interface Filter {
    name: string;
    value: any;
} 

@Injectable({
    providedIn: 'root'
})
export class CatalogoService {
    API_BASE: string = environment[environment.env].base_url;
    cache = null;

    constructor(
        private injector: Injector,
        private httpclient: HttpClient,
        private localStorage: LocalStorageService,
    ) {
    }

    /** home page */
    getCedole() {
      const serviceHeaders = this.getHeaders();
      const options = { headers: serviceHeaders};
      return this.injector.get(HttpClient).get(`${this.API_BASE}/cedole`, options);
    }

    getStats(kind) {
      const serviceHeaders = this.getHeaders();
      const options = { headers: serviceHeaders}; 
      const uri = (kind ==='spediti') ? 'stats/spediti' : 'stats/in_spedizione';  
      return this.injector.get(HttpClient).get(`${this.API_BASE}/${uri}`, options);
    }


   /*  getWiki(title: string) {
        const tempTitle = title.replace(' ', '_') + '?redirect=true';
        const baseUrl = 'https://it.wikipedia.org/api/rest_v1/page/summary/';
        return this.httpclient.get<any>(baseUrl+tempTitle);
      } */

    getImage(codice: string) {
      const baseUrl = `https://amazonstorageali.s3.eu-south-1.amazonaws.com/img/`;
      return this.httpclient.get(baseUrl+codice+'.jpg', { responseType: 'blob' })
     }



  /** servizio di validazione degli ISBNS */   
  public checkISBNS($isbns) {
    const serviceHeaders = this.getHeaders();
    const options = { headers: serviceHeaders};
    const payload = {
      'items' : $isbns
    }
    return this.injector.get(HttpClient).post(`${this.API_BASE}/check`, payload, options);
  }

  /** servizio di inserimento degli articoli nel carrello */
  public doImport($items) {
    /**
     * 
     * {
          "items" : [
              {"cod" :  "883229211" , "qta": 2}
          ]
        }   
     * 
     */
 
    const serviceHeaders = this.getHeaders();
    const options = { headers: serviceHeaders};
    const payload = {
      'items' : $items
    } 

    return this.injector.get(HttpClient).post(`${this.API_BASE}/ordine/import`, payload, options);

  }

  public getBooksOfTheWeek(page = 1) {
    const serviceHeaders = this.getHeaders();
    const options = { headers: serviceHeaders};
    return this.injector.get(HttpClient).get(`${this.API_BASE}/novita?page=${page}`, options);
  }

  //restituisce tutte le novita' della settimana con (se presenti) le quantita' gia' ordinate 
  public getOrderedBooksOfTheWeek() {
    const serviceHeaders = this.getHeaders();
    const options = { headers: serviceHeaders};
    return this.injector.get(HttpClient).get(`${this.API_BASE}/novita-ordini`, options);
  }


  public getCollection(resource: string,  orderby?: string, filters?: Array<Filter>) {
    let payload = new HttpParams();
    const serviceHeaders = this.getHeaders();
    if (orderby) {
      payload = payload.append(`sort`, orderby);
    }
    if (filters && filters.length > 0) {
      filters.forEach((filter: Filter) => {
        payload = payload.append(`filter[${filter.name}]`, filter.value);
      });
    }
    const options = { headers: serviceHeaders, params: payload };  
    return this.injector.get(HttpClient).get(`${this.API_BASE}/${resource}`, options);
  }

  public getCollectionPaginated(
    resource: string,
    page: number = 1,
    pageSize: number = 20,
    filters?: Array<Filter>,
    orderby?: string
  ) {
    const serviceHeaders = this.getHeaders();
    let payload = new HttpParams();
    if (orderby) {
      payload = payload.append(`sort`, orderby);
    }
    if (filters && filters.length > 0) {
      filters.forEach((filter: Filter) => {
        payload = payload.append(`filter[${filter.name}]`, filter.value);
      });
    }
    payload = payload.append('page', page.toString());
    payload = payload.append('size', pageSize.toString());

    const options = { headers: serviceHeaders, params: payload };  
    return this.injector.get(HttpClient).get(`${this.API_BASE}/${resource}`, options);
  }



    private getHeaders(): HttpHeaders {
        const token: SuccessOautResponse = this.localStorage.get('token');
        const header = new HttpHeaders({
            'Content-Type': 'application/json',
             Accept: 'application/json',
             Authorization: 'Bearer ' + token.access_token,
        });

        return header;
    }

}
