import { Catalogo } from 'src/app/models/catalogo.model';
import { CatalogoService } from './../../services/catalogo.service';
import { Component, OnInit } from '@angular/core'; 
import { OwlOptions } from 'ngx-owl-carousel-o';

@Component({
  selector: 'ali-novita-home',
  templateUrl: './novita-home.component.html',
  styleUrls: ['./novita-home.component.css']
})

export class NovitaHomeComponent implements OnInit {
  showAll = false;
  loader = false;
  novita: Catalogo;
  customOptions: OwlOptions = {
    loop: true,
    mouseDrag: true,
    touchDrag: true,
    pullDrag: true,
    dots: false,
    navSpeed: 700,
    navText: ['<', '>'],
    autoHeight: true,
    autoplay: true,
    autoplaySpeed: 500,
    autoplayTimeout: 2500,
    autoplayHoverPause: true,
    responsive: {
      0: {
        items: 1
      },
      400: {
        items: 2
      },
      740: {
        items: 3
      },
      940: {
        items: 5
      }
    },
    nav: true
  };
  constructor(
    private catalogoservice: CatalogoService
  ) { }

  ngOnInit(): void {
   this.getBooksOfTheWeek();
  }

  getBooksOfTheWeek() {
    this.loader = true;
    this.catalogoservice.getBooksOfTheWeek().subscribe((data:Catalogo)=> {  
      this.novita = data;
      this.loader = false; 
    });
  }

}
