<div class="center" *ngIf="success">
    <svg class="checkmark" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 52 52">
        <circle class="checkmark__circle" cx="26" cy="26" r="25" fill="none"/>
        <path class="checkmark__check" fill="none" d="M14.1 27.2l7.1 7.2 16.7-16.8"/>
    </svg>
    <h1 >Reso confermato</h1>
</div>

<div class="center" *ngIf="!success">
    <svg class="crossmark" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 52 52">
        <circle class="crossmark__circle" cx="26" cy="26" r="25" fillcross="none" />
        <path class="crossmark__check" fill="none" d="M16 16 36 36 M36 16 16 36" />
    </svg>
    <h1>Reso non confermato</h1>
</div>