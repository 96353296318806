import {Injectable} from '@angular/core';
import {Observable, Subject} from 'rxjs';

// tslint:disable-next-line: class-name
export interface eventModel {
  type: string;
  event: any;
}

@Injectable({
  providedIn: 'root'
})

export class MessageService {
  private listerners = new Subject<any>();

  constructor() {}

  listenEvent(): Observable<any> {
    return this.listerners.asObservable();
  }

  setEvent(event: object) {
    this.listerners.next(event);
  }
}

// import { Injectable } from '@angular/core';

// @Injectable({
//   providedIn: 'root',
// })
// export class MessageService {
//   messages: string[] = [];

//   add(message: string) {
//     this.messages.push(message);
//   }

//   clear() {
//     this.messages = [];
//   }
// }
