import * as _ from 'lodash'; 

export interface CartItem {
  titolo: string;
  ean: string;
  cod: string;
  prezzo: number;
  qta: number; 
  sconto: number;
  defiscalizzato: number;
}
 

export interface Carrello {
  success: boolean;
  items: CartItem[];
}

export class Cart {
    items: Array<CartItem>;
    totals:number = 0;
    pieces:number = 0;
    netto:number = 0;
    
    public constructor(items, totals = 0) { 
      this.items = items;
      if (items.length) {
        this.items.forEach((item:CartItem) => {
          this.totals+= item.qta * item.prezzo;
          this.netto+= this.sconto(item);
          this.pieces+= Number(item.qta);
        }); 
      } 
    }

    sconto(item: CartItem) {
      const lordo =    (item.prezzo*item.qta);
  
      if (item.defiscalizzato > 0 ) {
        const lordodef = lordo - (lordo * item.defiscalizzato / 100);
        const netto = lordo - (lordodef * item.sconto/ 100);
        return netto;
      } else { 
        const netto = lordo - (lordo * item.sconto/ 100);
        return netto;
      }
    }

    public addCartItem(CartItem: CartItem) {
      /** verifico che la cartItem non sia gia presente nella lista items */
      const index  = _.findIndex(this.items, { 'code': CartItem.cod });
      if (index > 0) {
        this.items[index].qta = CartItem.qta;
      } else {
        this.items.push(CartItem);
      }
      /** aggiorno il totale */
      this.items.forEach((item:CartItem) => {
        this.totals+= item.qta * item.prezzo;
        this.netto+= this.sconto(item);
        this.pieces+= item.qta;
      }); 
    }  

}



