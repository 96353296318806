import { Component, OnInit } from '@angular/core';
import { DocumentService } from 'src/app/services/document.service';
import * as _ from 'lodash';
import moment from 'moment';
import { ToastrService } from 'ngx-toastr';


export interface PeriodicElement {
  id: number;
  data_richiesta: string;
  colli: number;
  peso: number;
  valore: number;
  note: string;
  nr_ddt: number;
  data_ddt: string;
  imballo: string
}
const ELEMENT_DATA: PeriodicElement[] = [
  {id: 1, data_richiesta: '20-01-2020', colli: 1, peso: 20.1, valore: 23, note: 'Nisinsky', nr_ddt: 1, data_ddt: '21-01-2021', imballo: 'bancale'},
  {id: 2, data_richiesta: '22-01-2020', colli: 2, peso: 22.1, valore: 22, note: 'Paolo', nr_ddt: 1, data_ddt: '21-01-2021', imballo: 'bancale'},
];

@Component({
  selector: 'ali-archivio-resi',
  templateUrl: './archivio-resi.component.html',
  styleUrls: ['./archivio-resi.component.css']
})




export class ArchivioResiComponent implements OnInit {

  displayedColumns: string[] = ['id', 'data_richiesta', 'colli', 'peso', 'valore', 'note', 'nr_ddt', 'data_ddt', 'imballo'];
  displayedColumnsSave: string[] = ['id', 'data_richiesta', 'colli', 'peso', 'valore', 'note', 'nr_ddt', 'data_ddt', 'imballo', 'save'];
  dataSource = ELEMENT_DATA;
  resi:any = [];
  toBeApproved = []; 
  approved = []; 
  data_ddt : any = {};
  n_ddt : any = {};
  imballo : any = {};
 
  constructor(private documentservice: DocumentService, private toast: ToastrService) { }

  ngOnInit(): void {
    this.getResi();
  }


  getResi(){
    this.documentservice.getResi().subscribe(data => {
      this.resi = data;
      this.toBeApproved = _.filter(data,['stato', 'P']);
      this.approved = _.filter(data,['stato', 'A']);
    });
  }

  onSaveAssignment(element, n_ddt, data_ddt, imballo, index){
    const payload = {
      "nr_ddt" : n_ddt,
      "data_ddt" : moment(data_ddt).format('YYYYMMDD'),
      "imballo" : imballo
    };
    this.documentservice.updateReso(element.id, payload).subscribe((data:any)=> {
      this.approved = _.remove(this.approved, function(item) {
        return item.id !== element.id;
      });
      //aggiorno l'oggetto
      element.nr_ddt = n_ddt;
      element.data_ddt = data_ddt;
      element.imballo = imballo;
      //resetto il form
      this.n_ddt[index] = '';
      this.data_ddt[index] = '';
      this.imballo[index] = '';
    }),(error=>{
      this.toast.error(`Errore durante l'aggiornamento delle informazioni, riprova`);
    });
  }

  printEtichetta(id) {
    this.documentservice.printEtichetteReso(id).subscribe((blob) => {
      console.log(typeof blob);
     const a = document.createElement('a');
     a.href = URL.createObjectURL(blob);
     a.download = `etichette_di_resa.pdf`;
     a.click(); 
   }); 
  }
  formatData(data){
    return (data) ? moment(data).format('DD-MM-YYYY') : '';
  }

  formatImballo(imballo){
    if(!imballo){
      return '';
    }
    return (imballo ===  'S') ? 'Scatola' : 'Bancale'
  }

}
