<div class="loading-shade" *ngIf="loader">
  <mat-spinner></mat-spinner>
</div>

<div class="container-fluid"*ngIf="!loader">

  <ali-ricerca
    [ricerca]="ricerca"
    [advancedSearch]="advancedSearch"
    (changeSearchType)="ricercaAvanzata($event)"
    (orderChange)="orderBy($event)"
    (viewChange)="changeView($event)"
    (searched)="getSearchFilters($event)"
    (resetSearch)="resetForm($event)"
  >
  </ali-ricerca>

  <div class="row" *ngIf="catalogo && !loader">
    <div class="col-12">
      <ali-catalogo-grid [user]="user" (manageMedia)="mediaManager($event)" (clickScheda)="dettaglio($event)" (carrelloChange)="addToChart($event)"  *ngIf="view === 'grid'" [catalogo]="catalogo"></ali-catalogo-grid>
      <ali-catalogo-table [user]="user" (manageMedia)="mediaManager($event)" (clickScheda)="dettaglio($event)" *ngIf="view === 'table'" [catalogo]="catalogo" (carrelloChange)="addToChart($event)"></ali-catalogo-table>
    </div>
  </div>

  <div class="py-1" *ngIf="catalogo.data.length > 0">
    <mat-paginator *ngIf="catalogo" [pageSizeOptions]="[10, 20, 30]" showFirstLastButtons
      [length]="catalogo.total"
      [pageIndex]="catalogo.current_page"
      [pageSize]="catalogo.per_page"
      [showFirstLastButtons]="true"
      (page)="changePage($event)" >
    </mat-paginator>
  </div>
  <div *ngIf="catalogo.data.length == 0">
    <ali-alert-box type="warning" msg="Nessun articolo trovato"></ali-alert-box>
  </div>
</div>
