import * as _ from 'lodash';

export class ArticoloTools {
    public stati = [
        { cod: 'P' , descrizione:  'Disponibile'},
        { cod: 'E' , descrizione: 'Esaurito' },
        { cod: 'R' , descrizione: 'Ristampa'},
        { cod: 'R' , descrizione: 'Fuori catalogo'},
        { cod: 'A' , descrizione: 'Annullato'},
        { cod: 'I' , descrizione: 'In produzione'},
        { cod: 'S' , descrizione: 'Speciale'},
        { cod: 'U' , descrizione: 'Da richiamare'},
        { cod: '1' , descrizione: 'Acconto già fatturato'},
        { cod: '2' , descrizione: 'Acconto non fatturato'},
        { cod: '3' , descrizione: 'Sconto incondizionato'},
        { cod: '6' , descrizione: 'Spese imballo'},
        { cod: '7' , descrizione: 'Spese trasporto'},
        { cod: '#' , descrizione: 'Non a magazzino'},
        { cod: 'V' , descrizione: 'Vecchia edizione'},
        { cod: '+' , descrizione: 'Non più nostro'},
        { cod: 'X' , descrizione: 'Evadibile dal'},
        { cod: 'Y' , descrizione: 'Esaurito pross. F.C.'},
        { cod: ' ' , descrizione: 'Disponibile'}
      ];

    public disponibilita(disponibilita) {
        const d = Number(disponibilita); 
        if (d < 100) {
            return 'Disponibilità ' + d;
        }
        if (d > 100) {
            return 'Disponibilità più di 100';
        }
    }

    public stato(item) {  
      
        if(item.novita === 'N'){
            return 'Novità';
        }
       
      /*   if(Number(item.disponibilita) > 0 && item.stato === 'E'){
            return 'In esaurimento';
        } */
        const s = _.find(this.stati, { 'cod': item.stato});
        if (s) {
            return s.descrizione;
        }
        return 'Disponibile';
       // return item.stato;
    }
}
