<div class=" container-fluid calendar"  >
<div class="row">
    <div class="col-6 label">Scaduti</div>
    <div class="col-6 label">A scadere</div>
</div>
<div class="row">
    <div class="col-6">
        <div *ngFor="let item of intervalli; let i=index" class="inline" >
            <div (click)="setCollection(item.collection);" class="box {{item.class}}" *ngIf="i < 5" >
                <h3>{{item.label}}</h3>
                <p>{{item.tot | currency}}</p>
            </div>
        </div>
    </div>
    <div class="col-6">
        <div *ngFor="let item of intervalli; let i=index" class="inline">
        <div (click)="setCollection(item.collection);" class="box {{item.class}}" *ngIf="i > 4"  >
            <h3>{{item.label}}</h3>
            <p>{{item.tot | currency}}</p>
        </div>
    </div>
    </div>
</div>
</div>
<div class="pag">
    <ali-tabella-scadenze *ngIf="collection" [dataSource]="collection"></ali-tabella-scadenze>
</div>
  <!--   <div class="box {{item.class}}" *ngFor="let item of intervalli" (click)="setCollection(item.collection);">
        <h3>{{item.label}}</h3>
        <p>{{item.tot | currency}}</p>
        {{item.gmin}} {{item.gmax}}
    </div> -->


