<!-- <table mat-table [dataSource]="dataSource" matSort (matSortChange)="sortData($event)"  class="mat-elevation-z2 mytable"> 
    <ng-container matColumnDef="imponibile">
      <th mat-header-cell *matHeaderCellDef mat-sort-header> Imponibile </th>
      <td mat-cell *matCellDef="let row"> {{row.imponibile}} </td>
    </ng-container>
    <ng-container matColumnDef="desc_iva">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> desc_iva </th>
        <td mat-cell *matCellDef="let row"> {{row.desc_iva}} </td>
      </ng-container>
    <tr mat-header-row *matHeaderRowDef="displayedColumns;  sticky: true"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr> 
  </table>  -->
  <div class="mat-elevation-z8">
    <table mat-table
    [dataSource]="dataSource" multiTemplateDataRows   (matSortChange)="sortData($event)" class="mat-elevation-z4 " >
    <ng-container matColumnDef="{{column}}" *ngFor="let column of columnsName; let i=index">
    <!--  <th mat-header-cell *matHeaderCellDef mat-sort-header> {{columnsName[i]}} </th> -->
    <th mat-header-cell *matHeaderCellDef class="field">  {{displayNames[i]}}  </th>
    <td mat-cell *matCellDef="let element" class="field {{column}}"  >
      <span  class="field {{column}}"   *ngIf="column!=='desc_iva'">{{formatCur(element[column])}}</span>  
      <span  class="field {{column}}"   *ngIf="column==='desc_iva'">{{element[column]}}</span></td>  
    <td mat-footer-cell *matFooterCellDef class="totale"></td>
    </ng-container> 
    <tr mat-header-row *matHeaderRowDef="columnsName; sticky: true"></tr>
    <tr mat-row *matRowDef="let element; columns: columnsName;"
    class="example-element-row"  >
    </tr>  
    </table>
  </div> 