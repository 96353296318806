import { MediaService } from 'src/app/services/media.service';
import { Component, OnInit } from '@angular/core';



/**
 * decido di definire queste interfaccie all'interno del componente
 * perche vengono utilizzate solo qui
 */
export interface stats {
  sum: number;
  nfiles: number;
}

export interface adminstats {
  TOTALE: stats;
  PDF: stats;
  AUDIO: stats;
  ARTIMG: stats;
}




@Component({
  selector: 'ali-admin',
  templateUrl: './admin.component.html',
  styleUrls: ['./admin.component.css']
})
export class AdminComponent implements OnInit {
  loader = false;
  dati: adminstats = null;
  constructor( private mediaservice: MediaService) { }

  ngOnInit(): void {
    this.getStats();
  }

  getStats() {
    this.loader = true;
    this.mediaservice.getAdminStats().subscribe( (data:adminstats) => {
      // 200 201 204
      this.dati = data;
      this.loader = false;
      
    }, (error) => {
      // 500 401 

    }); 
  }

}
