<table mat-table [dataSource]="dataSource" matSort (matSortChange)="sortData($event)"  class="mat-elevation-z2 mytable"> 
        <ng-container matColumnDef="cod_ordine">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> Nr. Ordine </th>
          <td mat-cell *matCellDef="let row"> {{row.nr_ordine}} </td>
        </ng-container>
        <ng-container matColumnDef="stato">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> Stato riga</th>
          <td mat-cell *matCellDef="let row" class="stato">
            <div class="badge bgColorPrimaryLight"    matTooltip="{{row['stato'] | statoDescrizione}}" >
              <span *ngIf="row['stato']!==' '" >{{row['stato']}}</span>
              <span *ngIf="row['stato']===' '" >O</span>
            </div> 
          </td>
        </ng-container>
        <ng-container matColumnDef="stato_articolo">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> Stato Articolo</th>
          <td mat-cell *matCellDef="let row" class="stato"> 
            <div class="badge bgColorAccent"  matTooltip="{{articoloTools.stato(row.stato_articolo)}}" >
              <span *ngIf="row['stato_articolo']!==' '" >{{row['stato_articolo']}}</span>
              <span *ngIf="row['stato_articolo']===' '" >O</span>
            </div> 
            
              </td>
        </ng-container>
        
        <ng-container matColumnDef="data_ordine">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> Data Ordine </th>
          <td mat-cell *matCellDef="let row" class="data_ordine"> {{row.data_ordine  | formatDate}}  </td>
        </ng-container>
     
        <ng-container matColumnDef="causale_ordine">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> Causale </th>
          <td mat-cell *matCellDef="let row" class="causale"> {{row.causale_ordine}} </td>
        </ng-container>
        <ng-container matColumnDef="prezzo">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> Prezzo </th>
            <td mat-cell *matCellDef="let row" class="prezzo"> {{row.prezzo | currency}} </td>
          </ng-container>
        <ng-container matColumnDef="qta_in_spedizione">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> Qta in spedizione </th> 
            <td  mat-cell *matCellDef="let row" class="qta_in_spedizione"> {{printQta(row.qta_in_spedizione)}} </td>
        </ng-container>
          <ng-container matColumnDef="qta_rimanenza">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> Qta in rimanenza </th>
            <td mat-cell *matCellDef="let row" class="qta_rimanenza">{{printQta(row.qta_rimanenza)}}</td>
          </ng-container>
          <ng-container matColumnDef="sconto_base">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>&nbsp;</th>
            <td mat-cell *matCellDef="let row"><mat-icon *ngIf="isOm(row.sconto_base)" >card_giftcard</mat-icon></td>
          </ng-container>
         
        <tr mat-header-row *matHeaderRowDef="displayedColumns;  sticky: true"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr> 
      </table> 