<div class="row my-3">
  <mat-card class="card col" *ngFor="let item of catalogo.data">
    <mat-card-header>
      <mat-card-title>{{item.titolo | bookTitle}}</mat-card-title>
      <mat-card-subtitle>{{item.autore | uppercase}}&nbsp;{{(item.autore2) ? (", " + item.autore2 | uppercase) : ''}}</mat-card-subtitle>
    </mat-card-header>
    <mat-card-content>
      <ali-image type="grid" [codice]="item.codice" style="text-align: center !important;"></ali-image>
      <strong class="colorDark">ISBN</strong>&nbsp;{{item.ean}}<br>
      <strong class="colorDark">Editore</strong>&nbsp;{{item.editore}}<br>
      <strong class="colorDark">Data di pubblicazione</strong>&nbsp;{{formatData(item.prevista_pubb)}}<br>
      <strong class="colorDark">Collana</strong>&nbsp;{{item.collana}}<br>
      <strong class="colorDark">Stato</strong>&nbsp;{{articoloTools.stato(item)}}
      <h3 class="text-center price">{{item.prezzo |currency}} &nbsp;
        <!-- <span style="font-size:85%; color:green"> -{{item.sconto_art.sconto}} % </span><span *ngIf="item.sconto_art.sc4 > 0" style="font-size:85%; color:green" > Def. </span> --></h3>
      <h5></h5>
      <div align="center">
        <button (click)="schedaProdotto(item)" mat-flat-button color="accent"
                style="margin-bottom: 1em; display: block; width: 150px">
          Dettaglio
        </button>
      </div>

    </mat-card-content>
    <mat-card-footer>
      <mat-card-actions>
        <!--  -->
        <ali-order-quantity
          *ngIf="user.isCliente()"
          [qta]="item.qta"
          [codice]="item.codice"
          [disponibilita]="item.disponibilita"
          (editQta)="setQta($event)"
        ></ali-order-quantity>

      </mat-card-actions>
    </mat-card-footer>

  </mat-card>
</div>
