import { MatTableDataSource } from '@angular/material/table'; 
import { Component, Input, OnInit } from '@angular/core';
import * as _ from 'lodash'; 
import { ArticoloTools } from 'src/app/models/articolo.model';

@Component({
  selector: 'ali-view-order',
  templateUrl: './view-order.component.html',
  styleUrls: ['./view-order.component.css']
})
export class ViewOrderComponent implements OnInit {
  rows = [];
  @Input() data = [];
  
  dataSource;
  articoloTools: ArticoloTools = new ArticoloTools();
  displayedColumns: string[] = [ 'cod_ordine', 'stato', 'stato_articolo', 'data_ordine', 'causale_ordine','prezzo', 'qta_in_spedizione', 'qta_rimanenza', 'sconto_base'];

  constructor() { }

  ngOnInit(): void {
    this.rows = this.findSconto(this.data);
    this.dataSource = new MatTableDataSource(this.rows);
    
  }
  sortData($event) {
    
  }
 
  isOm(sconto_base) {
    if (Number(sconto_base) === 99 ) {
      return true;
    }
    return false;
  }  



  printQta(qta) { 
    if ( Number(qta) === 0) {
      return '';
   }

    return qta;
  }


  findSconto(data){
    //raggruppo per numero ordine
    const groupedOrders =  _.groupBy(data, 'nr_ordine');
    let rows = [];
    Object.keys(groupedOrders).forEach(key=>{
      //sommo tutti gli ordini omaggio
      let giftedOrders = _.filter(groupedOrders[key], ['sconto_base', '99']);
      let totalGiftedQuantity = _.sumBy(giftedOrders, function(o) { return Number(o.qta_rimanenza); }); 
      //sommo tutti gli ordini non in omaggio
      let regularOrders = _.filter(groupedOrders[key], function(o) { return Number(o.sconto_base) < 99; });
      let totalRegularQuantity = _.sumBy(regularOrders, function(o) { return Number(o.qta_rimanenza); }); 
      //concateno il valore finale
      groupedOrders[key][0].qta_rimanenza = (totalGiftedQuantity && totalRegularQuantity) ? `${totalRegularQuantity}+${totalGiftedQuantity} omaggio` : groupedOrders[key][0].qta_rimanenza ;
      groupedOrders[key][0].sconto_base = "40";
      rows.push(groupedOrders[key][0]);
    });
    
    return rows;
  }

}
