import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { SuccessOautResponse } from 'src/app/models/oauth.model';
import { iUser, User } from 'src/app/models/user.model';
import { BookService } from 'src/app/services/book.service';
import { MessageService } from 'src/app/services/message.service';
import { OauthService } from 'src/app/services/oauthservice.service';
import { LocalStorageService } from 'src/app/services/storage.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'ali-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {
  userName = new FormControl('', [
    Validators.required,
    Validators.email,
]);
public form;
loading = false;
user: iUser;
version = environment.version;
  constructor(
    private fb: FormBuilder,
    private oauth: OauthService,
        private localstore: LocalStorageService, 
        private toast: ToastrService, 
  ) { 
    this.form = fb.group({
      email: ['', [Validators.required, Validators.email]],
      password: ['', Validators.required]
  });
  }
  login(): void {

    if(this.localstore.get('firstLogin') || this.localstore.get('firstLogin') === undefined){
      this.localstore.set('firstLogin', true);
    }

    this.loading = true;
    this.localstore.remove('token');
    this.localstore.remove('tokenTimestamp');
    this.localstore.remove('usr');
    this.oauth.login(this.form.value.email, this.form.value.password)
        .subscribe((res: SuccessOautResponse) => {  
            this.localstore.set('token', res);
            this.localstore.set('tokenTimestamp', Math.floor(Date.now() / 1000));
            this.setUser(res.access_token); 
        }, (err: any) => {
            this.loading = false;
            if(err.error.error === 'invalid_request'){
                this.toast.warning('Inserire username e password', 'Controllare i campi obbligatori');
            } else if(err.error.error === 'invalid_grant'){
                this.toast.error('Le credenziali inserite non sono valide', 'Credenziali errate');
            } else {
                this.toast.error(err.error.error);
            }

        });
}
  ngOnInit(): void {
        
  }
  setUser(token) {
    this.oauth.getUser(token).subscribe((r: User) => {
        this.localstore.set('usr', r);
        this.user = new iUser(r);
        document.location.href='/home';
        /* this.router.navigateByUrl('/home').then(() => {
          window.location.reload();
        }); */
    });
}

}
