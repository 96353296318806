<div class="d-md-flex half" style="  margin-top: -50px;"> 
  <div class="bg" style="background-image:url(assets/images/ali-login.jpg)"></div>
  <div class="contents">
    <div class="container">
      <div class="row align-items-center justify-content-center">
        <div class="col-md-12">
          <div class="form-block mx-auto">
            <div class="text-center mb-5">
              <h3 class="text-uppercase">Login <strong class="text-primary">ALI</strong></h3>
            </div>
            <form [formGroup]="form" (ngSubmit)="login()">
              <mat-error *ngIf="0">
                The username and password were not recognized
              </mat-error>
              <div class="form-group first">
                <label for="username">Username</label>
                <input matInput type="text" placeholder="Email" class="form-control" formControlName="email" id="username" required>
              </div>
              <div class="form-group last mb-3">
                <label for="password">Password</label>
                <input matInput type="password" placeholder="Password" class="form-control" formControlName="password" id="password" required>
              </div>
<!--              <div class="d-sm-flex mb-5 align-items-center">-->
<!--                <span class="ml-auto"><a href="#" class="forgot-pass">Password dimenticata?</a></span>-->
<!--              </div>-->
              <input type="submit" value="Log In" class="btn btn-block py-2 btn-primary">
            </form>
          </div>
         
        </div>
        {{version}}
      </div>
    </div>
  </div>
</div>
