import { CedolaProdotto, DDT } from './../../models/catalogo.model';
import { CatalogoService } from './../../services/catalogo.service';
import { Component, OnInit, ViewChild } from '@angular/core';
import * as _ from 'lodash';
import { CartItem } from 'src/app/models/cartItem.model';
import { Router } from '@angular/router';
import { CarrelloService } from 'src/app/services/carrello.service';
import { MessageService } from 'src/app/services/message.service';
import { LocalStorageService } from 'src/app/services/storage.service';
import { MenuItem } from 'src/app/components/nav-menu/nav-menu.component';
import { MatSidenav } from '@angular/material/sidenav';
import { ToastrService } from 'ngx-toastr';
import { take } from 'rxjs/operators';
import * as moment from 'moment';
import { MatDialog } from '@angular/material/dialog';
import { DocumentService } from 'src/app/services/document.service';
import { DisplayTableComponent } from 'src/app/components/display-table/display-table.component';
import { iUser } from 'src/app/models/user.model';



@Component({
  selector: 'ali-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css'],
  
})
export class HomeComponent implements OnInit {
  @ViewChild('sidenav') sidenav: MatSidenav;

  stats  = {
    spedizione : false,
    spedita    : false,
  };
  loader = false;
  cedole = [];
  login = true;
  title = '';
  menuOpen = true;
  carrelloItems: Array<CartItem>;
  loadingCart = false;
  logged = false;
  spediti_oggi: Array<any>;
  spediti_ieri: Array<any>;
  in_spedizione: Array<any>; 
  user:iUser = new iUser(this.storage.get('usr'));
  firstLogin = false;
  constructor(
    private catalogoservice: CatalogoService,
    private router: Router,
    private carrelloservice: CarrelloService,
    private messageservice: MessageService,
    private storage: LocalStorageService,
    private toast: ToastrService,
    private documentservice: DocumentService, public dialog: MatDialog
    ) { }

  ngOnInit(): void {
    this.firstLogin = this.storage.get('firstLogin');
    this.getCedole(); 
  }
/* 
  showToaster() {
    this.toast.success('Hello world!', 'Toastr fun!')
      .onAction
      .pipe(take(1))
      .subscribe(() => this.toasterClickedHandler());
  }
  
  toasterClickedHandler() {
    console.log('Toastr clicked');
  }
   */
  getCedole() {
    this.loader =true;
    this.catalogoservice.getCedole().subscribe((data:Array<CedolaProdotto>) => {
      this.getStats();
      this.loader= false;
      const cedoleRecordset = _.groupBy(data, 'cedola_numero'); 
      const cedole = Object.keys(cedoleRecordset);
      cedole.forEach((element) => { 
        this.cedole.push(_.filter(data, {'cedola_numero' : element}) );
      }); 
    });
  }

  getStats() {
    
    const oggi = moment();
    const ieri = moment().subtract(1, 'days'); 

     this.catalogoservice.getStats('in_spedizione').subscribe((data:Array<any>) => { 
      this.in_spedizione = data;
     });  
     this.catalogoservice.getStats('spediti').subscribe((data:Array<any>) => { 
       this.spediti_oggi=  _.filter(data, { 'data_ddt' : oggi.format("YYYYMMDD")}); 
       this.spediti_ieri=  _.filter(data, { 'data_ddt' : ieri.format("YYYYMMDD")})
      /** vede le righe di oggi e ieri distintamente */
   });
  }
    
  getCarrello() {
    this.loadingCart = true;
    this.carrelloservice.getCart().subscribe((data : any) => {
      if (data.success) {
        this.carrelloItems = data.items;
      }
      this.loadingCart = false;
    });
  } 

  
  menuClicked($event: MenuItem) {
    this.title = $event.label;
    this.router.navigateByUrl($event.routerLink);
  }

  onSubmit() {
    this.storage.set('firstLogin', false);
    this.router.navigate(['/profilo'])
 }


 openRighe(element:DDT) {   
  this.documentservice.getRigheDDT(element.ddt_id).subscribe((data) => {
    const displayedColumns: string[] = [ 'nr_riga', 'ean','titolo','editore','qta', 'prezzo','sconto_base','sc1','sc2', 'sc3', 'sconto','defiscalizzato','totale_riga' ];
    const columnsName: string[] = [ 'Nr.Riga',  'EAN', 'Titolo',  'Editore', 'Qta','Prezzo', 'sb', 'sc1', 'sc2', 'sc3','Sconto %','Def.' ,'Totale Riga'];
    const dialogRef = this.dialog.open(DisplayTableComponent, { 
      data: {
        data:data,
        displayNames: columnsName,
        fieldNames: displayedColumns,
        title : 'Specifica d.d.t nr. ' + element.nr_ddt,
        numbers: ['prezzo', 'totale_riga', 'sconto'],
        totali : ['totale_riga', 'qta']
      }
    }); 
  }); 
}

}
