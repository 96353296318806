import { CatalogoService } from './../../services/catalogo.service';
import { Component, OnInit } from '@angular/core';
import * as _ from 'lodash';
import { Scadenza } from 'src/app/models/scadenze.model';
import {MatTableDataSource} from '@angular/material/table';

@Component({
  selector: 'ali-scadenziario',
  templateUrl: './scadenziario.component.html',
  styleUrls: ['./scadenziario.component.css']
})
export class ScadenziarioComponent implements OnInit {
  loader = false;
  dataSource: MatTableDataSource<Scadenza>;
   
  constructor(private service: CatalogoService) {}

  ngOnInit(): void {
    this.getData();
  }
  
  getData() {
    this.loader = true;
    this.service.getCollection('scadenziario').subscribe((r:Array<any>) => {
        // applico l'ordinamento di default
        const collection = _.orderBy(r, ['data_scadenza'], ['asc']); 
        collection.forEach((element:Scadenza) => {
          element.a_saldo = Number(element.a_saldo);
          element.giorni_scadenza = Number(element.giorni_scadenza);
        }); 
        this.dataSource = new MatTableDataSource(collection);
        this.loader = false;
    });
  }
}
