import { LocalStorageService } from "../services/storage.service";

export interface Address {
  indirizzo: string;
  citta: string;
  cap: string;
  prov: string;
}

export interface User {
  id: number;
  name: string;
  email: string;
  code: string;
  profile: string;
  created_at: Date;
  updated_at: Date;
  address: Address;
  can_do_orders: boolean;
  change_address: boolean;
}

export class iUser implements User {
  id: number;
  name: string;
  email: string;
  code: string;
  profile: string;
  created_at: Date;
  updated_at: Date;
  address: Address;
  can_do_orders: boolean;
  change_address: boolean;

  constructor(user: User) {
      Object.assign(this, user);
  }

   
  public getName(): string { 
      return this.name;
  }

  public isCatena(){
    return (this.profile === 'cliente' && !this.can_do_orders) ? true : false;
  }

  public isAdmin():boolean {
    return (this.profile === 'admin') ? true : false;
  }

  public isCliente():boolean {
    return (this.profile === 'cliente' && this.can_do_orders) ? true : false;
  }
}