import { eventModel, MessageService } from './../../services/message.service';
import { Component, Input, OnInit, Output , EventEmitter} from '@angular/core';
import { ArticoloTools } from 'src/app/models/articolo.model';


@Component({
  selector: 'ali-order-quantity',
  templateUrl: './order-quantity.component.html',
  styleUrls: ['./order-quantity.component.css']
})
export class OrderQuantityComponent implements OnInit {
  @Input() codice;
  @Input() disponibilita;
  @Output() editQta = new EventEmitter<any>();
  @Input() qta = 0;
  articoloTools: ArticoloTools = new ArticoloTools();
  
  constructor(private messageservice: MessageService) {
    this.messageservice.listenEvent().subscribe((data:eventModel) => {
      if (data.type==='close-cart') {
        this.qta = 0;
      }
    });
  }

  ngOnInit(): void {
  }

  changeInput($element) {
    this.editQta.emit({codice: this.codice, qta: $element.qta});
  }

  add(){
    this.qta++;
    this.editQta.emit({codice: this.codice, qta: this.qta});
  }

  remove(){
    if (this.qta - 1 >= 0){
      this.qta--;
      this.editQta.emit({codice: this.codice, qta: this.qta});
    }
  }

}
