import {CatalogoService} from './../../services/catalogo.service';
import {Component, Input, OnInit} from '@angular/core';
import {DomSanitizer} from '@angular/platform-browser';
import {eventModel, MessageService} from '../../services/message.service';

@Component({
  selector: 'ali-image',
  templateUrl: './image.component.html',
  styleUrls: ['./image.component.css']
})
export class ImageComponent implements OnInit {
  @Input() codice: string;
  @Input() type: string = 'mini';
  loader = true;
  imageToShow;
  subscribe = null;
  constructor(
    private readonly sanitizer: DomSanitizer,
    private catalogoservice: CatalogoService,
    private messageservice: MessageService
  ) {
  }

  ngOnInit(): void {
    this.getImageFromService(this.codice);
    if (!this.subscribe) {
      this.subscribe = true;
      this.messageservice.listenEvent().subscribe((data: eventModel) => {
        if (data.type === 'reload' && data.event === this.codice) {
          // this.getImageFromService(this.codice);
          const timestamp = (new Date()).getTime();
          this.imageToShow = `https://amazonstorageali.s3.eu-south-1.amazonaws.com/img/${this.codice}.jpg`;
        }
      });
    }
  }


  getImageFromService(ean) {
    this.imageToShow = `https://amazonstorageali.s3.eu-south-1.amazonaws.com/img/${ean}.jpg`;
    this.loader = false;

  }

}
