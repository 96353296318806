<mat-dialog-content *ngIf="datasource && datasource.data" >
   <h3 class="sectionTitle">{{data.title}}</h3>
  <div class="export">
    <ali-export-table [data]="dataExport" *ngIf="dataExport.length"></ali-export-table>
  </div>
  <div class="righe">
    <table mat-table
    [dataSource]="datasource" multiTemplateDataRows   (matSortChange)="sortData($event)" class="mat-elevation-z4 " >
    <ng-container matColumnDef="{{column}}" *ngFor="let column of data.fieldNames; let i=index">
    <!--  <th mat-header-cell *matHeaderCellDef mat-sort-header> {{columnsName[i]}} </th> -->
    <th mat-header-cell *matHeaderCellDef class="field" >  {{data.displayNames[i]}}  </th>
    <td mat-cell *matCellDef="let element" class="{{format(element[column])}} field">
      <div *ngIf="column!=='stato' && column!=='stato_articolo' && column!=='titolo' && column!=='sconto'" >{{formatNumber(element[column],column)}}</div>
      <div *ngIf="column==='titolo'" >{{formatNumber(element[column],column) | bookTitle}}</div>
      <div *ngIf="column==='stato'" >
        <div class="badge bgColorPrimaryLight" matTooltip="{{element[column] | statoDescrizione}}" >
          <span *ngIf="element[column]!==' '" >{{element[column]}}</span>
          <span *ngIf="element[column]===' '" >O</span>
        </div>
      </div>
      <div *ngIf="column==='sconto'">{{element[column] | sconto}}</div>
      <div *ngIf="column==='stato_articolo'" >
        <div class="badge bgColorAccent" matTooltip="{{element[column] | statoArticolo}}" >
          <span *ngIf="element[column]!==' '" >{{element[column]}}</span>
          <span *ngIf="element[column]===' '" >O</span>
        </div>
      </div>
    </td>
    <td mat-footer-cell *matFooterCellDef class="totale" >{{totale(column)}}</td>
    </ng-container>
    <tr mat-footer-row *matFooterRowDef="data.fieldNames; sticky: true"></tr>
    <tr mat-header-row *matHeaderRowDef="data.fieldNames; sticky: true"></tr>
    <tr mat-row *matRowDef="let element; columns: data.fieldNames;"
    class="example-element-row"  >
    </tr>
    </table>
  </div>
 </mat-dialog-content>
