<form #f="ngForm" (submit)="changeInput(f.value)" class="orderQty">
  <div class="container-fluid mb-3">
    <div class="row justify-content-center">
      <button type="button" class="btnSm" (click)="remove()">-</button>
      <input placeholder="QTA" (change)="changeInput(f.value)" [(ngModel)]="qta" name="qta"  size="4">
      <button type="button" class="btnSm" (click)="add()">+</button>
    </div>
    <div class="row text-center" *ngIf="disponibilita" >
      <div class="col-12 button-items" >
        {{articoloTools.disponibilita(disponibilita)}}
      </div>
    </div>
  </div>
</form>
