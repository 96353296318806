import { Injectable } from '@angular/core';
import {
    HttpInterceptor,
    HttpRequest,
    HttpResponse,
    HttpHandler,
    HttpEvent,
    HttpErrorResponse
} from '@angular/common/http';

import { Observable, of, throwError } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { SuccessOautResponse } from '../models/oauth.model';
 
@Injectable()
export class HttpConfigInterceptor implements HttpInterceptor {
  constructor(

  ) {}

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(req).pipe(catchError((error: HttpErrorResponse) => this.handleErrorReq(error)));
  }
 

  /**
   * not in use
   * @param event
   */
  private handleOkReq(event: HttpEvent<any>): Observable<any> {
    if (event instanceof HttpResponse) {
      const body: any = event.body;
      // failure: { code: **, msg: 'failure' }
      // success: { code: 0,  msg: 'success', data: {} }
      if (body && body.code !== 0) {
        if (body.msg && body.msg !== '') {
          
        }
        return throwError([]);
      } else {
        return of(event);
      }
    }
    // Pass down event if everything is OK
    return of(event);
  }

  private handleErrorReq(error: HttpErrorResponse): Observable<never> {
    /* 409: risorsa già presente (conflict) */
    /* 422: entità non processabile */
    switch (error.status) {
      case 401:
            console.log('finalmente');
        break;
      case 403:
      case 404:
        
        break;
      case 500:
       // this.goto(`/sessions/${error.status}`);
       /**
        * @todo  toast livello error con la dicitura si è verificato un errore imprevisto.
        */
        break;
      case 409:
      case 412:
        /*
        if (error instanceof HttpErrorResponse) {
          this.toastr.warning(error.error.description || `${error.status} ${error.statusText}`);
        } */
        /**
         * @todo ciclare gli errori che vengono dati dal backend, per il 412 è mandatario
         * restituire gli errori nello standard json api.
         *
         */
        break;
      default:
        /**
         * da gestire come il 500
         */

        break;
    }
    return throwError(error);
  }
}
