import {LocalStorageService} from 'src/app/services/storage.service';
import {iUser} from './../../models/user.model';
import {BookService} from './../../services/book.service';
import {DomSanitizer} from '@angular/platform-browser';
import {FormControl, FormGroup} from '@angular/forms';
import {Ricerca} from './../../models/ricerca.model';
import {Component, OnInit, Output, EventEmitter, Input} from '@angular/core';
import {MatIconRegistry} from '@angular/material/icon';
import * as _ from 'lodash';
import { description } from 'src/app/models/catalogo.model';



@Component({
  selector: 'ali-ricerca',
  templateUrl: './ricerca.component.html',
  styleUrls: ['./ricerca.component.css']
})
export class RicercaComponent implements OnInit{
  user: iUser;
  scope = '';
  public view = 'grid';
  inputScope = '';
  cont = 0;
  order = '';
  form = new FormControl();
  group: FormGroup;
  searchTerm = '';
  field: any;
  filtereditems: Array<description> = [];
  @Input() advancedSearch: boolean;
  @Input() ricerca: Ricerca;
  @Input() singleSearch = true;
  @Output() searched = new EventEmitter<any>();
  @Output() resetSearch = new EventEmitter<boolean>();
  @Output() sendSubmit = new EventEmitter<boolean>();
  @Output() viewChange = new EventEmitter<string>();
  @Output() orderChange = new EventEmitter<string>();
  @Output() changeSearchType = new EventEmitter<boolean>();
  toBeSearched: Array<any> = [];
  risp = [];
  types = [];
  reset = false;

  public orderType = [
    { cod : 'prezzo', descrizione: 'prezzo crescente' },
    { cod : '-prezzo', descrizione: 'prezzo decrescente' },
    { cod : '-disponibilita', descrizione: 'maggiore disponibilità' },
    { cod : '-df', descrizione: 'più recenti' },
 ];


  constructor(
    private icon: MatIconRegistry,
    private sanitizer: DomSanitizer,
    private bookservice: BookService,
    private storage: LocalStorageService,
  ) {
    this.icon.addSvgIcon(
      'reset_filter',
      this.sanitizer.bypassSecurityTrustResourceUrl('/assets/images/filter-off-48x48.svg')
    );

    const prefView = this.storage.get('prefView');
    if (prefView) {
      this.view = prefView;
      this.viewChange.emit(this.view);
    }
  }

  ngOnInit(): void {
    this.user = new iUser(this.storage.get('usr'));

    // creo l'oggetto di risposta in base ai campi dati in input, inizzializzando il valore di ognuno di esso a null
      let ctrlGroup = {};
      this.ricerca.fields.forEach(field => {
        ctrlGroup[field.field]=new FormControl();
        this.risp.push({name: field.field, value: null});
      });
      this.group = new FormGroup(ctrlGroup);
      this.setInitValue();
      this.risp.push({name: 'search', value: null});

    /* this.group.controls[this.inputScope].valueChanges.subscribe((data: any) => {
      this.searchTerm = data;
      const i = this.risp.findIndex(r => r.name === this.inputScope);
      if (this.risp[i]) {
        this.risp[i].value = this.searchTerm ? this.searchTerm : null;
      }
    }); */
  }

  update(){
  //  this.group.get('titolo').setValue('prova');
  }
  /**
   * Funzione che salva il nome del campo della ricerca in cui sta avvenendo un evento
   *
   */
  getName(data: any) {
    this.inputScope = data.srcElement.name;
  }


  orderEvent(order) {
    this.orderChange.emit(order);
    this.storage.set('lastOrder', order);
  }

  selected(event) {
    const i = this.risp.findIndex(r => r.name === event.field);
    this.risp[i].value = event.value.nome;
  }

  setInitValue(){
    // this.advancedSearch = this.storage.get('advancedSearch');
    this.order = this.storage.get('lastOrder');
    const lastSearch = this.storage.get('lastSearch');
    if(this.advancedSearch){
      lastSearch.forEach(item =>{
        this.group.controls[item.name].setValue(item.value);
      });
    }else{
      const value = (lastSearch && lastSearch[0]) ? lastSearch[0].value : ''
      this.form.setValue(value);
    }
  }

  /**
   * Funzione che emitta il reset dei campi della ricerca e reimposta il form
   */
  resetFormField() {
    this.resetSearch.emit(true);
    this.form.setValue(null);
    this.reset = true;
    this.scope = '';
    this.order = '';
    this.filtereditems = [];
    this.types = [];
    // svuoto l'oggetto di risposta
    this.risp.forEach(element => {
      element.value = null;
    });
    this.group.reset();
    this.storage.set('lastSearch', null);


  }

  Onsubmit() {
    let filters = [];
    if(this.advancedSearch){
      const values = this.group.value;
      this.risp.forEach((item)=>{
        item.value = values[item.name];
      });
      // compongo l'oggetto filtri che sara' emittato in formato jsonapi
      filters = this.risp.filter(r => {
        if (r.value && r.value !== "0") {
          return r.value;
        }
      });
    }else{
      if(this.form.value){
        filters.push({name:'search', value:this.form.value});
      }
    }

    //salvo nello storage la ricerca effettuata
    this.storage.set('lastSearch', filters);
    this.storage.set('lastOrder', this.order || '-df');
    // this.storage.set('advancedSearch', this.advancedSearch || false);

    this.searched.emit({event: 'search', value:filters});
    this.sendSubmit.emit(true);

    if(this.order){
      this.searched.emit({event: 'order', value: this.order});
    }

  }

  ricercaAvanzata() {
    this.advancedSearch = !this.advancedSearch;
    this.changeSearchType.emit(this.advancedSearch);
  }

  public changeView($event) {
    this.storage.set('prefView', $event);
    this.viewChange.emit($event);
  }
}
