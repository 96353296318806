<mat-tab-group>
    <mat-tab label="Calendario scadenze" *ngIf="dataSource && dataSource.data && dataSource.data.length" >
        <ali-calendar *ngIf="!loader" [dataSource]="dataSource"></ali-calendar>
    </mat-tab>
    <mat-tab label="Tabella scadenze" *ngIf="dataSource && dataSource.data && dataSource.data.length">
        <ali-tabella-scadenze *ngIf="!loader" [dataSource]="dataSource"></ali-tabella-scadenze>
    </mat-tab> 
</mat-tab-group>
<div *ngIf="dataSource && !dataSource.data.length">
    <ali-alert-box type="warning" msg="Nessun scadenza trovata"></ali-alert-box>
</div>
<div class="loading-shade" *ngIf="loader">
    <mat-spinner></mat-spinner>
</div>