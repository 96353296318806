import { righeOrdine } from './../../models/rigaOrdine.model';
import { CatalogoService } from './../../services/catalogo.service';
import {AfterViewInit, Component, ViewChild} from '@angular/core';
import {MatSort} from '@angular/material/sort';
import {MatTableDataSource} from '@angular/material/table';
import {animate, state, style, transition, trigger} from '@angular/animations';
import * as _ from 'lodash'; 
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'ali-ordini',
  templateUrl: './ordini.component.html',
  styleUrls: ['./ordini.component.css'],
  animations: [
    trigger('detailExpand', [
      state('collapsed', style({height: '0px', minHeight: '0'})),
      state('expanded', style({height: '*'})),
      transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
    ]),
  ],
})
export class OrdiniComponent implements AfterViewInit {
  displayedColumns: string[] = [ 'ean', 'titolo', 'editore', 'autore', 'qta_in_spedizione', 'qta_rimanenza'];
  columnsName: string[] = [ 'Ean', 'Titolo', 'Editore', 'Autore', 'Qta in spedizione', 'Qta rimanenza'];
  /**
   * nr.ordine, ean , titolo, autore, editore, da consegnare, prezzo, sconto, da pagare, stato, data
   */
  dataSource;
  expandedElement: any | null;
  initData = null;
  loader = false;
  causali:Array<any> = [];
  selected = "0";
  defaultSort = 'editore';
  defaultSortDir ='asc'

  visualizzaArrivi
  @ViewChild(MatSort) sort: MatSort;
  
  constructor( private catalogoservice: CatalogoService, private activatedRoute: ActivatedRoute) {
    
    this.getOrdiniCliente();
  }

   ngAfterViewInit() {
     if (this.dataSource && this.dataSource.sort) {
      this.dataSource.sort = this.sort;
     } 
  }

  //  titolo alfabetico

  public getOrdiniCliente(order = 'ean') {
      this.loader = true;
      this.catalogoservice.getCollection('righe-ordini', order).subscribe((data:Array<righeOrdine>)=>{
        this.initData = data;

        /** 
         * ottengo le causali e il loro codice 
         */
        this.buildCausali(data); 
         this.dataSource = new MatTableDataSource(this.buildDataSource(data)); 
         this.loader = false;
         const in_spedizione = this.activatedRoute.snapshot.paramMap.get('in_spedizione');
         if (in_spedizione === 'in_spedizione') {
           this.visualizzaArrivi = true;
           this.arriviFilter();
         }

      });
  }
 

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();

    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }

  total(column: string) {
      const totalColumns = ['qta_in_spedizione', 'qta_rimanenza'];
      if (totalColumns.indexOf(column) > -1) {
        const data = this.dataSource.data;
        return _.sumBy(data, column);
      }  
      return;
  }

  formatField(column: string) { 
    const qta = ['qta_in_spedizione', 'qta_rimanenza'];
    if (qta.indexOf(column) > -1) {
      if (Number(column) === 0) {
        return ''
      }
    }
    return column;
  }

  /* sortData($event) { 
    const orderBy = $event.active;
    const direction = ($event.direction==='desc') ? '-' : '';
    const order = direction+orderBy;
    this.getOrdiniCliente(order);
    this.loader = true; 
    const orderBy = $event.active;
    const direction = $event.direction;
    const collection =  _.orderBy(this.dataSource.data, [orderBy], [direction]);
    this.dataSource = null;
    this.dataSource = new MatTableDataSource(collection); 
    console.log(this.dataSource);
    this.loader = false;
  } */
  

  sortData($event) {
    this.defaultSortDir = $event.direction;
    this.defaultSort    = $event.active; 
    const data =  _.orderBy(this.dataSource.data, [this.defaultSort],[this.defaultSortDir]); 
    this.dataSource.data = data;
}

  buildCausali(data:Array<any>) {
    const g = _.groupBy(data, 'nr_causale');
      Object.entries(g).forEach((item) => { 
        let codice_causale = item[0];
        this.causali.push({'cod': codice_causale, 'descrizione':_.find(item[1], { 'nr_causale': codice_causale })['causale_ordine'] });  
      })  
  }

  filtraCausale() {
    if (this.selected === "0") {
        this.buildDataSource(this.initData);
        return;
    } 
    const data = _.filter(this.initData, {'nr_causale' : this.selected}); 
    this.dataSource = new MatTableDataSource(this.buildDataSource(data));
    return; 
  }

  arriviFilter() { 
      let data = [];
      if (this.visualizzaArrivi) {
         data = _.filter(this.initData, function(o) { return  Number(o.qta_in_spedizione) > 0 });
         this.dataSource = new MatTableDataSource(this.buildDataSource(data));
      } else {
         this.dataSource = new MatTableDataSource(this.buildDataSource(this.initData));
      } 
  }

  buildDataSource(data) {
    let codarticolo = '';  
    let dataSource = [];  
    data.forEach((item) => { 
      (item.autore) ? item.autore = item.autore.trim() : '';
      if(codarticolo !== item.cod_articolo) {
        codarticolo =  item.cod_articolo;
        let row = {};
        this.displayedColumns.forEach((field: string) => {
            row[field] = item[field];
        });
        row['childs'] = _.filter(data, {'cod_articolo' :  codarticolo});
        row['qta_in_spedizione'] = _.sumBy(row['childs'], function(o) { return Number(o.qta_in_spedizione); });
        row['qta_rimanenza'] = _.sumBy(row['childs'], function(o) { return Number(o.qta_rimanenza); });
      
        dataSource.push(row);
      }
    });
    return _.orderBy(dataSource, ['editore', 'titolo'],['asc', 'asc']); 
  }
   
  

}  