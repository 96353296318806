import {Component, Input, OnInit} from '@angular/core';

@Component({
  selector: 'ali-alert-box',
  templateUrl: './alert-box.component.html',
  styleUrls: ['./alert-box.component.css']
})
export class AlertBoxComponent implements OnInit {
  @Input() msg: string;
  @Input() type: string;
  constructor() { }

  ngOnInit(): void {
  }

}
