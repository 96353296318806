<!-- <h3>1 Indirizzo di consegna</h3>
Paolo Antonio Reina
Via cremona 1
Presso DSC srl
Legnano, Milano 20025
Aggiungi le istruzioni per la consegna
Modifica
Oppure seleziona un punto di ritiro - Scegli
<hr>
<h3>2 Modalità di pagamento</h3> 
MasterCard MasterCard termina con 0141
Indirizzo della carta di credito: Uguale a indirizzo di spedizione
Aggiungi un buono regalo o un codice promozionale
Inserisci codice
<hr>
<h3>3 Rivedi gli articoli e la spedizione</h3>  -->
<div class="pag" *ngIf="!orderSent">
    <mat-card class="card"> 
        <div class="row">
            <!--destinatario-->
            <div class="col">
                <mat-card-content >
                    <h2 class="colorPrimary">Destinatario</h2>
                    <hr>
                    <p > 
                    <strong>{{user.name}}</strong> <br>
                    {{user.address.indirizzo}}<br/> 
                    {{user.address.cap}}, {{user.address.citta}} {{(user.address.prov) ? '(' + user.address.prov + ')': '' }}<br/> 
                    </p>
                    <button mat-mini-fab color="primary" aria-label="Example icon button with a menu icon" (click)="editAddress()" *ngIf="!changeAddress">
                        <mat-icon>edit_note</mat-icon>
                    </button>
                </mat-card-content>
            </div>
            <!--indirizzo consegna-->
            <div class="col-6" *ngIf="changeAddress || modifiedAddress">
                <mat-card-content >
                    <h2 class="colorPrimary">Indirizzo di Consegna</h2>
                    <hr>
                    <p *ngIf=" modifiedAddress && !changeAddress"> 
                        <strong>{{indirizzo.destinatario}}</strong> <br>
                        {{indirizzo.indirizzo}}<br/> 
                        {{indirizzo.cap}}, {{indirizzo.citta}} {{(indirizzo.prov) ? '(' + indirizzo.prov + ')': '' }}<br/> 
                    </p>
                    <div *ngIf="changeAddress " > 
                        <mat-form-field appearance="legacy" class="full-width-input" >
                            <input matInput placeholder="destinatario" type="text" name="destinatario" [(ngModel)]="indirizzo.destinatario" />
                        </mat-form-field><br/>
                        <mat-form-field appearance="legacy" class="full-width-input" >
                            <input matInput placeholder="indirizzo" type="text" name="indirizzo" [(ngModel)]="indirizzo.indirizzo" />
                        </mat-form-field> <br>
                        <mat-form-field appearance="legacy" style="width:100px" >
                            <input matInput placeholder="cap" type="text" name="cap"  [(ngModel)]="indirizzo.cap" />
                        </mat-form-field> 
                        <mat-form-field appearance="legacy" style="width:200px" >
                            <input matInput placeholder="città" type="text" name="citta"  [(ngModel)]="indirizzo.citta" />
                        </mat-form-field> 
                        <mat-form-field appearance="legacy" style="width:100px" >
                            <input matInput placeholder="provincia" type="text" name="prov"  [(ngModel)]="indirizzo.prov" />
                        </mat-form-field>  
                    </div>
                </mat-card-content> 
                <mat-card-actions *ngIf="user.change_address">
                    <!-- <button mat-flat-button color="primary"  class="fixed-width" (click)="editAddress()" *ngIf="!changeAddress" [disabled]="!user.change_address">Modifica</button> -->
                    <button mat-flat-button color="accent"  class="fixed-width" (click)="editAddress()" (click)="addressChange()" *ngIf="changeAddress" >Conferma</button>
                </mat-card-actions> 
            </div>
        </div>
    </mat-card>
    <mat-card> 
        <mat-card-content *ngIf="loadingCart">
            <mat-spinner></mat-spinner>
        </mat-card-content>
        <mat-card-content *ngIf="!loadingCart">
            <h2 class="colorPrimary">Articoli in ordine</h2>
            <hr> 
           <div  *ngFor="let item of carrelloItems" class="item mat-elevation-z2"  > 
                  <ali-cart-item [item]="item" (setItem)="aggiornaCarrello($event)" (removeItemFromCart)="remove($event)"></ali-cart-item>
           </div>   
               <!--  <h1 class="total">Totale Ordine : {{this.totaleOrdine | currency}} </h1> 
                <h1 class="total">Totale netto : {{this.totaleNetto | currency}} </h1> 
                 -->
    <mat-card>
           <h1>Riepilogo Ordine</h1>
           <div class="row">
            <div class="col-6 label">Numero di articoli : </div>
            <div class="col-6 quantity">{{numeroArticoli}}</div>
          </div>
          <div class="row">
            <div class="col-6 label">Numero pezzi : </div>
            <div class="col-6 quantity">{{numeroPezzi}}</div>
        </div>
        <div class="row">
            <div class="col-6 label">Lordo :</div>
            <div class="col-6 quantity">{{this.totaleOrdine | currency}}</div>
          </div> 
          <div class="row">
            <div class="col-6 label">Netto a pagare :</div>
            <div class="col-6 quantity"><strong>{{this.totaleNetto | currency}}</strong></div>
          </div>
        </mat-card>
        
        </mat-card-content> 
            <mat-card-actions> 
                <button mat-flat-button  color="primary" class="fixed-width" routerLink="/catalogo">Continua con gli accquisti</button>
                <button mat-flat-button color="accent" class="fixed-width" (click)="buyNow()">Acquista Ora</button>
            </mat-card-actions> 
    </mat-card> 
</div>


<div class="card-ordine-confirm" *ngIf="orderSent"> 
    <mat-card class="example-card mat-elevation-z8">
        <mat-card-header>
            <mat-card-title>
              <h1>Ordine é stato inviato</h1>
            </mat-card-title>
        </mat-card-header>
        <mat-card-content>
        <p>
            Tra qualche minuto sarà visibile nell'elenco degli ordini.
        </p>
        </mat-card-content>
        <mat-card-actions>
          <button mat-raised-button color="primary" routerLink="/catalogo" >Torna al catalogo</button>
       
            <button mat-raised-button color="primary" routerLink="/home" >Torna alla home</button>
          </mat-card-actions>
  </mat-card>
</div>