<div class="loader" *ngIf="loader">
<mat-spinner ></mat-spinner>
</div>

<div class="" *ngIf="!loader">
    <div class="row cont-percent">
        <div class="col-11">
            
            <ali-testo 
            title="Totale spazio occupato" 
            icon="book"
            type="ep_card-header-info"
            subtitle= "totale files: {{dati.TOTALE.nfiles}}"
            number="{{dati.TOTALE.sum | formatFileSize}}"
            
            ></ali-testo>
     
        </div>
        <div class="col-3">
            <ali-testo 
            title="IMMAGINI" 
            subtitle= "totale files: {{dati.ARTIMG.nfiles}}"
            icon="image"
            type="ep_card-header-warning"
            number=" {{dati.ARTIMG.sum | formatFileSize}}"
            ></ali-testo>
        </div>
        <div class="col-3">
            <ali-testo 
            title="PDF" 
            icon="picture_as_pdf"
            subtitle= "totale files: {{dati.PDF.nfiles}}"
            type="ep_card-header-danger"
            number="{{dati.PDF.sum | formatFileSize}}"
            ></ali-testo>
        </div>
        <div class="col-3">
            <ali-testo 
            title="AUDIO" 
            subtitle= "totale files: {{dati.AUDIO.nfiles}}"
            icon="library_music"
            type="ep_card-header-success"
            number="{{dati.AUDIO.sum | formatFileSize}}"
            ></ali-testo>
        </div>
    </div>
   
 <!--    @Input() title = '';
    @Input() subtitle = '';
    @Input() icon = '';
    @Input() type = '';
    @Input() number = null;
    @Input() name = null;
    @Input() loading = false; -->
    <!-- <div class="file-caricati-cont">
     <div> <span class="rossso">Totale dei files caricati</span> : {{dati.TOTALE.nfiles}} dimensione dei files {{dati.TOTALE.sum | formatFileSize}}</div>  
     <div> <span class="verrrde"> Totale IMMAGINI</span> : {{dati.ARTIMG.nfiles}} dimensione dei files {{dati.ARTIMG.sum | formatFileSize}} </div>


 <div> <span class="gialllo">Totale PDF</span> : {{dati.PDF.nfiles}} dimensione dei files {{dati.PDF.sum | formatFileSize}}</div>

<div> <span class="blllu">Totale AUDIO</span>: {{dati.AUDIO.nfiles}} dimensione dei files {{dati.AUDIO.sum | formatFileSize}}</div>


 <div> <span class="verrrde"> Totale IMMAGINI</span> : {{dati.ARTIMG.nfiles}} dimensione dei files {{dati.ARTIMG.sum | formatFileSize}} </div>

</div> -->
</div>