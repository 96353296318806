import { iUser } from 'src/app/models/user.model';
import { ArticoloTools } from './../../models/articolo.model';
import { Component, EventEmitter, Input, OnInit, Output, SimpleChanges } from '@angular/core';
import { Catalogo } from 'src/app/models/catalogo.model';
import moment from 'moment';

@Component({
  selector: 'ali-catalogo-grid',
  templateUrl: './catalogo-grid.component.html',
  styleUrls: ['./catalogo-grid.component.css']
})
export class CatalogoGridComponent implements OnInit {
  @Input() catalogo: Catalogo;
  @Output() carrelloChange = new EventEmitter<any>();
  @Output() clickScheda = new EventEmitter<any>();
  @Output() manageMedia = new EventEmitter<any>();
  @Input() user:iUser;
  articoloTools: ArticoloTools = new ArticoloTools();

  constructor() { }


  ngOnInit(): void {
  }


  schedaProdotto(item) {
      this.clickScheda.emit(item);
  }

  openMediaManager($event) {
    this.manageMedia.emit($event);
  }

  formatData(data) {
    if (Number(data) > 19999999) {
      return moment(data, 'YYYYMMDD').format('DD-MM-YYYY');
    }
      return '';
  }

  setQta(event) {
    this.carrelloChange.emit(event);
  }
}
