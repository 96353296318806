<div class="cartContent" *ngIf="!loading" >
  <div class="container-fluid" *ngIf="items.length">
    <div class="row subtotal" >
      <div class="col-12 text-center my-2 ">
        <strong>Subtotale: </strong>
        <span class="price">{{total | currency}}</span>
      </div>
      <div class="col-12 text-center my-2"  >
        <button mat-flat-button color="default" (click)="svuotaCarrello();">Svuota il carrello</button>
        <button mat-flat-button color="accent" (click)="concludiOrdine();" >Concludi ordine</button>
      </div>
    </div>
    <div class="row itemsContainer">
      <div class="container-fluid p-0" *ngFor="let item of items">
        <div class="row">
          <div class="col-12">
            <ali-cart-item [item]="item" (setItem)="aggiornaTotale($event)" (removeItemFromCart)="remove($event)"></ali-cart-item>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="container-fluid" *ngIf="items.length === 0">  
      <h1 class="colorPrimary text-center empty-cart">Il tuo carrello &egrave; vuoto.</h1>   
  </div>
</div>
