import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {DomSanitizer} from '@angular/platform-browser';
import {ToastrService} from 'ngx-toastr';
import * as _ from 'lodash';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'ali-image-upload',
  templateUrl: './image-upload.component.html',
  styleUrls: ['./image-upload.component.css']
})
export class ImageUploadComponent implements OnInit {
  @Output() changeImage: EventEmitter<any> = new EventEmitter<any>();
  @Input() title = '';
  @Input() image = null;
  h = null;
  w = null;
  size = {
    size: '',
    valid: false
  };
  allowedTypes = ['image/jpeg', 'image/jpg'];
  picture: string = null;
  formData: FormData = new FormData();

  constructor(
    private sanitizer: DomSanitizer,
    private toastservice: ToastrService,
  ) {
  }

  ngOnInit(): void {
    console.log('this is an image' + this.image);
  }

  // controlla il file dato in input
  setContent(key, event) { 
    this.getBase64(event.target);
    if (_.includes(this.allowedTypes, event.target.files[0].type)) {
      this.appendFormData(key, event.target.files[0]);
    }else{
      this.toastservice.error(`Immagine non valida`);
      return;
    }
  }

  // imposta valore all'oggetto formData
  appendFormData(key, content) {
    if (this.formData.has(key)) {
      this.formData.delete(key);
    }
    this.formData.append(key, content);
    this.uploadImage();
  }

  uploadImage(){
    const file: any = this.formData.getAll('file')[0];
    this.image = null;
    this.getImage();
    this.changeImage.emit({type: 'ARTIMG', content: this.formData});
    this.formData.delete('file');
  }

  getImage() {
      if (this.image) {
      return 'url(' +  this.image + ')';
    } else {
      return 'url(' + this.picture + ')';
    }  
  }

  getBase64(file): void {
    const reader = new FileReader();
    reader.readAsDataURL(file.files[0]);
    const _s = this;
    reader.onload = () => {
      let encoded = reader.result.toString().replace(/^data:(.*,)?/, '');
      if ((encoded.length % 4) > 0) {
        encoded += '='.repeat(4 - (encoded.length % 4));
      }
      _s.picture = 'data:image/jpeg;base64,' + encoded;
      file.value = null;
    },
      reader.onerror = (error) => {
        _s.picture = null; 
      };
  }

}
