import {ElencoOrdiniComponent} from './pages/elenco-ordini/elenco-ordini.component';
import {ProfiloComponent} from './pages/profilo/profilo.component';
import {RiepilogoComponent} from './pages/catalogo/riepilogo/riepilogo.component';
import {LoginComponent} from './pages/login/login.component';
import {GestioneResiComponent} from './pages/gestione-resi/gestione-resi.component';
import {ImportaOrdineComponent} from './pages/importa-ordine/importa-ordine.component';
import {ScadenziarioComponent} from './pages/scadenziario/scadenziario.component';
import {ElencoFattureComponent} from './pages/elenco-fatture/elenco-fatture.component';
import {ElencoDdtComponent} from './pages/elenco-ddt/elenco-ddt.component';
import {CatalogoComponent} from './pages/catalogo/catalogo.component';
import {HomeComponent} from './pages/home/home.component';
import {NgModule} from '@angular/core';
import {Routes, RouterModule, PreloadAllModules} from '@angular/router';
import {AuthGuard} from './auth.guard';
import {OrdiniComponent} from './pages/ordini/ordini.component';
import {ResaConfirmComponent} from './pages/resa-confirm/resa-confirm.component';
import {NovitaInOrdineComponent} from './pages/novita-in-ordine/novita-in-ordine.component';

const routes: Routes = [
  /*  {
     path: 'home/:id',
     loadChildren: () => import('./folder/folder.module').then( m => m.FolderPageModule),
     canActivate: [AuthGuard]
   }, */
  {
    path: 'login',
    component: LoginComponent,
  },
  /*  {
    path: 'home',
    loadChildren: () => import('./folder/folder.module').then( m => m.FolderPageModule),
    canActivate: [AuthGuard]
  },  */
  {
    path: '',
    redirectTo: 'home',
    pathMatch: 'full'
  },

  {
    path: 'profilo',
    component: ProfiloComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'login',
    component: LoginComponent,
  },
  {
    path: 'home',
    component: HomeComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'catalogo',
    component: CatalogoComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'elenco-ddt',
    component: ElencoDdtComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'elenco-fatture',
    component: ElencoFattureComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'gestione-resi',
    component: GestioneResiComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'articoli-in-ordine',
    component: OrdiniComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'articoli-in-ordine/:in_spedizione',
    component: OrdiniComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'ordini',
    component: ElencoOrdiniComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'novita-in-ordine',
    component: NovitaInOrdineComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'importa-ordine',
    component: ImportaOrdineComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'scadenziario',
    component: ScadenziarioComponent,
    canActivate: [AuthGuard]
  }
  ,
  {
    path: 'riepilogo-ordine',
    component: RiepilogoComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'resa/confirm/:token',
    component: ResaConfirmComponent,
  }
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      onSameUrlNavigation: 'reload',
      preloadingStrategy: PreloadAllModules
    })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule {
}
