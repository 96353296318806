import { CatalogoService } from './../../../services/catalogo.service';
import { AfterViewInit, Component, Input, OnInit, ViewChild } from '@angular/core'; 
import {MatPaginator} from '@angular/material/paginator';
import {MatSort} from '@angular/material/sort';
import {MatTableDataSource} from '@angular/material/table';
import * as _ from 'lodash';
import { Scadenza } from 'src/app/models/scadenze.model';
import { DocumentService } from 'src/app/services/document.service';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'ali-tabella-scadenze',
  templateUrl: './tabella-scadenze.component.html',
  styleUrls: ['./tabella-scadenze.component.css']
})
export class TabellaScadenzeComponent implements AfterViewInit {
  columnsName: string[] = ['Nr.Fattura', 'Descrizione', 'Data Fattura', 'Scadenza', 'GG.Scadenza', 'Pagamento', 'Da pagare', 'Tipo']
  displayedColumns: string[] = ['num_fattura', 'descrizione', 'data_fattura', 'data_scadenza', 'giorni_scadenza','desc_pag', 'a_saldo', 'tipo', 'id_fattura'];
  @Input() dataSource: MatTableDataSource<Scadenza>;
  dataExport = [];
  loader = false;
  /* @ViewChild(MatPaginator) paginator: MatPaginator; */
  @ViewChild(MatSort) sort: MatSort; 
  constructor( private documentservice: DocumentService, private toast: ToastrService) { } 
 

  ngAfterViewInit() { 
    this.loader = true;
    this.dataExport = this.buildDataExport(this.dataSource.data);  
     if (this.dataSource && this.dataSource.sort) { 
      this.dataSource.sort = this.sort;
     } 
     this.loader = false;
  }
    
  buildDataExport(collection: Array<any>){
    let dataExport = [];
    let row= {};
    collection.forEach(element =>{
      this.displayedColumns.forEach((column, index) => {
        row[this.columnsName[index]]=element[column];
      });
      dataExport.push(row);
      row = {};
    }); 
    return dataExport;
  }
 

  applyFilter(event: Event) { 
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase(); 
}
 
  getTotalPagare() {
      const daPagare = _.filter(this.dataSource.data, function(o) { return o.giorni_scadenza <= 0 });
      let totale = 0;

      daPagare.forEach((element) => {
          totale = eval(totale + element.a_saldo);
      });
      return totale;
  }

  getTotalPagati() {
    const pagati = _.filter(this.dataSource.data, function(o) { return o.giorni_scadenza > 0 });
    let totale = 0;

    pagati.forEach((element) => {
        totale = eval(totale + element.a_saldo);
    });
    return totale;
}
sortData($event) { 
  const orderBy = $event.active;
  const direction = $event.direction;
  const collection =  _.orderBy(this.dataSource.data, [orderBy], [direction]);
  this.dataSource = new MatTableDataSource(collection);
}

downloadPdf(type: string, id: string){ 
  
 const request = this.documentservice.fileRequest(type, id);
  fetch(request, {cache: "reload"})
  .then(response => { 
    if(response.status === 200){
     response.blob().then(blob =>{
        const a = document.createElement('a');
        a.href = URL.createObjectURL(blob);
        a.download = `${type}_${id}.pdf`;
        a.click(); 
        //this.file = URL.createObjectURL(blob); 
      });  
    }else{ 
      this.toast.error('File non trovato');
    }
  });
}

}
