<!-- <div class="containerP">
  <mat-card *ngIf="acquista"> 
        <div class=" ">
          <h4> {{prodotto.titolo | uppercase}} </h4>
          <div class="m-0">
            <span class="colorDark">Autore: </span>
            <span class="colorPrimary">{{prodotto.autore | uppercase}}</span>
            <span class="colorPrimary" *ngIf="prodotto.autore2">, {{prodotto.autore2 | uppercase}}</span>
             </div>
          <div class="m-0">
            <span class="colorDark">Editore: </span>
            <span class="colorPrimary">{{prodotto.editore | uppercase}}</span>
          </div>
        </div>  
     
    <div class="m-0">
      <span class="colorDark">ISBN: </span>{{prodotto.ean}}
    </div>
       <span class="colorDark">Categoria: </span>{{prodotto.categoria}} <br>
       
    <h3 class="text-center price">{{prodotto.prezzo |currency}}</h3> 
    <ali-order-quantity
            [codice]="prodotto.codice"
            [disponibilita]="prodotto.disponibilita"
            (editQta)="setQta($event)"
          ></ali-order-quantity>
  </mat-card> 
  <button (click)="setAcquista()" [@cardFlip]="data.state" mat-flat-button color="accent" style="margin-bottom: 1em; display: block; width: 100%;" *ngIf="!acquista">Acquista
  </button>
  <div *ngIf="!acquista" > 
  <div [class]="loader">
    <mat-progress-spinner mode="indeterminate" diameter="35" matSuffix style="margin: 0 auto;"></mat-progress-spinner>
    <h4 >{{prodotto.titolo}}</h4>
    <h5>{{prodotto.autore}}</h5>
  </div>
  <div class="{{imageClass}}">
  <img
    src="https://www.alilibri.it/servizi-mi/img/{{prodotto.codice}}.jpg"
     style="width:100%"
    (error)="vainerrore()" (load)="hacaricato()">
  </div>
  <div   [class]="noImage">
    <img src="assets/images/classic.jpg">
    <h3 class="inset title">{{prodotto.titolo}}</h3>
    <h5 class="inset subtitle">{{prodotto.autore}}</h5>
  </div>
</div>
</div>

 -->
<div class="card"  [@cardFlip]="data.state"> 
  <div class="face back">
    <button (click)="setAcquista()" [@cardFlip]="data.state" mat-flat-button color="accent" style="margin-bottom: 1em; display: block; width: 100%;" *ngIf="!acquista">Info Libro
    </button>
    <div [class]="loader">
      <h4 >{{prodotto.titolo | bookTitle}}</h4>
      <h5>{{prodotto.autore}}</h5>
      <mat-progress-spinner mode="indeterminate" diameter="35" matSuffix style="margin: 0 auto;"></mat-progress-spinner>
    
    </div>
    <div class="{{imageClass}}">
    <img
      [src]="src"
       style="width:100%"
      (error)="vainerrore()" (load)="hacaricato()">
    </div>
    <div   [class]="noImage" >
      <img src="assets/images/classic.jpg">
      <h3 class="inset title">{{ (prodotto.titolo.length>30)? (prodotto.titolo | slice:0:30)+'..':(prodotto.titolo) | bookTitle }}</h3>
      <h5 class="inset subtitle" *ngIf="!acquista">{{prodotto.autore}}</h5>
    </div>
  </div>
  <div class="face front">
    <button (click)="setAcquista()" [@cardFlip]="!data.state" mat-flat-button color="accent" style="margin-bottom: 1em; display: block; width: 100%;" *ngIf="acquista">
      Copertina
    </button>
    <mat-card>
    <div *ngIf="acquista">  
      <h4> {{ (prodotto.titolo.length>30)? (prodotto.titolo | slice:0:30)+'..':(prodotto.titolo) | bookTitle}} </h4>
      <div class="m-0">
        <span class="colorDark">Autore: </span>
        <span class="colorPrimary">{{prodotto.autore | uppercase}}</span>
        <span class="colorPrimary" *ngIf="prodotto.autore2">, {{prodotto.autore2 | uppercase}}</span>
         </div>
      <div class="m-0">
        <span class="colorDark">Editore: </span>
        <span class="colorPrimary">{{prodotto.editore | uppercase}}</span>
      </div> 
 
<div class="m-0">
  <span class="colorDark">ISBN: </span>{{prodotto.ean}}
</div>
   <span class="colorDark">Categoria: </span>{{prodotto.categoria}} <br>
   
<h3 class="text-center price">{{prodotto.prezzo |currency}}</h3> 
<ali-order-quantity
        *ngIf="user.isCliente()"
        [codice]="prodotto.codice"
        [disponibilita]="prodotto.disponibilita"
        (editQta)="setQta($event)"
      ></ali-order-quantity>
</div> 
</mat-card>
  </div>
</div>
