import { Carrello } from './../../models/cartItem.model';
import { Router } from '@angular/router';
import { CarrelloService } from './../../services/carrello.service';
import { MessageService } from './../../services/message.service';
import { Component, EventEmitter, Input, OnInit, Output, SimpleChanges } from '@angular/core';
import {Cart, CartItem} from '../../models/cartItem.model';
import { MatSnackBar } from '@angular/material/snack-bar';

@Component({
  selector: 'ali-cart',
  templateUrl: './cart.component.html',
  styleUrls: ['./cart.component.css']
})
export class CartComponent implements OnInit { 
  @Input() items: CartItem[] = [];
  @Input() loading = false;
  public total = 0;
  constructor(
     private carrelloservice: CarrelloService,
     private messageservice: MessageService, 
     private router: Router,
     private _snackBar: MatSnackBar,
  ) {
   
  }
  ngOnChanges(changes: SimpleChanges): void { 
      this.total = this.calc(this.items); 
  }


  ngOnInit(): void {
   this.total =  this.calc(this.items);
  }

  aggiornaTotale($event:Carrello) { 
   this.total =  this.calc($event.items);
  }

  concludiOrdine() { 
    this.messageservice.setEvent({type: 'riepilogo-ordine'}); 
    this.router.navigateByUrl('riepilogo-ordine');
  }
  
  svuotaCarrello() {
    this.carrelloservice.emptyTrash().subscribe((data: any) => {   
        this.items =  data.items;
        this.calc(this.items);
        this.messageservice.setEvent({type : 'close-cart', event: data});
        this.openSnackBar('Il carrello è stato svuotato', 'OK')
    });
  }

  remove(itemCode) {
    this.carrelloservice.delItemFromCart(itemCode).subscribe((data:Carrello) => {  
      this.total = this.calc(data.items);
      this.items = data.items;
      this.messageservice.setEvent({type:'adj-cart', event: data});
    }); 
  }

  calc(items) {
    if (items && items.length > 0) {
      const cart = new Cart(items);
      return cart.totals; 
    } else {
      return 0;
    }
  }
  openSnackBar(message: string, action: string) {
    this._snackBar.open(message, action);
  }
}
