import { Component, Output, EventEmitter, Input, OnInit } from '@angular/core'; 
import { ActivatedRoute } from '@angular/router';
import { CartItem } from 'src/app/models/cartItem.model';
import { LocalStorageService } from 'src/app/services/storage.service';
import menuItems from './menu.json';

export interface MenuItem {
  label: string;
  routerLink: string;
  icon?: string;
  tooltip?: string;
  badge?: number;
}
export interface Menu {
  cliente: MenuItem[];
  admin: MenuItem[];
}

@Component({
  selector: 'ali-nav-menu',
  templateUrl: './nav-menu.component.html',
  styleUrls: ['./nav-menu.component.css']
})
export class NavMenuComponent implements OnInit{
  @Output() menuClick = new EventEmitter<MenuItem>();
  @Input() items: CartItem[];
  menu: Menu = menuItems;
  activeMenu: MenuItem[];
  
  selectedItem = null;
  constructor(private storage: LocalStorageService) {
  }

  ngOnInit(): void { 
    const user = this.storage.get('usr');
    this.activeMenu = this.setMenu(user);

    this.selectedItem = window.location.pathname.replace('/', '');
  }

  public gotoLink(item: MenuItem) {
      this.selectedItem = item.routerLink;
      this.menuClick.emit(item);
  }

  setMenu(user):MenuItem[]{
    let type = '';
    if(user.profile === 'cliente' && !user.can_do_orders){
      type = 'catena';
    }else{
      type = 'cliente';
    }

    if(user.profile === 'admin'){
      type = 'admin';
    }

    return this.menu[type];
  }
}
