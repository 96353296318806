import {Injectable, Injector} from '@angular/core';
import {environment} from 'src/environments/environment';
import {LocalStorageService} from './storage.service';
import {SuccessOautResponse} from '../models/oauth.model';
import {HttpHeaders, HttpClient, HttpParams} from '@angular/common/http';
import { Filter } from './catalogo.service';
 
@Injectable({
  providedIn: 'root'
})
export class DocumentService {
  API_BASE: string = environment[environment.env].base_url;

  constructor(
      private injector: Injector,
      private httpclient: HttpClient,
      private localStorage: LocalStorageService,
  ) {

  }

  public getRigheDDT(ddt_id) {
    const serviceHeaders = this.getHeaders();
    const options = { headers: serviceHeaders };
    return this.injector.get(HttpClient).get(`${this.API_BASE}/ddt/${ddt_id}`, options);
  }

  public getRigheOrdine(nr_ordine) {
    const serviceHeaders = this.getHeaders();
    const options = { headers: serviceHeaders };
    return this.injector.get(HttpClient).get(`${this.API_BASE}/ordine/${nr_ordine}`, options);
  }
  
  public getFattureCollection( 
    startDate:string,
    stopDate: string, 
    filters?: Array<Filter>,
    orderby?: string
  ) {
    const serviceHeaders = this.getHeaders();
    let payload = new HttpParams();
    if (orderby) {
      payload = payload.append(`sort`, orderby);
    }
    if (filters && filters.length > 0) {
      filters.forEach((filter: Filter) => {
        payload = payload.append(`filter[${filter.name}]`, filter.value);
      });
    } 

    const options = { headers: serviceHeaders, params: payload };  
    return this.injector.get(HttpClient).get(`${this.API_BASE}/fatture/${startDate}/${stopDate}`, options);
  }



  public getOrdiniCollection(startDate:string,
    stopDate: string, 
    filters?: Array<Filter>,
    orderby?: string) {
      const serviceHeaders = this.getHeaders();
      let payload = new HttpParams();
      if (orderby) {
        payload = payload.append(`sort`, orderby);
      }
      if (filters && filters.length > 0) {
        filters.forEach((filter: Filter) => {
          payload = payload.append(`filter[${filter.name}]`, filter.value);
        });
      } 
  
      const options = { headers: serviceHeaders, params: payload };  
      return this.injector.get(HttpClient).get(`${this.API_BASE}/ordini/${startDate}/${stopDate}`, options);
  }

  public getDDTCollection( 
    startDate:string,
    stopDate: string, 
    filters?: Array<Filter>,
    orderby?: string
  ) {
    const serviceHeaders = this.getHeaders();
    let payload = new HttpParams();
    if (orderby) {
      payload = payload.append(`sort`, orderby);
    }
    if (filters && filters.length > 0) {
      filters.forEach((filter: Filter) => {
        payload = payload.append(`filter[${filter.name}]`, filter.value);
      });
    } 

    const options = { headers: serviceHeaders, params: payload };  
    return this.injector.get(HttpClient).get(`${this.API_BASE}/ddt/${startDate}/${stopDate}`, options);
  }


  private getHeaders(): HttpHeaders {
    const token: SuccessOautResponse = this.localStorage.get('token');
    if (token) {
      const header = new HttpHeaders({
        'Content-Type': 'application/json',
        Accept: 'application/json',
        Authorization: 'Bearer ' + token.access_token,
    }); 
    return header;
    }
    const header = new HttpHeaders({
        'Content-Type': 'application/json',
        Accept: 'application/json'
    }); 
    return header;
}

    public download(type: string, id: string) {
      const ServiceHeaders = this.getHeaders();
      const options = {headers: ServiceHeaders}; 
      const requestOptions: object = Object.assign({responseType: 'Blob'}, options);

      return this.injector.get(HttpClient).get(`${this.API_BASE}/pdf/${type}/${id}`, requestOptions);
}

  public printEtichetteReso(id) {
    const ServiceHeaders = this.getHeaders();
    const options = {headers: ServiceHeaders}; 
    const requestOptions: object = Object.assign({responseType: 'Blob'}, options);

    return this.injector.get(HttpClient).get(`${this.API_BASE}/stampa/reso/${id}`, requestOptions);
  }


  public fileRequest(type: string, id: string, isFat = null): Request {
    const token: SuccessOautResponse = this.localStorage.get('token');
    const options = {
        headers: {
            'Content-Type': 'application/pdf',
            'Accept': 'application/pdf',
            'Authorization': 'Bearer ' + token.access_token,
        }
    }
    let jsonResponse = null;
    if (!isFat) {
       jsonResponse = new Request(`${this.API_BASE}/pdf/${type}/${id}`, options);
    } else {
       jsonResponse = new Request(`${this.API_BASE}/pdf/${type}/${id}/${isFat}`, options);
    }
    
    return jsonResponse;
  }

  public getRappresentanti() {
    const serviceHeaders = this.getHeaders();
    const options = {headers: serviceHeaders};
    return this.httpclient.get(`${this.API_BASE}/rappresentanti`, options);
  }

  public getResi() {
    const serviceHeaders = this.getHeaders();
    const options = {headers: serviceHeaders};
    return this.httpclient.get(`${this.API_BASE}/resi`, options);
  }

  public createReso(payload) {
    const serviceHeaders = this.getHeaders();
    const options = {headers: serviceHeaders};
    return this.httpclient.post(`${this.API_BASE}/reso`,payload, options);
  }

  public updateReso(id, payload) {
    const serviceHeaders = this.getHeaders();
    const options = {headers: serviceHeaders};
    return this.httpclient.patch(`${this.API_BASE}/reso/${id}`,payload, options);
  }


  public checkTokenReso(token) {
    const serviceHeaders = this.getHeaders();
    const options = {headers: serviceHeaders};
    return this.httpclient.get(`${this.API_BASE}/checktoken/${token}` ,options);
  }
}
