import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'formatFileSize'
})
export class FormatFileSizePipe implements PipeTransform {

  transform(value: number ): string {
      if (value <= 1000) {
        return value.toFixed(2) + 'Kb';
      }
      if (value > 1000 && value < 1000000) {
        return (value/1024).toFixed(2) + 'Mb'; 
      }

      return (value/1024/1024).toFixed(2) + 'Gb';  
      
  }

}
