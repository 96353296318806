<mat-tab-group (selectedIndexChange)="selectTab($event)">
  <mat-tab label="Inserimento nuovo reso" > 
    <div *ngIf="requestResoSuccess">
      <ali-alert-box type="success" msg="Richiesta di reso inviata"></ali-alert-box>
      <button mat-button (click)="undoImport()">Nuova richiesta</button>
    </div>
    <form class="form" #resoForm="ngForm">
    <mat-card *ngIf="!requestResoSuccess">
      <mat-card-content>
          
              <div class="row">
                  <div class="col-3 col">
                      <mat-form-field class="form-field">
                           <mat-label>Rappresentante</mat-label>
                          <!--<input type="text"
                                 placeholder="Scegli un rappresentante"
                                 aria-label="Number"
                                 matInput
                                 [formControl]="rappresentante"
                                 [matAutocomplete]="auto">
                          <mat-autocomplete autoActiveFirstOption #auto="matAutocomplete">
                            <mat-option *ngFor="let option of filteredOptions | async" [value]="option">
                              {{option}}
                            </mat-option>
                          </mat-autocomplete> -->
                        <mat-select name="rappresentante" [(ngModel)]="agente" [ngModelOptions]="{standalone: true}" required >
                          <mat-option  *ngFor="let rappresentante of rappresentanti" [value]="rappresentante.cod">{{rappresentante.descrizione}}</mat-option>
                        </mat-select>
                      </mat-form-field> 
                  </div>
                  <div class="col-3 col">
                      <mat-form-field class="form-field">
                          <mat-label>Numero Colli</mat-label>
                          <input type="number"
                                 placeholder="Numero dei colli"
                                 aria-label="Numero dei colli"
                                 name="nr_colli"
                                 matInput 
                                 [(ngModel)]="nr_colli"
                                 
                                 required
                                 > 
                        </mat-form-field> 
                  </div>
                  <div class="col-3 col">
                      <mat-form-field class="form-field">
                          <mat-label>Peso (Kg)</mat-label>
                          <input type="number"
                                 placeholder="Peso (Kg)"
                                 aria-label="Peso (Kg)"
                                 name="peso"
                                 matInput 
                                 [(ngModel)]="peso"
                                 required
                                 > 
                        </mat-form-field> 
                  </div>
                  <div class="col-3 col">
                    <mat-form-field class="form-field">
                      <mat-label>Valore totale</mat-label>
                      <input type="number"
                             placeholder="valore della merce"
                             aria-label="valore della merce"
                             name="valore"
                             matInput 
                             [(ngModel)]="valore"
                             required
                             > 
                    </mat-form-field> 
                  </div>
              </div>
              
                      <mat-form-field appearance="fill" class="textarea">
                          <mat-label>Note Eventuali</mat-label>
                          <textarea matInput class="form-field" rows="3"  
                          [(ngModel)]="note"
                          [ngModelOptions]="{standalone: true}"></textarea>
                      </mat-form-field> 
            
      </mat-card-content> 
  </mat-card>
  <mat-card *ngIf=" !requestResoSuccess" >
    <mat-card-title *ngIf="!imported">Seleziona un file</mat-card-title>
    <mat-card-subtitle *ngIf="!imported">Puoi caricare l'ordine selezionando un file xls o csv ( separato da punti e virgola ) con il seguente ordine dei campi : Codice ISBN: Quantit&agrave;.<br/>Dopo aver caricato un file, sarai in grado di vedere gli articoli che verranno importati nel tuo carrello. </mat-card-subtitle>
    <mat-card-content *ngIf="!records.length" >
        <input type="file" (change)="onFileChange($event)" multiple="false"/>
    </mat-card-content>
    <mat-card-content *ngIf="records.length && !imported" >
       <p>
           Il file contiene {{records.length}} articoli.
          <span *ngIf="unvalidISBNS.length === 0" >Non sono stati trovati errori.</span>
          <span *ngIf="unvalidISBNS.length > 0" >{{unvalidISBNS.length}} articolo/i non saranno importati perch&egrave; hanno un ISBN non valido</span>
       </p>
    </mat-card-content>
    <mat-card-actions *ngIf="records.length && !imported" >
        <button mat-button color="warn" (click)="undoImport()">Annulla importazione</button> 
        <button mat-button color="accent" (click)="doImport()">Continua importazione</button> 
    </mat-card-actions>
    <mat-card-actions *ngIf="imported" >
      <button mat-icon-button color="primary"> <span class="material-icons"  >done</span></button> 
      {{validElements.length}} Articoli importati
  </mat-card-actions>
    <button mat-raised-button color="accent" align="right" 
    (click)="sendResoRequest()" [disabled]="!resoForm.form.valid">Richiedi Reso</button>
  </mat-card> 
</form>
  <div *ngIf="records.length && import===0" >
    <mat-divider></mat-divider>
  <table mat-table [dataSource]="records" class="mat-elevation-z4">
  <ng-container matColumnDef="codiceISBN">
    <th mat-header-cell *matHeaderCellDef> ISBN. </th>
    <td mat-cell *matCellDef="let element"> 
      <button mat-icon-button color="primary" *ngIf="isValid(element.codiceISBN)"><span class="material-icons"  >task_alt</span></button> 
      <button mat-icon-button color="warn" *ngIf="!isValid(element.codiceISBN)"><span class="material-icons"  >highlight_off</span></button> 
         <strong>{{element.codiceISBN}} </strong> 
    </td>
  </ng-container>
  <ng-container matColumnDef="descrizione">
    <th mat-header-cell *matHeaderCellDef> Descrizione. </th>
    <td mat-cell *matCellDef="let element"> {{element.descrizione}} </td>
  </ng-container> 
  <ng-container matColumnDef="quantita">
    <th mat-header-cell *matHeaderCellDef> Quantita. </th>
    <td mat-cell *matCellDef="let element"> {{element.quantita}} </td>
  </ng-container>
  
  <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
  <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
  </table>
  </div>
  
  <!-- <div *ngIf="import" >
  <mat-card>
    <mat-card-content>
      <p>I prodotti sono stati importati con successo.</p>
      <p>Vai nel carrello per visualizzare i prodotti importati</p>
    </mat-card-content>
  </mat-card>
  
  </div> -->
  </mat-tab>
  <mat-tab label="Richieste di reso"  > 
    <ali-archivio-resi *ngIf="selectedTab === 1"></ali-archivio-resi>
  </mat-tab> 
</mat-tab-group>



<!-- 'ISBN', 'descrizione', 'quantita', 'sconto']

</table> -->
<div class="loading-shade" *ngIf="loader">
    <mat-spinner></mat-spinner>
</div>  