import { iUser, User } from './../../models/user.model';
import { LocalStorageService } from './../../services/storage.service';
import { Router } from '@angular/router';
import { eventModel, MessageService } from './../../services/message.service';
import { CartItem } from './../../models/cartItem.model';
import {Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges, ViewChild} from '@angular/core';
import { MatRipple } from '@angular/material/core';
import {
  trigger,
  state,
  style,
  animate,
  transition,
  // ...
} from '@angular/animations';
import { OauthService } from 'src/app/services/oauthservice.service';
import { SuccessOautResponse } from 'src/app/models/oauth.model';
@Component({
  selector: 'ali-nav-bar',
  templateUrl: './nav-bar.component.html',
  styleUrls: ['./nav-bar.component.css'],
  animations: [
    trigger('openClose', [
      // ...
      state('open', style({ 
        opacity: 1,  
      })),
      state('closed', style({ 
        opacity: 0.2, 
      })),
      transition('open => closed', [
        animate('0.5s')
      ]),
      transition('closed => open', [
        animate('0.5s')
      ]),
    ]),
  ],
})
export class NavBarComponent implements OnInit, OnChanges {
  @Output() toggleNavBar: EventEmitter<any> = new EventEmitter<any>();
  @Output() toggleSideCart: EventEmitter<any> = new EventEmitter<any>();
  @Input() items: CartItem[] = [];
  @Input() loading = false;
  @ViewChild(MatRipple) ripple: MatRipple;
  isOpen = true;
  User:iUser = new iUser(this.storage.get('usr'));
  constructor(
    private messageservice : MessageService,
    private router: Router,
    private authservice: OauthService,
    private storage: LocalStorageService
  ) { }

  ngOnInit(): void {
    this.messageservice.listenEvent().subscribe((data:eventModel) => { 
      if (data.type==='riepilogo-ordine') { 
        this.toggleCart(); 
      } 
      // chiude il pannello del carrello
      if (data.type==='close-cart') { 
        this.items = data.event.items;
        this.toggleCart(); 
      } 
      //aggiorna il carrello
      if (data.type==='adj-cart') { 
        this.items = data.event.items;
      }
  });
  }

  ngOnChanges(changes: SimpleChanges): void {
    //Called before any other lifecycle hook. Use it to inject dependencies, but avoid any serious work here.
    //Add '${implements OnChanges}' to the class.
    this.launchRipple();
    if ( this.items && this.items.length === 0) {
      
    }
  }

  help() {
    document.location.href = "https://stage.alilibri.it/help/Home.html";
  }

  toggleMenu() {
    this.toggleNavBar.emit();
  }

  toggleCart() {
    this.toggleSideCart.emit();
  }
  launchRipple() {
    this.isOpen = !this.isOpen;
    setTimeout(()=> {
      this.isOpen = !this.isOpen;
    },500);
  }

  async logout() {
    const token: SuccessOautResponse = this.storage.get('token');
    this.authservice.logout(token.access_token).subscribe((data) => {
      this.storage.remove('token');
      this.storage.remove('tokenTimestamp');
      this.storage.remove('usr');
    //  setTimeout(() => {
     //   this.router.navigateByUrl('login')  ;
       /*  .then(() => {
      //    window.location.reload();
        }); */
   //   }, 500);
     document.location.href='/login';
    });

  }

  public alihomepage() {
    document.location.href="https://www.alilibri.it/";
  }

}