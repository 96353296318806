<mat-nav-list> 
    <a mat-list-item matRipple [matRippleColor]="'rgba(26, 157, 227, .3)'"  *ngFor="let item of activeMenu" 
    [matTooltip]="item.tooltip"
    matTooltipPosition="after"
    (click)="gotoLink(item)" 
    [class]="(selectedItem === item.routerLink) ? 'selected' : ''"
 >
   <mat-icon *ngIf="item.icon">{{item.icon}}</mat-icon>
   <span class="pl-2">{{item.label}}</span>
 </a> 
  
</mat-nav-list>
