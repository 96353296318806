import { PrintComponent } from './components/print/print.component';
import { LazyUrlComponent } from './components/lazy-url/lazy-url.component';
import { ExportTableComponent } from './components/export-table/export-table.component';
import { DocumentService } from './../services/document.service';
import { CatalogoService } from './../services/catalogo.service';
// import {JsonapiService} from './../services/jsonapi.service';
import {MaterialModule} from './../material/material.module';
import {LOCALE_ID, NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {FormsModule,  ReactiveFormsModule} from '@angular/forms';
// import {BookService} from '../services/book.service';
import {HttpClientModule} from '@angular/common/http';
// import {OauthService} from '../services/oauthservice.service';
import {GraficoComponent} from '../shared/components/grafico/grafico.component';
import {TabellaComponent} from '../shared/components/tabella/tabella.component';
import {TestoComponent} from '../shared/components/testo/testo.component';
import {NgxEchartsModule} from 'ngx-echarts';
import { CarrelloService } from '../services/carrello.service';
import { OauthService } from '../services/oauthservice.service';
import { PasswordStrengthMeterModule } from 'angular-password-strength-meter';
import {AlertBoxComponent} from '../shared/components/alert-box/alert-box.component';


const MODULES = [
    CommonModule,
    MaterialModule, 
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    PasswordStrengthMeterModule
];

const SERVICES = [
  CatalogoService,
  CarrelloService,
  OauthService,
  DocumentService
  // JsonapiService,
];


@NgModule({
  declarations: [
    GraficoComponent,
    TestoComponent,
    TabellaComponent,
    AlertBoxComponent,
    ExportTableComponent,
    LazyUrlComponent,
    PrintComponent
  ],
  imports: [
    MODULES,
    NgxEchartsModule
  ],
  providers: [{
    provide: LOCALE_ID,
    useValue: 'it-IT'
  }, ...SERVICES],
  exports: [...MODULES, TestoComponent, TabellaComponent, GraficoComponent, 
    AlertBoxComponent, ExportTableComponent, LazyUrlComponent, PrintComponent
  ],
})
export class SharedModule {}
