<div class="novita">
  <mat-card class="bgColorPrimaryLight">
    <div class="row" *ngIf="!novita || loader" >
      <div class="col-12  text-center"> 
      <mat-spinner ></mat-spinner>
      </div>
    </div>
    <div class="row" *ngIf="novita && novita.data && novita.data.length ===0 && !loader" >
      <div class="col-12  text-center"> 
        <h1 class="mt-5 white">Non ci sono novit&agrave; in uscita questa settimana</h1>
        <img src="assets/images/open-book.png" class="novitaImg mb-4">
      </div>
    </div>
    <div class="row" *ngIf="novita  && novita.data && novita.data.length && !loader" >
      <div class="col-4 col-sm-4 col-md-3 text-center">
        <h1 class="mt-5 white">Le novità della settimana</h1>
        <img src="assets/images/open-book.png" class="novitaImg mb-4">
       <!--  <button mat-flat-button color="primary" (click)="showAll = !showAll">
          {{showAll ? 'Nascondi' : 'Mostra tutte'}}
        </button> -->
      </div>
      <div class="col-8 col-sm-8 col-md-9">
        <div class="container-fluid">
            <owl-carousel-o [options]="customOptions"> 
                <ng-container *ngFor="let item of novita.data">
                  <ng-template carouselSlide [id]="item.codice">
                    <ali-libro-cedola [prodotto]="item"></ali-libro-cedola>
                  </ng-template>
                </ng-container>
            </owl-carousel-o>
        </div>
      </div>
    </div>
  </mat-card>
  </div>

  <!--- <ali-libro-cedola [prodotto]="item"></ali-libro-cedola> -->
<!-- - novita + novita in ordine 
PESCARE le cedole attive tabella F_TNEW PER stato = 2
IN F_RNEW cercare per NRE_NUMERO =  NTE_NUMERO. -->
<!-- <div class="container">
  <div class="row">
    <div class="col">
      <ali-testo icon="flight_land" title="Avviso merce spedita" subtitle="Hai della merce spedita oggi"></ali-testo> 
    </div>
    <div class="col">
      <ali-testo icon="flight_takeoff" title="Avviso in merce spedizione" subtitle="Hai della merce spedita oggi" type="ep_card-header-success"></ali-testo>  
    </div> 
  </div>
  <div class="row">
    <div class="col">
     <ali-tabella icon="book" title="Ordini caricati ieri" subtitle="Elenco ordini caricati ieri" type="ep_card-header-primary"></ali-tabella>
    </div>
  </div>
</div> -->
<!-- @Input() title = '';
@Input() subtitle = '';
@Input() icon = '';
@Input() type = '';
@Input() number = null;
@Input() name = null;
@Input() loading = false; -->

