import {LocalStorageService} from './storage.service';
import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {environment} from '../../environments/environment';
import {SuccessOautResponse} from '../models/oauth.model';
import {error} from 'echarts/lib/util/log';

@Injectable({
    providedIn: 'root'
})
export class OauthService {

    API_BASE: string = environment[environment.env].oauth_base_url;
    CLIENT_ID = environment[environment.env].client_id;
    CLIENT_SECRET = environment[environment.env].client_secret;
    BASE_URL = environment[environment.env].base_url;
    SEC_BEFORE_REFRESH = 600; 
    constructor(
        private httpclient: HttpClient,
        private st: LocalStorageService
        ) {
    }
  
    // tslint:disable-next-line: typedef
    login(userName: string, pwd: string) {
        /* Payload => body di postman */

        const payload = {
            grant_type: 'password',
            client_id: this.CLIENT_ID,
            client_secret: this.CLIENT_SECRET,
            username: userName,
            password: pwd,
            scope: ''
        };
        /* Header di postman */
        const header = new HttpHeaders({
             Accept: 'application/json',
            'Content-Type': 'application/json'
        });

        const options = {
            headers: header
        };

        return this.httpclient.post(`${this.API_BASE}/token`, payload, options);

    }

    async refreshToken() {
        /* Payload => body di postman */
        const token: SuccessOautResponse  = this.st.get('token');
        const payload = {
            grant_type: 'refresh_token',
            client_id: this.CLIENT_ID,
            client_secret: this.CLIENT_SECRET,
            refresh_token: token.refresh_token,
            scope: ''
        };
        /* Header di postman */
        const header = new HttpHeaders({
             Accept: 'application/json',
            'Content-Type': 'application/json'
        });

        const options = {
            headers: header
        };

         this.httpclient.post(`${this.API_BASE}/token`, payload, options).subscribe((data:SuccessOautResponse) => {
            this.st.set('token', data);
            this.st.set('tokenTimestamp', Math.floor(Date.now() / 1000));
         });
    }

    // tslint:disable-next-line: typedef
    logout(token: string) {
        /* Header di postman */
        const header = new HttpHeaders({
            Accept: 'application/json',
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`
        });

        const options = {
            headers: header
        };

        return this.httpclient.delete(`${this.BASE_URL}/logout`, options);

    }
 

    public checkTokenIsValid(): boolean {
        let isValid = false; 
        const token: SuccessOautResponse  = this.st.get('token');
        if (token) { 
          const tokenTimestamp = this.st.get('tokenTimestamp');
          const now            = Math.floor(Date.now() / 1000);  
          const remainTime =  (tokenTimestamp + token.expires_in) - now;
         
          if (now < tokenTimestamp + token.expires_in) {   
            if (remainTime < this.SEC_BEFORE_REFRESH) {
                this.refreshToken(); 
            } 
            isValid = true; 
          }  
        }  
        return isValid;
      }


    // tslint:disable-next-line: typedef
    getUser(token: string) {
        /* Header di postman */
        const header = new HttpHeaders({
            Accept: 'application/json',
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`
        });

        const options = {
            headers: header
        };

        return this.httpclient.get(`${this.BASE_URL}/me`, options);

    }

    changePwd(pwd: string) {
        const token: SuccessOautResponse = this.st.get('token');

        /* Payload => body di postman */
        const payload = {
            password: pwd
        };
        /* Header di postman */
        const header = new HttpHeaders({
            Accept: 'application/json',
            'Content-Type': 'application/json',
            Authorization: 'Bearer ' + token.access_token,
        });

        const options = {
            headers: header
        };

        return this.httpclient.post(`${this.BASE_URL}/reset-password`, payload, options);
    }
}
