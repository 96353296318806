import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute } from '@angular/router';
import { CatalogoService } from 'src/app/services/catalogo.service';
import {animate, state, style, transition, trigger} from '@angular/animations';
import * as _ from 'lodash'; 
import { righeOrdine } from 'src/app/models/rigaOrdine.model';
import { CarrelloService } from 'src/app/services/carrello.service';
import { MessageService } from 'src/app/services/message.service';
import { Utilities } from '../../../app/utilities/utilities.class';

@Component({
  selector: 'ali-novita-in-ordine',
  templateUrl: './novita-in-ordine.component.html',
  styleUrls: ['./novita-in-ordine.component.css'],
  animations: [
    trigger('detailExpand', [
      state('collapsed', style({height: '0px', minHeight: '0'})),
      state('expanded', style({height: '*'})),
      transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
    ]),
  ],
})
export class NovitaInOrdineComponent implements AfterViewInit {

  displayedColumns: string[] = [ 'ean', 'titolo', 'editore', 'autore', 'qta_rimanenza','prezzo','dummy','tot_riga'];
  columnsName: string[] = [ 'Ean', 'Titolo', 'Editore', 'Autore', 'Qta Ordinata','Prezzo','','Totale Riga'];
  /**
   * nr.ordine, ean , titolo, autore, editore, da consegnare, prezzo, sconto, da pagare, stato, data
   */
  dataSource;
  expandedElement: any | null;
  initData = null;
  loader = false;
  causali:Array<any> = [];
  selected = "0";
  defaultSort = 'editore';
  defaultSortDir ='asc'
  items = [];
  itemQuantity= {};

  visualizzaArrivi
  @ViewChild(MatSort) sort: MatSort;
  
  constructor( private catalogoservice: CatalogoService, 
    private activatedRoute: ActivatedRoute,
    private carrelloservice: CarrelloService,
    private messageservice: MessageService) {
    
    this.getOrdiniCliente();
    this.listenCart();
    console.log(this.itemQuantity);
  }
  
   ngAfterViewInit() {
     if (this.dataSource && this.dataSource.sort) {
      this.dataSource.sort = this.sort;
     } 
  }

  public addToChart($event) {
    this.carrelloservice.setItemToCart($event.codice, $event.qta).subscribe((data) => {
      this.updateQunatity($event);
      this.messageservice.setEvent({type : 'cart', event: data});
    });
  }

  public listenCart() {
    this.messageservice.listenEvent().subscribe((data) => {
      if (data.type === 'cart'){
        data.event.items.forEach(item =>{
          const i = _.findIndex(this.dataSource.data, { 'ean': item.ean});
          if(i>=0){
            this.itemQuantity[item.ean] = Number(item.qta);
          } 
        })
      }
    });
  }

  updateQunatity(item){
    console.log(item);
    const i = _.findIndex(this.dataSource.data, function(o) { return o.childs[0].cod_articolo == item.codice; });
    if(i > -1){
      this.dataSource.data[i].qta = item.qta;
    }

  }

  totalRow(element){
    const total = Number(element.prezzo) * Number(element.qta);
    return Number(total);
  }

  //  titolo alfabetico

  public getOrdiniCliente(order = 'ean') {
      this.loader = true;
      this.catalogoservice.getOrderedBooksOfTheWeek().subscribe((data)=>{
        this.initData = data;
        //console.log(data);
        /** 
         * ottengo le causali e il loro codice 
         */
        //this.buildCausali(data); 
        const keyToCast = ['qta', 'qta_rimanenza','qta_in_spedizione'];
        this.dataSource = new MatTableDataSource(this.buildDataSource(Utilities.castToNumber(data, keyToCast))); 
        //console.log(this.dataSource.data);
        this.loader = false;
        /* const in_spedizione = this.activatedRoute.snapshot.paramMap.get('in_spedizione');
        if (in_spedizione === 'in_spedizione') {
          this.visualizzaArrivi = true;
          this.arriviFilter();
        } */

      });
  }
 

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();

    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }

  total(column: string) {
      const totalColumns = ['qta_in_spedizione', 'qta_rimanenza'];
      if (totalColumns.indexOf(column) > -1) {
        const data = this.dataSource.data;
        return _.sumBy(data, column);
      }  
      return;
  }

  formatField(column: string) { 
    const qta = ['qta_in_spedizione', 'qta_rimanenza'];
    if (qta.indexOf(column) > -1) {
      if (Number(column) === 0) {
        return ''
      }
    }
    return column;
  }

  /* sortData($event) { 
    const orderBy = $event.active;
    const direction = ($event.direction==='desc') ? '-' : '';
    const order = direction+orderBy;
    this.getOrdiniCliente(order);
    this.loader = true; 
    const orderBy = $event.active;
    const direction = $event.direction;
    const collection =  _.orderBy(this.dataSource.data, [orderBy], [direction]);
    this.dataSource = null;
    this.dataSource = new MatTableDataSource(collection); 
    console.log(this.dataSource);
    this.loader = false;
  } */
  

  sortData($event) {
    this.defaultSortDir = $event.direction;
    this.defaultSort    = $event.active; 
    const data =  _.orderBy(this.dataSource.data, [this.defaultSort],[this.defaultSortDir]); 
    this.dataSource.data = data;
}

  buildCausali(data:Array<any>) {
    const g = _.groupBy(data, 'nr_causale');
      Object.entries(g).forEach((item) => { 
        let codice_causale = item[0];
        this.causali.push({'cod': codice_causale, 'descrizione':_.find(item[1], { 'nr_causale': codice_causale })['causale_ordine'] });  
      })  
  }

  filtraCausale() {
    if (this.selected === "0") {
        this.buildDataSource(this.initData);
        return;
    } 
    const data = _.filter(this.initData, {'nr_causale' : this.selected}); 
    this.dataSource = new MatTableDataSource(this.buildDataSource(data));
    return; 
  }

  arriviFilter() { 
      let data = [];
      if (this.visualizzaArrivi) {
         data = _.filter(this.initData, function(o) { return  Number(o.qta_in_spedizione) > 0 });
         this.dataSource = new MatTableDataSource(this.buildDataSource(data));
      } else {
         this.dataSource = new MatTableDataSource(this.buildDataSource(this.initData));
      } 
  }

  buildDataSource(data) {
    let codarticolo = '';  
    let dataSource = [];  
    data.forEach((item) => { 
      (item.autore) ? item.autore = item.autore.trim() : '';
      if(codarticolo !== item.cod_articolo) {
        codarticolo =  item.cod_articolo;
        let row = {};
        this.displayedColumns.forEach((field: string) => {
            row[field] = item[field];
        });
        row['childs'] = _.filter(data, {'cod_articolo' :  codarticolo});
        row['qta'] = item.qta
        row['qta_in_spedizione'] = _.sumBy(row['childs'], function(o) { return Number(o.qta_in_spedizione); });
        row['qta_rimanenza'] = _.sumBy(row['childs'], function(o) { return Number(o.qta_rimanenza); });
        row['tot_riga'] = 0;
      
        dataSource.push(row);
      }
    });
    return _.orderBy(dataSource, ['editore', 'titolo'],['asc', 'asc']); 
  }
   
  
}
