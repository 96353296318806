import { CedolaProdotto } from './../../models/catalogo.model';
import {Component, Input, OnInit} from '@angular/core';
import {Articolo} from '../../models/catalogo.model';
import moment from 'moment';
import { OwlOptions } from 'ngx-owl-carousel-o';

@Component({
  selector: 'ali-image-carousel',
  templateUrl: './image-carousel.component.html',
  styleUrls: ['./image-carousel.component.css']
})
export class ImageCarouselComponent implements OnInit {
  @Input() items: CedolaProdotto[];
  title: string;
  subtitle: string = null;
  customOptions: OwlOptions = {
    loop: true,
    mouseDrag: false,
    touchDrag: false,
    pullDrag: true,
    dots: false,
    navSpeed: 700,
    navText: ['<', '>'],
    autoHeight: true,
    responsive: {
      0: {
        items: 1
      },
      400: {
        items: 2
      },
      740: {
        items: 3
      },
      940: {
        items: 6
      }
    },
    nav: true
  };
  constructor() { }

  ngOnInit(): void {
    this.title = this.items[0].cedola_descrizione;
    this.subtitle = this.items[0].cedola_data;
  }

}
