  <mat-card class="my-3" *ngFor="let item of catalogo.data">
    <mat-card-content>

    <div class="row">
      <div class="col-2 col-sm-3 col-lg-2 ">
        <ali-image [codice]="item.codice"></ali-image>
      </div>
      <div class="col-8 col-sm-6 col-lg-8 ">
        <div class="container-fluid">
          <div class="row">
            <div class="col-12 col-md-8 pl-0">
              <h3 class="mb-1 itemTitle colorDark">{{item.titolo | bookTitle}}</h3>
              <div class="m-0">
                <span class="colorDark">Autore: </span>
                <span class="colorPrimary">{{item.autore | uppercase}}</span>
                <span class="colorPrimary" *ngIf="item.autore2">, {{item.autore2 | uppercase}}</span>
                <!--          <button mat-button color="accent"-->
                <!--                  (click)="getBooksAutore(book.cod_autore_1, book.autore)">{{book.autore | uppercase}}</button>-->
              </div>
              <div class="m-0">
                <span class="colorDark">Editore: </span>
                <span class="colorPrimary">{{item.editore | uppercase}}</span>
              </div>
            </div>
          </div>
        </div>
        <div class="m-0">
          <span class="colorDark">ISBN: </span>{{item.ean}}
        </div>
        <div class="row">
          <div class="col-4"><span class="colorDark">Data di pubblicazione: </span>{{formatData(item.prevista_pubb)}}</div>
          <div class="col-4"><span class="colorDark">Collana: </span>{{item.collana}}</div>
          <div class="col-4"><span class="colorDark">Stato: </span>{{articoloTools.stato(item)}}</div>
        </div>
      </div>
      <div class="col-2 col-sm-3 col-lg-2  right">
        <div class="ribbon-content">
          <!-- <div class="ribbon ristampa px-5" ><span class="uppercase">Ristampa</span></div> -->
        </div>
        <h3 class="text-center price">{{item.prezzo |currency}}</h3>
        <ali-order-quantity
          *ngIf="user.isCliente()"
          [qta]="item.qta"
            [codice]="item.codice"
            [disponibilita]="item.disponibilita"
            (editQta)="setQta($event)"
          ></ali-order-quantity>
          <div class="container-fluid mb-3">
            <div class="row justify-content-center">
              <button (click)="schedaProdotto(item)"  mat-flat-button color="accent" style="margin-bottom: 1em; display: block; width: 150px" >
                Dettaglio
              </button>
            </div>
          </div>

      </div>
      <div class="ribbon novita px-5"  *ngIf="item.novita==='s'" ><span class="uppercase">Novità</span></div>
    </div>
  </mat-card-content>

  </mat-card>
