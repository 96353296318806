 <div class="row ctr-panel" id="sticky" *ngIf="!advancedSearch">
  <div class="col-12">
    <mat-card class="py-1 "> 
      <div class="block-ricerca">

        <mat-form-field appearance="legacy" class="full-width">
          <mat-label>Ricerca</mat-label>
          <input matInput placeholder="Ricerca" name="search" [formControl]="form"  (focus)="getName($event)" (keydown.enter)="Onsubmit()"> 
        </mat-form-field> 
      </div> 
      <div> 
        <button mat-stroked-button color="primary" (click)="Onsubmit()">
        Cerca
        <mat-icon>search</mat-icon>
       </button>
      </div>
      <div> 
    <mat-form-field appearance="legacy" class="block-ordinamento"> 
      <mat-label>Ordina per</mat-label>
      <mat-select  [(ngModel)]="order" (selectionChange)="orderEvent(order)"> 
        <mat-option value="{{item.cod}}" *ngFor="let item of orderType">{{item.descrizione}}</mat-option>    
      </mat-select>
    </mat-form-field>
  </div>
  <button mat-stroked-button  (click)="ricercaAvanzata()"
  matTooltipPosition="below" matTooltip="Ricerca Avanzata">
    <mat-icon>saved_search</mat-icon>
    Ricerca avanzata
   </button>
   &nbsp;
  <div class="reset">
    <button mat-stroked-button color="primary" (click)="resetFormField()" 
    matTooltipPosition="below" matTooltip="Reimposta i filtri">
      <mat-icon svgIcon="reset_filter"></mat-icon>
    </button>
  </div>
   <div class="block-options">
    <mat-button-toggle-group appearance="legacy" [(ngModel)]="view" (ngModelChange)="changeView($event)" class="btn-select">
              <mat-button-toggle value="table" >
                <span class="material-icons">list</span></mat-button-toggle>
              <mat-button-toggle value="grid">
                <span class="material-icons"  >grid_view</span>
              </mat-button-toggle>
            </mat-button-toggle-group>
          </div>
       <!--    <button mat-stroked-button color="primary" >
            <mat-icon>money_off</mat-icon>
          </button> -->
    </mat-card>
  </div>
</div>  
<div class="row ctr-panel" id="sticky" *ngIf="advancedSearch" >
  <div class="col-12">
    <mat-card class="py-1 "> 
      <div class="block-ricerca" >
        <form [formGroup]="group">
        <div class="field" *ngFor="let field of ricerca.fields">
          <mat-form-field class="full-width" *ngIf="field.option.type === 'text'">
            <mat-label>{{field.field.replace('_',' ')}}</mat-label>
            <input matInput  placeholder="{{field.field.replace('_',' ')}}"
                  type="{{field.option.type}}" name="{{field.field}}"
                  (focus)="getName($event)" id="{{field.field}}"
                  [formControlName]="field.field" value="{{group.get(field.field).value}}"
                  (keydown.enter)="Onsubmit()"
                  >
          </mat-form-field>

          <mat-form-field class="full-width" *ngIf="field.option.list">
            <mat-label>{{field.field.replace('_',' ')}}</mat-label>
            <select matNativeControl
                    [formControl]="form" name="{{field.field}}" (focus)="getName($event)" id="{{field.field}}">
              <option value="0">Tutti</option>
              <option *ngFor="let item of field.option.list" value="{{item.nome}}">
                {{item.nome}}
              </option>
            </select>
          </mat-form-field>
        
         <!--  <ali-lazy-url
          *ngIf="field.option.lazyUrl"
            [itemsLabel]="field.field.replace('_',' ')"
            (searching)="getValues($event)"
            [filtereditems]="filtereditems"
            (selectedItems)="selected($event)"
            [resetFormField]="reset"
          ></ali-lazy-url> -->
        </div> 
      </form>
      </div>
      <hr>
      <!-- <div class="block-ricerca"> 
        <mat-form-field appearance="legacy" class="full-width">
          <mat-label>Ricerca</mat-label>
          <input matInput placeholder="Ricerca" name="search" [formControl]="form"  (focus)="getName($event)"> 
        </mat-form-field> 
      </div>  -->
      <div> 
        <button mat-stroked-button color="primary" (click)="Onsubmit()">
        Cerca
        <mat-icon>search</mat-icon>
       </button>
      </div>
      <div> 
    <mat-form-field appearance="legacy" class="block-ordinamento"> 
      <mat-label>Ordina per</mat-label>
      <mat-select [(ngModel)]="order" > 
        <mat-option value="{{item.cod}}" *ngFor="let item of orderType">{{item.descrizione}}</mat-option>    
      </mat-select>
    </mat-form-field>
  </div>
  <button mat-stroked-button (click)="ricercaAvanzata()"
  matTooltipPosition="below" matTooltip="Ricerca Semplice">
    <mat-icon>search_off</mat-icon>
    Ricerca semplice
   </button>
   &nbsp;
  <div class="reset">
    <button mat-stroked-button color="primary" (click)="resetFormField()" 
    matTooltipPosition="below" matTooltip="Reimposta i filtri">
      <mat-icon svgIcon="reset_filter"></mat-icon>
    </button>
  </div>
   <div class="block-options">
    <mat-button-toggle-group appearance="legacy" [(ngModel)]="view" (ngModelChange)="changeView($event)" class="btn-select">
              <mat-button-toggle value="table" >
                <span class="material-icons">list</span></mat-button-toggle>
              <mat-button-toggle value="grid">
                <span class="material-icons"  >grid_view</span>
              </mat-button-toggle>
            </mat-button-toggle-group>
    </div>
  
    </mat-card>
  </div>
</div>