<div class="loading-shade" *ngIf="loader">
  <mat-spinner></mat-spinner>
</div>

<div class="pag" *ngIf="!loader" >
  <div class="row">
    <div class="col">
      <mat-form-field style="width: 100%;">
        <mat-label>Cerca</mat-label>
        <input matInput (keyup)="applyFilter($event)"  #input>
      </mat-form-field>
    </div>
    <div class="col-2">
      <mat-form-field>
          <mat-label>Emessi dalla data</mat-label>
          <input matInput [matDatepicker]="picker" readonly
                 (dateInput)="addEvent()" [formControl]="start" (dateChange)="addEvent()">
          <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
          <mat-datepicker #picker></mat-datepicker>
      </mat-form-field>
    </div>
    <div class="col-2">
      <mat-form-field >
          <mat-label>Alla data</mat-label>
          <input matInput [matDatepicker]="picker2" readonly
                 (dateInput)="addEvent()" [formControl]="stop" (dateChange)="addEvent()">
          <mat-datepicker-toggle matSuffix [for]="picker2"></mat-datepicker-toggle>
          <mat-datepicker #picker2></mat-datepicker>
      </mat-form-field>
    </div>
    <ali-export-table [data]="dataExport" *ngIf="dataExport.length"></ali-export-table>
  </div>

  <div *ngIf="dataSource && !dataSource.data.length">
    <ali-alert-box type="warning" msg="Nessuna fattura trovata"></ali-alert-box>
  </div>

    <div class="mat-elevation-z8" *ngIf="!loader && dataSource.data.length" >
        <table mat-table 
        [dataSource]="dataSource" multiTemplateDataRows   (matSortChange)="sortData($event)" class="mat-elevation-z4" matSort>
   <ng-container matColumnDef="{{column}}" *ngFor="let column of displayedColumns; let i=index">
     <th mat-header-cell *matHeaderCellDef mat-sort-header > <span class="toptable">{{columnsName[i]}}</span> </th> 
     <td mat-cell *matCellDef="let element" class="{{column}} {{(element[column] < 0) ? 'negative' : ''}}"> 
      <span *ngIf="column!=='id_fattura' && column!=='nr_fattura'" >{{format(column, element[column])}} </span> 
      <span *ngIf="column==='nr_fattura'" >
        <mat-icon class="open"  (click)="expandedElement = expandedElement === element ? null : element; openRows(element.rows)">folder_open</mat-icon>&nbsp;{{format(column, element[column])}}
      </span>
      <span *ngIf="column==='id_fattura'" >
        <button mat-raised-button (click)="downloadPdf('fat', element[column])" *ngIf="(element.nr_fattura*1)">
          <mat-icon >download</mat-icon> 
          Fattura 
      </button>
      </span> 
      </td>
     <td mat-footer-cell *matFooterCellDef class="totale" >{{total(column)}}</td>
   </ng-container>
   <ng-container matColumnDef="expandedDetail">
    <td mat-cell *matCellDef="let element" [attr.colspan]="displayedColumns.length">
      <div class="example-element-detail "  *ngIf="expandedElement === element"  >
          <ali-fatture [data]=element.rows></ali-fatture> 
      </div>
    </td>
  </ng-container>
  <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
     <tr mat-row *matRowDef="let element; columns: displayedColumns;"
         class="example-element-row"
         [class.example-expanded-row]="expandedElement === element"
         
        >

     </tr>
     <tr mat-row *matRowDef="let row; columns: ['expandedDetail']" class="example-detail-row"></tr>
     <tr class="mat-row" *matNoDataRow>  <td class="mat-cell" colspan="4">Non ho trovato dati per il valore "{{input.value}}"</td></tr>
     <tr mat-footer-row *matFooterRowDef="displayedColumns; sticky: true"></tr>
 </table>
    </div>
</div>
