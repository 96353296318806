import { Component, Input, OnInit } from '@angular/core';
import {MatTableDataSource} from '@angular/material/table';
import { Scadenza } from 'src/app/models/scadenze.model';
import * as _ from 'lodash';

@Component({
  selector: 'ali-calendar',
  templateUrl: './calendar.component.html',
  styleUrls: ['./calendar.component.css']
})
export class CalendarComponent implements OnInit {
  @Input() dataSource: MatTableDataSource<Scadenza>;
  collection:MatTableDataSource<Scadenza> = null;
  dataExport = [];
  intervalli = [
    {
    gmin : 120,
    gmax : null,
    tot: null,
    collection : null,
    label: 'Oltre 120',
    class:''
   }, 
   {
     gmin : 90,
     gmax : 120,
     tot: null,
     collection : null,
     label: '90/120',
     class:''
   },
   {
     gmin: 60,
     gmax: 90,
     tot: null,
     collection : null,
     label: '60/90',
     class:''
   },
   {
     gmin: 30,
     gmax: 60,
     tot: null,
     collection : null,
     label: '30/60',
     class:''
   },
   {
     gmin: 0,
     gmax: 30,
     tot: null,
     collection : null,
     label: '0/30',
     class:'current'
   },
   {
    gmin: 0,
    gmax: -30,
    tot: null,
    collection : null,
    label: '0/30',
    class:'current'
  },
   {
    gmin: -30,
    gmax: -60,
    tot: null,
    collection : null,
    label: '30/60',
    class:''
  },
  {
    gmin: -60,
    gmax: -90,
    tot: null,
    collection : null,
    label: '60/90',
    class:''
  },
  {
    gmin: -90,
    gmax: -120,
    tot: null,
    collection : null,
    label: '90/120',
    class:''
  },
  {
    gmin: -120,
    gmax: null,
    tot: null,
    collection : null,
    label: 'Oltre 120',
    class:''
  }
];

  constructor() { } 
  ngOnInit(): void {
      const collection = this.dataSource.data;
      this.intervalli.forEach((item) => {
        if (item.gmin < 0 || item.gmax < 0) {
          if( item.gmax=== null) {
            const report = _.filter(collection, function(o) { return ( o.giorni_scadenza < item.gmin) });
            item.collection = report;
            item.tot = _.sumBy(report, 'a_saldo');
          } else {
            const report = _.filter(collection, function(o) { return ( o.giorni_scadenza < item.gmin && o.giorni_scadenza >= item.gmax) });
            item.collection = report;
            item.tot = _.sumBy(report, 'a_saldo');
          } 
        } else {
          if( item.gmax=== null) {
            const report = _.filter(collection, function(o) { return ( o.giorni_scadenza > item.gmin) });
            item.collection = report;
            item.tot = _.sumBy(report, 'a_saldo');
          } else {
            const report = _.filter(collection, function(o) { return ( o.giorni_scadenza > item.gmin && o.giorni_scadenza <= item.gmax) });
            item.collection = report;
            item.tot = _.sumBy(report, 'a_saldo');
          } 
        }
       
      });
      this.collection =  null;
  } 

  setCollection(collection) {
    this.collection = new MatTableDataSource(collection);
  }


  
}
