import { Input, SimpleChanges } from '@angular/core';
import { Component, OnInit } from '@angular/core';
import * as _ from 'lodash';

@Component({
  selector: 'ali-progress-bar',
  templateUrl: './progress-bar.component.html',
  styleUrls: ['./progress-bar.component.css']
})
export class ProgressBarComponent implements OnInit {
    @Input() itemSize;
   
    @Input() flagaudio:boolean = false;
    @Input() flagimage:boolean = false;
    @Input() flagpdf:boolean = false;
    totalSize:number = 0;
    percentuale = {};
    constructor() { }

    ngOnInit(): void {
        console.log(this.itemSize);
        this.itemSize.forEach(item =>{
            this.percentuale[item.field] = item.value;
        })
    }


    ngOnChanges(changes: SimpleChanges) {
        if(changes.itemSize!== undefined && changes.itemSize.currentValue){  
            this.itemSize = changes.itemSize.currentValue;
        }
    }

    addBarStyle(item){
        let total = _.sumBy(this.itemSize, 'value');
        if(total){
            this.percentuale[item.field] = (item.value * 100/total).toFixed(1)+'%';
            return {
                'width':  this.percentuale[item.field], 
                'background-color' : item.color
            }
        }
        return {};
    }
}
