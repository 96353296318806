import {Injectable, Injector} from '@angular/core';
import {Router} from '@angular/router';
import {environment} from 'src/environments/environment';
import {LocalStorageService} from './storage.service';
import {SuccessOautResponse} from '../models/oauth.model';
import {HttpHeaders, HttpClient} from '@angular/common/http';
import {Subscribable} from 'rxjs';

export interface filters {
    name: string;
    value: any;
}


@Injectable({
    providedIn: 'root'
})
export class BookService {
    API_BASE: string = environment[environment.env].base_url;
    cache = null;

    constructor(
        private injector: Injector,
        private httpclient: HttpClient,
        private localStorage: LocalStorageService,
        private router: Router
    ) {
        this.initCache();
    }

    public saveCatalogoBook(payload, cod) {
        const serviceHeaders = this.getHeaders();
        const options = {headers: serviceHeaders};
        return this.httpclient.patch(`${this.API_BASE}/update-abstract-note/${cod}`, payload, options);
    }

    public getBestSellerBook(start, stop, type, limit) {
        const serviceHeaders = this.getHeaders();
        const options = {headers: serviceHeaders};
        return this.httpclient.get(`${this.API_BASE}/best-sellers/book/${start}/${stop}/${limit}/${type}`, options);
    }

    public getBestSellerLibrerie(start, stop, type, limit) {
        const serviceHeaders = this.getHeaders();
        const options = {headers: serviceHeaders};
        return this.httpclient.get(`${this.API_BASE}/best-sellers/librerie/${start}/${stop}/${limit}/${type}`, options);
    }

    public getBookStats(code, type, onlyCda, start = null, stop = null, ) {
        const serviceHeaders = this.getHeaders();
        const options = {headers: serviceHeaders};
        const url = (start !== null && stop !== null) ? `${this.API_BASE}/stats/libro/${type}/${code}/${onlyCda}/${start}/${stop}` :
            `${this.API_BASE}/stats/libro/${type}/${code}/${onlyCda}`;
        return this.httpclient.get(url, options);
    }

    public setRistampa(ean: string) {
        const serviceHeaders = this.getHeaders();
        const options = {headers: serviceHeaders};
        return this.httpclient.get(`${this.API_BASE}/ristampa/${ean}`, options);
    }

    public getGiri() {
        const serviceHeaders = this.getHeaders();
        const options = {headers: serviceHeaders};
        return this.httpclient.get(`${this.API_BASE}/giri`, options);
    }

    public clienti() {
        const serviceHeaders = this.getHeaders();
        const options = {headers: serviceHeaders};
        return this.httpclient.get(`${this.API_BASE}/clienti`, options);
    }

    public getGiriByYear(year, successivi = 0) {
        const serviceHeaders = this.getHeaders();
        const options = {headers: serviceHeaders};
        return this.httpclient.get(`${this.API_BASE}/giri-anno/${year}/${successivi}`, options);
    }

    public getVenduto(year) {
        const serviceHeaders = this.getHeaders();
        const options = {headers: serviceHeaders};
        return this.httpclient.get(`${this.API_BASE}/venduto/${year}`, options);
    }

    private async initCache() {
        this.cache = await caches.open(environment.cache);
    }

    public collane(filter: string) {
        const serviceHeaders = this.getHeaders();
        const options = {headers: serviceHeaders};
        return this.httpclient.get(`${this.API_BASE}/collane?filter=${filter}`, options);
    }

    public getAutoriInfo(autori) {
        const payload = {
            authors : autori
        };
        const serviceHeaders = this.getHeaders();
        const options = {headers: serviceHeaders};
        return this.httpclient.post(`${this.API_BASE}/autori`, payload, options);
    }

    public getBooks() {
        const serviceHeaders = this.getHeaders();
        const options = {headers: serviceHeaders};
        return this.httpclient.get(`${this.API_BASE}/catalogo`, options);
    }

    public dettaglio(cod) {
        const serviceHeaders = this.getHeaders();
        const options = {headers: serviceHeaders};
        return this.httpclient.get(`${this.API_BASE}/catalogo/${cod}`, options);
    }

    public getNovita() {
        const serviceHeaders = this.getHeaders();
        const options = {headers: serviceHeaders};
        return this.httpclient.get(`${this.API_BASE}/libri/novita`, options);
    }

    public getEntityNovita(oid) {
        const serviceHeaders = this.getHeaders();
        const options = {headers: serviceHeaders};
        return this.httpclient.get(`${this.API_BASE}/novita/${oid}`, options);
    }
    public getBook(cod: string) {
        const serviceHeaders = this.getHeaders();
        const options = {headers: serviceHeaders};
        return this.httpclient.get(`${this.API_BASE}/catalogo/${cod}`, options);
    }


    public getCollectionCached(collectionName): Request  {
        const token: SuccessOautResponse = this.localStorage.get('token');
        const options = {
                headers: {
                    'Content-Type': 'application/json',
                    Accept: 'application/json',
                    Authorization: 'Bearer ' + token.access_token,
                }
            };
        const jsonResponse = new Request(`${this.API_BASE}/${collectionName}`, options);
        return jsonResponse;
    }

    public getGiacenza() {
        const serviceHeaders = this.getHeaders();
        const options = {headers: serviceHeaders};
        return this.httpclient.get(`${this.API_BASE}/giacenza`, options);
    }


    

    public partizioni(code) {
        const serviceHeaders = this.getHeaders();
        const options = {headers: serviceHeaders};
        return this.httpclient.get(`${this.API_BASE}/partizioni/${code}`, options);
    }
    /** admin part */

    /** ottiene l'ultima data di esportazione delle immagini */
    public getDataExport() {
        const serviceHeaders = this.getHeaders();
        const options = {headers: serviceHeaders};
        return this.httpclient.get(`${this.API_BASE}/export/data`, options);
    }
    /** fa partire il job per la generazione del file zip delle immagini per amazon */
    public getExport(data) {
        const serviceHeaders = this.getHeaders();
        const options = {headers: serviceHeaders};
        return this.httpclient.get(`${this.API_BASE}/export/download/${data}`, options);
    }
    /** report */
    public editoriReport() {
        const serviceHeaders = this.getHeaders();
        const options = {headers: serviceHeaders};

        return this.httpclient.get(`${this.API_BASE}/report/editori`, options);
    }

    public anniReport(editore) {
        const serviceHeaders = this.getHeaders();
        const options = {headers: serviceHeaders};

        return this.httpclient.get(`${this.API_BASE}/report/anni/${editore}`, options);
    }


    public mesiReport(editore, anno) {
        const serviceHeaders = this.getHeaders();
        const options = {headers: serviceHeaders};

        return this.httpclient.get(`${this.API_BASE}/report/mesi/${editore}/${anno}`, options);
    }

    public downloadReport(editore, anno, mese) {
        /* const serviceHeaders = this.getHeaders();
        const options = {headers: serviceHeaders};
        const requestOptions: object = Object.assign({responseType: 'Blob'}, options);
        return this.httpclient.get(, requestOptions); */
        const ServiceHeaders = this.getHeadersDownload();
        const options = {headers: ServiceHeaders};
        const requestOptions: object = Object.assign({responseType: 'Blob'}, options);

        return this.injector.get(HttpClient).get(`${this.API_BASE}/report/get/${anno}/${mese}/${editore}`, requestOptions);
    }

    /*******/

    public autori(filter: string) {
        const serviceHeaders = this.getHeaders();
        const options = {headers: serviceHeaders};
        return this.httpclient.get(`${this.API_BASE}/autori?filter=${filter}`, options);
    }

    public editori() {
        const serviceHeaders = this.getHeaders();
        const options = {headers: serviceHeaders};
        return this.httpclient.get(`${this.API_BASE}/editori`, options);
    }

    public categorie() {
        const serviceHeaders = this.getHeaders();
        const options = {headers: serviceHeaders};
        return this.httpclient.get(`${this.API_BASE}/categorie`, options);
    }


    private getHeaders(): HttpHeaders {
        const token: SuccessOautResponse = this.localStorage.get('token');
        const header = new HttpHeaders({
            'Content-Type': 'application/json',
            Accept: 'application/json',
            Authorization: 'Bearer ' + token.access_token,
        });

        return header;
    }

    private getHeadersDownload(): HttpHeaders {

        const token: SuccessOautResponse = this.localStorage.get('token');
        const header = new HttpHeaders({
            'Content-Type': 'application/x-www-form-urlencoded',
            Accept: 'application/json',
            Authorization: 'Bearer ' + token.access_token,
        });

        return header;
    }

    public image(ean: string, type: string) {
        //  const options = this.buildRequestOptions({ responseType: 'Blob' });
        const serviceHeaders = this.getHeaders();
        const options = {headers: serviceHeaders};
        const requestOptions: object = Object.assign({responseType: 'Blob'}, options);
        return this.httpclient.get(`${this.API_BASE}/image/ean/${ean}/${type}`, requestOptions);
    }

    public checkIsbn(isbn) {
        const serviceHeaders = this.getHeaders();
        const options = {headers: serviceHeaders}; //

        return this.httpclient.get(`${this.API_BASE}/check/isbn/${isbn}`, options);
    }

    /**
     *
     * @param formData
     * @param id
     */
    public insertCopertina(formData: FormData, id, isean = 0) {
        const token: SuccessOautResponse = this.localStorage.get('token');
        const header = new HttpHeaders({
            Accept: 'application/json',
            Authorization: 'Bearer ' + token.access_token,
        });
        const options = {headers: header}; //
        return this.httpclient.post(`${this.API_BASE}/set-copertina/${id}/${isean}`, formData, options);
    }

    public insertAdditionalImages(formData: FormData, id, isean = 0) {
        const token: SuccessOautResponse = this.localStorage.get('token');
        const header = new HttpHeaders({
            Accept: 'application/json',
            Authorization: 'Bearer ' + token.access_token,
        });
        const options = {headers: header}; //
        return this.httpclient.post(`${this.API_BASE}/store-additional-images/${id}/${isean}`, formData, options);
    }

    public viewAdditionalImages(ean) {
        const token: SuccessOautResponse = this.localStorage.get('token');
        const options = {
            headers: {
                'Content-Type': 'application/json',
                Accept: 'application/json',
                Authorization: 'Bearer ' + token.access_token,
            }
        };
        const jsonResponse = new Request(`${this.API_BASE}/view-additional-images/${ean}`, options);
        return jsonResponse;
    }
    public deleteAdditionalImages(isbn){
        const token: SuccessOautResponse = this.localStorage.get('token');
        const header = new HttpHeaders({
            Accept: 'application/json',
            Authorization: 'Bearer ' + token.access_token,
        });
        const options = {headers: header}; //
        return this.httpclient.delete(`${this.API_BASE}/delete-additional-images/${isbn}`, options);
    }

    public insertSchedaNovita(formData: FormData, id, isean = 0) {
        const token: SuccessOautResponse = this.localStorage.get('token');
        const header = new HttpHeaders({
            Accept: 'application/json',
            Authorization: 'Bearer ' + token.access_token,
        });
        const options = {headers: header}; //
        return this.httpclient.post(`${this.API_BASE}/set-scheda-novita/${id}/${isean}`, formData, options);
    }


    public catalogoRequest(): Request {
        const token: SuccessOautResponse = this.localStorage.get('token');
        const options = {
            headers: {
                'Content-Type': 'application/json',
                Accept: 'application/json',
                Authorization: 'Bearer ' + token.access_token,
            }
        };
        const jsonResponse = new Request(`${this.API_BASE}/catalogo`, options);
        return jsonResponse;
    }

    public imageRequest(ean: string, type: string): Request {
        const token: SuccessOautResponse = this.localStorage.get('token');
        const options = {
            headers: {
                'Content-Type': 'application/json',
                Accept: 'application/json',
                Authorization: 'Bearer ' + token.access_token,
            }
        };
        const jsonResponse = new Request(`${this.API_BASE}/image/ean/${ean}/${type}`, options);
        return jsonResponse;
    }

    public schedaNovitaRequest(ean: string): Request {
        const token: SuccessOautResponse = this.localStorage.get('token');
        const options = {
            headers: {
                'Content-Type': 'application/json',
                Accept: 'application/json',
                Authorization: 'Bearer ' + token.access_token,
            }
        };
        const response = new Request(`${this.API_BASE}/novita/ean/${ean}`, options);
        return response;
    }



    public insertAutore(payload: any) {
        const serviceHeaders = this.getHeaders();
        const options = {headers: serviceHeaders}; //
        return this.httpclient.post(`${this.API_BASE}/autore`, payload, options);
    }


    public insertWbook(payload: any) {
        const serviceHeaders = this.getHeaders();
        const options = {headers: serviceHeaders}; //
        return this.httpclient.post(`${this.API_BASE}/insert-book`, payload, options);
    }

    public saveBook(payload: any, oid) {
        const serviceHeaders = this.getHeaders();
        const options = {headers: serviceHeaders}; //
        return this.httpclient.patch(`${this.API_BASE}/save-book/${oid}`, payload, options);
    }

    public hadleError(error: Error) {
        // se http status 401 allora elimini tutto lo storage e cache e redirigi nella pagina di login

        console.warn(error);
    }

    public getAllegatoPromozionale(editore, giro) {
        const options = {
            headers: {
                'Content-Type': 'application/json',
                Accept: 'application/json',
            }
        };
        const response = new Request(`${this.API_BASE}/allegatipromozionali/${editore}/${giro}`, options);
        return response;
    }

    public setAllegatoPromozionale(editore, giro, allegato: FormData) {
        const token: SuccessOautResponse = this.localStorage.get('token');
        const header = new HttpHeaders({
            Accept: 'application/pdf',
            Authorization: 'Bearer ' + token.access_token,
        });
        const options = {headers: header}; //
        return this.httpclient.post(`${this.API_BASE}/set-allegatopromozionale/${editore}/${giro}`, allegato, options);
    }
    public deleteBook(id){
        const serviceHeaders = this.getHeaders();
        const options = {headers: serviceHeaders}; //
        return this.httpclient.delete(`${this.API_BASE}/novita/${id}`, options);
    }

    public getCedola(type, anno, mese, cliente) {
        const serviceHeaders = this.getHeaders();
        const options = {headers: serviceHeaders};
        const requestOptions: object = Object.assign({responseType: 'blob', filename: 'cedola.xls'}, options);
        return this.httpclient.get(`${this.API_BASE}/cedola/${type}/${anno}/${mese}/${cliente}`, requestOptions);
    }


    public getEditoriCliente(cod_cliente){
        const serviceHeaders = this.getHeaders();
        const options = {headers: serviceHeaders};
        return this.httpclient.get(`${this.API_BASE}/editori-cliente/${cod_cliente}`, options);
    }

    public scaricaArchivio(kind, anno, numero, cliente) {
        const serviceHeaders = this.getHeaders();
        const options = {headers: serviceHeaders};
        const requestOptions: object = Object.assign({responseType: 'blob', filename: 'cedola.xls'}, options);
        //console.log(`${this.API_BASE}/esportazione/${kind}/${anno}/${numero}/${cliente}`);
        return this.httpclient.get(`${this.API_BASE}/esportazione/${kind}/${anno}/${numero}/${cliente}`, requestOptions);
    }
}
