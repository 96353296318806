<div class="container-fluid my-1 py-1 cartItemContainer mat-elevation-z2">
  <div class="row">
    <div class="mycol">
      <ali-image [codice]="item.cod"></ali-image>
    </div>
    <div class="mycol">
      <div><strong>{{item.titolo | bookTitle}} </strong></div>
      <div><h4>ISBN:{{item.ean}}</h4></div>
      <div>
        Q.t&agrave;: <input type="number" min="1" style="max-width: 70px;" [(ngModel)]="item.qta" (ngModelChange)="qtaChanged($event)">
      </div>
      <div class="cart-item-row"> 
        <table  >
          <tr>
            <th>
              Qta.
            </th>
            <th>
              Prezzo
            </th>
           
            <th>
              Lordo
            </th>
            <th>
              Sconto
            </th>
            <th>
              Netto
            </th>
          </tr>
          <tr>
            <td>{{item.qta}}</td>
            <td>{{item.prezzo |currency}}</td>
            <td>{{item.qta*item.prezzo |currency}}</td>
            <td>{{item.sconto | sconto}} <span *ngIf="item.defiscalizzato> 0" > Def.</span></td>
            <td>{{sconto(item) |currency}}</td>
          </tr>
        </table>

     <!--    <div class="price">{{item.qta}} x {{item.prezzo | currency}}= {{item.qta*item.prezzo |currency}}</div> -->
      </div>
    </div>
    <div class="mycol btnContainer">
      <button mat-button (click)="delete(item.cod)" color="warn">elimina</button>
    </div>
  </div>
</div>
