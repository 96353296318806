 <div class="container">
    <div class="row">
        <div class="col" > 
              <mat-card  *ngIf="message===''" >
                <mat-card-title>
                    Cambia Password
                </mat-card-title>
                <mat-form-field class="full-width">
                <mat-label>Nuova password</mat-label>
                <input matInput placeholder="nuova password" type="password"  [(ngModel)]="password1"> 
              </mat-form-field>  
              <mat-form-field class="full-width">
                <mat-label>Ripeti password</mat-label>
                <input matInput placeholder="ripeti password" type="password" [(ngModel)]="password2"> 
              </mat-form-field> 
              <password-strength-meter [password]="password1"></password-strength-meter>
              <mat-card-actions>
                <ali-alert-box *ngIf="password1.length > 0 && password2.length > 0 && password1 !== password2"  msg="le password non coincidono"  type="warning"></ali-alert-box>
                <ali-alert-box *ngIf="password1 === password2  &&  password1.length < 8"  msg="La password deve essere almeno di 8 caratteri"  type="warning"></ali-alert-box>
                <button  mat-raised-button (click)="changePassword()" *ngIf="password1 === password2 && password1.length > 7" color="accent" class="full-width action">Conferma</button> 
              </mat-card-actions>
            </mat-card> 
            <mat-card *ngIf="message.length > 0">
              <ali-alert-box [msg]="message"  type="success"></ali-alert-box>
              <mat-card-actions>
                <button  mat-raised-button  color="accent" class="full-width action" routerLink="/home">Vai alla home page</button> 
              </mat-card-actions>
              
            </mat-card>
           

        </div>
    </div> 
</div>