<!--1_titolo slider-->
<!--2_array di Articolo-->
<mat-card class="m-3">
  <mat-card-header>
    <mat-card-title class="fs16 colorPrimary">{{items[0].cedola_descrizione}}</mat-card-title>
    <mat-card-subtitle class="fs14" *ngIf="items[0].cedola_data">{{items[0].cedola_data | formatDate}}</mat-card-subtitle>
  </mat-card-header>
  <mat-card-content>
<!--    <carousel-->
<!--      cellsToShow="4"-->
<!--      autoplay="true"-->
<!--      overflowCellsLimit="3"-->
<!--      autoplayInterval="3000"-->
<!--      transitionDuration="600"-->
<!--      height="440"-->
<!--    >-->
<!--      <div *ngFor="let item of items" class="carousel-cell">-->
<!--        <ali-libro-cedola [prodotto]="item"></ali-libro-cedola>-->
<!--      </div>-->
<!--    </carousel>-->
    <owl-carousel-o [options]="customOptions"> 
      <ng-container *ngFor="let item of items">
        <ng-template carouselSlide [id]="item.codice">
          <ali-libro-cedola [prodotto]="item"></ali-libro-cedola>
        </ng-template>
      </ng-container>

    </owl-carousel-o>
  </mat-card-content>
</mat-card>
