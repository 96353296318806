<img 
    src="/assets/icons/xls-icon.png" 
    width="48px" 
    height="48px" 
    (click)="exportexcel()"
    matTooltip="Esporta tabella in Excel">
<img 
    src="/assets/icons/pdf-icon.png" 
    width="36px" 
    height="48px" 
    (click)="generatePdf()" 
    matTooltip="Esporta tabella in PDF">