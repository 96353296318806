<mat-card class="section">
    <mat-card-header>
        <mat-card-title>
            <h3>In fase di approvazione</h3>
        </mat-card-title>
    </mat-card-header>
    <mat-card-content *ngIf="!toBeApproved.length">
      <ali-alert-box type="success" msg="Nessuna richiesta inviata"></ali-alert-box>
  </mat-card-content>
    <mat-card-content *ngIf="toBeApproved.length">
        <table mat-table [dataSource]="toBeApproved" class="mat-elevation-z8">

            <!--- Note that these columns can be defined in any order.
                  The actual rendered columns are set as a property on the row definition" -->
           
            <ng-container matColumnDef="id">
              <th mat-header-cell *matHeaderCellDef> Nr. </th>
              <td mat-cell *matCellDef="let element"> {{element.id}} </td>
            </ng-container> 
            <ng-container matColumnDef="data_richiesta">
              <th mat-header-cell *matHeaderCellDef> Data richiesta </th>
              <td mat-cell *matCellDef="let element"> {{element.created_at | date }} </td>
            </ng-container>
           
             <ng-container matColumnDef="colli">
                <th mat-header-cell *matHeaderCellDef> Nr. Colli </th>
                <td mat-cell *matCellDef="let element"> {{element.colli}} </td>
              </ng-container>
              <ng-container matColumnDef="peso">
                <th mat-header-cell *matHeaderCellDef> Peso </th>
                <td mat-cell *matCellDef="let element"> {{element.peso}} </td>
              </ng-container>
              <ng-container matColumnDef="valore">
                <th mat-header-cell *matHeaderCellDef> Valore </th>
                <td mat-cell *matCellDef="let element"> {{element.valore}} </td>
              </ng-container>
              <ng-container matColumnDef="note">
                <th mat-header-cell *matHeaderCellDef> Note </th>
                <td mat-cell *matCellDef="let element"> {{element.note}} </td>
              </ng-container>
              <ng-container matColumnDef="nr_ddt">
                <th mat-header-cell *matHeaderCellDef> Nr ddt </th>
                <td mat-cell *matCellDef="let element"> {{element.nr_ddt}} </td>
              </ng-container>
              <ng-container matColumnDef="data_ddt">
                <th mat-header-cell *matHeaderCellDef> Data ddt </th>
                <td mat-cell *matCellDef="let element"> {{element.data_ddt}} </td>
              </ng-container>
              <ng-container matColumnDef="imballo">
                <th mat-header-cell *matHeaderCellDef> Imballo </th>
                <td mat-cell *matCellDef="let element"> {{element.imballo}} </td>
              </ng-container>
          <!--     ['id', 'data_richiesta', 'colli', 'peso', 'valore', 'note', 'nr_ddt', 'data_ddt', 'imballo'] -->
            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
          </table>
          
    </mat-card-content>
</mat-card>

<mat-card  class="section"> 
    <mat-card-header>
        <mat-card-title>
            <h3>Richieste autorizzate</h3>
        </mat-card-title>
    </mat-card-header>
    <mat-card-content *ngIf="!approved.length">
        <ali-alert-box type="success" msg="Nessuna richiesta autorizzata"></ali-alert-box>
    </mat-card-content>
    <mat-card-content *ngIf="approved.length">
      
      <table mat-table [dataSource]="approved" class="mat-elevation-z8">

          <!--- Note that these columns can be defined in any order.
                The actual rendered columns are set as a property on the row definition" -->
          <!-- ID -->
          <ng-container matColumnDef="id">
            <th mat-header-cell *matHeaderCellDef> Nr. </th>
            <td mat-cell *matCellDef="let element"> {{element.id}} </td>
          </ng-container> 
          <!-- DATA RICHIESTA -->
          <ng-container matColumnDef="data_richiesta">
            <th mat-header-cell *matHeaderCellDef> Data richiesta </th>
            <td mat-cell *matCellDef="let element"> {{element.created_at  | date}} </td>
          </ng-container>
          <!-- COLLI -->
          <ng-container matColumnDef="colli">
            <th mat-header-cell *matHeaderCellDef> Nr. Colli </th>
            <td mat-cell *matCellDef="let element"> {{element.colli}} </td>
          </ng-container>
          <!-- PESO -->
          <ng-container matColumnDef="peso">
            <th mat-header-cell *matHeaderCellDef> Peso </th>
            <td mat-cell *matCellDef="let element"> {{element.peso}} </td>
          </ng-container>
          <!-- VALORE -->
          <ng-container matColumnDef="valore">
            <th mat-header-cell *matHeaderCellDef> Valore </th>
            <td mat-cell *matCellDef="let element"> {{element.valore}} </td>
          </ng-container>
          <!-- NOTE -->
          <ng-container matColumnDef="note">
            <th mat-header-cell *matHeaderCellDef> Note </th>
            <td mat-cell *matCellDef="let element"> {{element.note}} </td>
          </ng-container>
          <!-- NR DDT -->
          <ng-container matColumnDef="nr_ddt">
            <th mat-header-cell *matHeaderCellDef> Nr ddt </th>
            <td mat-cell *matCellDef="let element; let i = index" >
              <mat-form-field>
                <input 
                matInput 
                [(ngModel)]="n_ddt[i]" 
                type="number" 
                placeholder="Inserisci numero DDT" 
                name="n_ddt"
                required>
              </mat-form-field>
            </td>
          </ng-container>
          <!-- DATA DDT -->
          <ng-container matColumnDef="data_ddt">
            <th mat-header-cell *matHeaderCellDef> Data ddt </th>
            <td mat-cell *matCellDef="let element; let i = index"> 
              <mat-form-field>
                <mat-label>Seleziona una data</mat-label>
                <input 
                matInput 
                [(ngModel)]="data_ddt[i]" 
                [matDatepicker]="picker" 
                name="data_ddt"
                readonly
                required>
                <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                <mat-datepicker #picker></mat-datepicker>
              </mat-form-field>
            </td>
          </ng-container>
          <!-- IMBALLO -->
          <ng-container matColumnDef="imballo">
            <th mat-header-cell *matHeaderCellDef> Imballo </th>
            <td mat-cell *matCellDef="let element; let i = index">
              <mat-form-field>
                <mat-label>Imballo</mat-label>
                <mat-select [(ngModel)]="imballo[i]" name ="imballo" required>
                  <mat-option value="S">Scatola</mat-option>
                  <mat-option value="B">Bancale</mat-option>
                </mat-select>
              </mat-form-field>
            </td>
          </ng-container>

          <ng-container matColumnDef="save">
            <th mat-header-cell *matHeaderCellDef></th>
            <td mat-cell *matCellDef="let element; let i = index">
            <button 
            mat-raised-button color ="primary" 
            [disabled]="!n_ddt[i] || !data_ddt[i] || !imballo[i]" 
            type ="submit" 
            (click)="onSaveAssignment(element, n_ddt[i], data_ddt[i], imballo[i], i)"
            >
              Save
            </button>
            </td>
          </ng-container>
      <!--     ['id', 'data_richiesta', 'colli', 'peso', 'valore', 'note', 'nr_ddt', 'data_ddt', 'imballo'] -->
        <tr mat-header-row *matHeaderRowDef="displayedColumnsSave"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumnsSave;"></tr>
      </table>
      
  </mat-card-content>
</mat-card>



<mat-card  class="section"> 
  <mat-card-header>
      <mat-card-title>
          <h3>Rese inviate negli ultimi 6 mesi</h3>
      </mat-card-title>
  </mat-card-header>
  <mat-card-content *ngIf="!resi.length">
      <ali-alert-box type="success" msg="Nessuna resa inviata"></ali-alert-box>
  </mat-card-content>
  <mat-card-content *ngIf="resi.length">
    <table mat-table [dataSource]="resi" class="mat-elevation-z8">

        <!--- Note that these columns can be defined in any order.
              The actual rendered columns are set as a property on the row definition" -->
       
      
        <ng-container matColumnDef="data_richiesta">
          <th mat-header-cell *matHeaderCellDef> Data richiesta </th>
          <td mat-cell *matCellDef="let element"> {{element.created_at  | date}} </td>
        </ng-container>
       
         <ng-container matColumnDef="colli">
            <th mat-header-cell *matHeaderCellDef> Nr. Colli </th>
            <td mat-cell *matCellDef="let element"> {{element.colli}} </td>
          </ng-container>
          <ng-container matColumnDef="peso">
            <th mat-header-cell *matHeaderCellDef> Peso </th>
            <td mat-cell *matCellDef="let element"> {{element.peso}} </td>
          </ng-container>
          <ng-container matColumnDef="valore">
            <th mat-header-cell *matHeaderCellDef> Valore </th>
            <td mat-cell *matCellDef="let element"> {{element.valore}} </td>
          </ng-container>
          <ng-container matColumnDef="note">
            <th mat-header-cell *matHeaderCellDef> Note </th>
            <td mat-cell *matCellDef="let element"> {{element.note}} </td>
          </ng-container>
          <ng-container matColumnDef="nr_ddt">
            <th mat-header-cell *matHeaderCellDef> Nr ddt </th>
            <td mat-cell *matCellDef="let element"> {{element.nr_ddt}} </td>
          </ng-container>
          <ng-container matColumnDef="data_ddt">
            <th mat-header-cell *matHeaderCellDef> Data ddt </th>
            <td mat-cell *matCellDef="let element"> {{formatData(element.data_ddt)}} </td>
          </ng-container>
          <ng-container matColumnDef="imballo">
            <th mat-header-cell *matHeaderCellDef> Imballo </th>
            <td mat-cell *matCellDef="let element"> {{formatImballo(element.imballo)}} </td>
          </ng-container>
          <ng-container matColumnDef="id">
            <th mat-header-cell *matHeaderCellDef> </th>
            <td mat-cell *matCellDef="let element">  <mat-icon (click)="printEtichetta(element.id);" style="cursor:pointer">print</mat-icon></td>
          </ng-container> 
      <!--     ['id', 'data_richiesta', 'colli', 'peso', 'valore', 'note', 'nr_ddt', 'data_ddt', 'imballo'] -->
        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
      </table>
      
</mat-card-content>
</mat-card>