import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { DocumentService } from 'src/app/services/document.service';

@Component({
  selector: 'ali-resa-confirm',
  templateUrl: './resa-confirm.component.html',
  styleUrls: ['./resa-confirm.component.css']
})
export class ResaConfirmComponent implements OnInit {
  success:boolean = false;

  constructor(
    private activatedRoute: ActivatedRoute, 
    private documentservice: DocumentService) { 
  }

  ngOnInit() {
    const token = this.activatedRoute.snapshot.paramMap.get('token');
    this.checkToken(token);
  }
  
  checkToken(token){
    this.documentservice.checkTokenReso(token).subscribe((response:any)=>{
      this.success = response.success;
    })
  }
 
  
}
