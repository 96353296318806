import { OauthService } from './services/oauthservice.service';
import {SuccessOautResponse} from './models/oauth.model';
import {LocalStorageService} from './services/storage.service';
import {Injectable} from '@angular/core';
import {CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router} from '@angular/router';
import {Observable} from 'rxjs';
import {connectableObservableDescriptor} from 'rxjs/internal/observable/ConnectableObservable';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {
  constructor(
    private router: Router,
    private outhservice: OauthService  
    ) {}

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
     const requestPath  = next.url;
     if (!this.outhservice.checkTokenIsValid()){
      this.router.navigateByUrl('login');
      return false;
    } else {
      return true;
    }
  }


  /**
   * 
   * @returns boolean
   */
   
}
