<div *ngIf="logged" >
  <ali-nav-bar [items]="carrelloItems" [loading]="loadingCart"
  (toggleNavBar)="sideNav.toggle()"
  (toggleSideCart)="sideCart.toggle()"
></ali-nav-bar>

<mat-sidenav-container  class="sideNavContainer">
  <mat-sidenav #sideNav mode="side" [opened]="menuOpen">
    <ali-nav-menu   (menuClick)="menuClicked($event);"></ali-nav-menu>
  </mat-sidenav>
  <mat-sidenav #sideCart closed mode="over" position="end">
    <ali-cart [items]="carrelloItems" [loading]="loadingCart"></ali-cart>
  </mat-sidenav> 
  <mat-sidenav-content opened="false"> 
    <router-outlet></router-outlet>
  </mat-sidenav-content>
</mat-sidenav-container>
</div>
<router-outlet *ngIf="!logged" ></router-outlet>
<!-- <router-outlet></router-outlet> -->