import { Articolo } from 'src/app/models/catalogo.model';
import { Pipe, PipeTransform } from '@angular/core';
import { ArticoloTools } from 'src/app/models/articolo.model';
import * as _ from 'lodash';
@Pipe({
  name: 'statoArticolo'
})
export class StatoArticoloPipe implements PipeTransform {
  articoloTools: ArticoloTools = new ArticoloTools();
  transform(value: Articolo, ...args: unknown[]): string {
    return  this.articoloTools.stato(value);
  }

}
