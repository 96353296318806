<div mat-dialog-content>
  <h1 mat-dialog-title>{{data.element.titolo | bookTitle}}</h1>
  <div class="row">
    <div class=" col-3">
      <ali-image *ngIf="!user.isAdmin()"
                 type="thumb"
                 [codice]="data.element.codice"
      ></ali-image>
      <ali-image-upload *ngIf="user.isAdmin() && !loading"
                        [image]="immagineCopertina"
                        (changeImage)="setCopertina($event)"
                        title="Copertina Libro"
      ></ali-image-upload>
    </div>
    <div class=" col-9 ">
      <mat-tab-group>
        <mat-tab class="mat-tab-cho" label="Informazioni generali" style="margin:10px">
          <div class="container-fluid">
            <div class="row">
              <div class="col-12 col-md-12 pl-0">
                <div class="m-0">
                  <span class="colorDark">Autore: </span>
                  <span class="colorPrimary">{{data.element.autore | uppercase}}</span>
                  <span class="colorPrimary" *ngIf="data.element.autore2">, {{data.element.autore2 | uppercase}}</span>
                </div>
                <div class="m-0">
                  <span class="colorDark">Editore: </span>
                  <span class="colorPrimary">{{data.element.editore | uppercase}}</span>
                </div>
              </div>
            </div>
          </div>
           
            <div class="m-0">
              <span class="colorDark">ISBN: </span>{{data.element.ean}}
            </div>
            <div class="m-0">
              <span class="colorDark">Data di pubblicazione: </span>
              {{data.element.prevista_pubb}}
            </div>
            <div class="m-0" *ngIf="data.info.diritto_di_resa!== ' '">
              {{data.info.diritto_di_resa}}
            </div>
            <div class="m-0" *ngIf="data.element.collana!== ' '">
              <span class="colorDark">Collana:</span>
              {{data.element.collana}}
            </div>
            <div class="m-0" *ngIf="data.element.categoria!== ' '">
              <span class="colorDark">Categoria: </span>
              {{data.element.categoria}}
            </div>
            <div class="m-0" *ngIf="data.info.numero_pagine !== ' '">
              <span class="colorDark">Nr. Pagine:</span>
              {{data.info.numero_pagine}}
            </div>
            <div class="m-0">
              <span class="colorDark">Formato:</span>
              {{data.info.formato}} cm
            </div>
            <div class="m-0" *ngIf="data.info.peso > 0">
              <span class="colorDark">Peso:</span>
              {{data.info.peso}} Kg
            </div>
     

          <h3 class="price">{{price}} € </h3>

          <p class="abstract" *ngIf="data.info.abstract !==' ' || data.info.abstract !==''">
            {{data.info.abstract}}
          </p>

        </mat-tab>
        <mat-tab label="Contenuti multimediali">
          <ng-container *ngIf="!user.isAdmin()">
            <div class="row obj-df-df" *ngIf="!loading">
              <div *ngFor="let type of mediaTypes">
                <div class="col-4 obj-df-df">
                  <p class="cont-multi-tit-cent">{{type.label}}</p>
                  <div *ngFor="let item of mediaItems[type.type]; let i= index">
                    <img src="{{getIcon(type.img)}}"/>
                    <a [href]="item.path" target="_blank">{{basename(item.path)}}</a>
                  </div>
                  <div *ngIf="!mediaItems[type.type]">
                    <ali-alert-box class="text-center" type="warning" msg="Nessun file"></ali-alert-box>
                  </div>
                </div>
              </div>
            </div>
          </ng-container>
          <ali-admin-media-manager
            *ngIf="user.isAdmin()"
            [libro]="data.element"
            (copertinaCaricata)=caricaCopertina($event)
          ></ali-admin-media-manager>
        </mat-tab>
      </mat-tab-group>

    </div>
  </div> 

</div>
<hr>
<div mat-dialog-actions align="center">
  <button mat-button (click)="onClick()">Chiudi</button>
</div>
