<div class="family">
  <div class="col-4" *ngFor="let colonna of fileTypes">
    <h3>{{colonna.title}}</h3>

    <ali-media-block [loading]="uploadLoading"
                     [loadingType]="uploadType"
                     (uploadedFile)="submit($event)"
                     [type]="colonna.type"
                     [title]="colonna.title"
                     style="width:100%;"
                     [myclass]="colonna.color"
                     [acceptedMimeType]="colonna.mime"
    ></ali-media-block>

    <div class="container-fluid p-0 mt-4">
      <div *ngFor="let file of media; let last = last; let i = index" > 
        <div *ngIf="file.tipo_doc === colonna.title">
          <div class="row my-1">
            <div class="col-9">
              <img src="{{getIcon(colonna.icon)}}" class="fileIco">

              <a [href]="file.path" target="_blank" *ngIf="file.tipo_doc === 'VIDEO'" >
                VIDEO - {{i}}
              </a>
              <a [href]="file.path" target="_blank" *ngIf="file.tipo_doc !='VIDEO'" > {{basename(file.path)}}</a>
              
            </div>
            <div class="col-3">
              <button mat-icon-button color="warn" aria-label="Elimina file"
                      (click)="deleteItem(file.tipo_doc, file.path)"
                      [disabled]="deleting">
                <mat-icon>delete_outline</mat-icon>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>

  </div>
</div>
