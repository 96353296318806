import { MessageService } from './../../services/message.service';
import { CarrelloService } from './../../services/carrello.service';
import {Component, Input, OnInit} from '@angular/core';
import {CedolaProdotto} from '../../models/catalogo.model';
import moment from 'moment';
import { CardData } from 'src/app/models/carddata.model';
import { animate, state, style, transition, trigger } from '@angular/animations';
import { iUser } from 'src/app/models/user.model';
import { LocalStorageService } from 'src/app/services/storage.service';

@Component({
  selector: 'ali-libro-cedola',
  templateUrl: './libro-cedola.component.html',
  styleUrls: ['./libro-cedola.component.css'],
  animations: [
    trigger('cardFlip', [
      state('default', style({
        transform: 'none'
      })),
      state('flipped', style({
        transform: 'rotateY(180deg)'
      })),
      transition('default => flipped', [
        animate('400ms')
      ]),
      transition('flipped => default', [
        animate('200ms')
      ])
    ])
  ]
})
export class LibroCedolaComponent implements OnInit {
  @Input() prodotto:any;
  data: CardData = {
    imageId: "",
    state: "default"
  };
  src: string = null;
  errorLoadingImage = false;
  noImage = 'hide';
  imageClass = 'hide';
  loader = 'show';
  acquista = false;
  user:iUser = new iUser(this.storage.get('usr'));
  constructor(
    private carrelloservice: CarrelloService,
    private messageservice: MessageService,
    private storage: LocalStorageService
  ) {
    
  }

  hacaricato(){
    this.loader = 'hide';
    this.imageClass = 'show';
  }

  vainerrore(){
    this.loader = 'hide';
    this.imageClass = 'hide';
    this.noImage = 'show';
  }

  ngOnInit(): void {
    this.src = `https://amazonstorageali.s3.eu-south-1.amazonaws.com/img/${this.prodotto.codice}.jpg`;
  }

  formatData(data) {
    return moment(data, 'YYYYMMDD').format('DD-MM-YYYY');
  }
  
setAcquista() {
  if (this.data.state === "default") {
    this.data.state = "flipped";
  } else {
    this.data.state = "default";
  }
  this.acquista = !this.acquista;
}

  setQta($event) { 
    this.carrelloservice.setItemToCart($event.codice, $event.qta).subscribe((data) => {
      this.messageservice.setEvent({type : 'cart', event: data});
    });
  }

}
