import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { FormControl, NgForm } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import {Observable} from 'rxjs';
import {map, startWith} from 'rxjs/operators';
import { CatalogoService } from 'src/app/services/catalogo.service';
import { MessageService } from 'src/app/services/message.service';
import * as XLSX from 'xlsx'; 
import * as _ from 'lodash';  
import { CSVRecord } from '../importa-ordine/ordine.model';
import { Articolo, CheckResponse } from 'src/app/models/catalogo.model';
import { DocumentService } from 'src/app/services/document.service';
import { ToastrService } from 'ngx-toastr';
@Component({
  selector: 'ali-gestione-resi',
  templateUrl: './gestione-resi.component.html',
  styleUrls: ['./gestione-resi.component.css']
})
export class GestioneResiComponent implements OnInit {
  rappresentante = new FormControl();
  options: string[] = [];
  filteredOptions: Observable<string[]>;
  nr_colli;
  peso;
  note;
  agente;
  imballo = [
    'Scatola', 'Bancale'
  ];
  loader= false; 
  import = 0;
  isbns = [];
  unvalidISBNS:Array<string> = [];
  public records = []; 
  validElements = []; 
  displayedColumns: string[] = ['codiceISBN', 'quantita'];
  valore;
  rappresentanti:any = [];
  resi: any = [];
  imported: boolean = false;
  importedItems = [];
  requestResoSuccess: boolean = false;
  selectedTab = 0;
  /**  *public codiceISBN: any;
    public quantita: any;
     */
  constructor(
    private catalogoservice: CatalogoService,
    private _snackBar: MatSnackBar,
    private messageservice : MessageService,
    private documentservice: DocumentService, 
    private toast: ToastrService
  ) { }
    

  undoImport() {
    this.import= 0;
    this.records = [];
    this.imported =false;
    this.requestResoSuccess = false;
  }

  /**
   * onFileChange
   * analizza il file lato client
   * @param evt 
   */

  onFileChange(evt: any) {
    this.records = [];
    this.unvalidISBNS = [];
    this.isbns = [];
    /* wire up file reader */
    const target: DataTransfer = <DataTransfer>(evt.target);
    if (target.files.length !== 1) throw new Error('Cannot use multiple files');
    const reader: FileReader = new FileReader();
    reader.onload = (e: any) => {
      /* read workbook */
      const bstr: string = e.target.result;
      const wb: XLSX.WorkBook = XLSX.read(bstr, {type: 'binary'}); 
      /* grab first sheet */
      const wsname: string = wb.SheetNames[0];
      const ws: XLSX.WorkSheet = wb.Sheets[wsname];
      const data = XLSX.utils.sheet_to_json(ws, {header: 1});
      data.forEach((item: CSVRecord) => {
        this.isbns.push(item[0]);
        this.records.push({  
          codiceISBN: item[0], 
          quantita: item[1], 
        });
      });
      this.checkIbsns();
    };
    reader.readAsBinaryString(target.files[0]);
  }
  /**
   * checkIbsns
   */
  public checkIbsns() {
    this.catalogoservice.checkISBNS(this.isbns).subscribe((data: CheckResponse) => {
          if(data.errors.length) {
            data.errors.forEach((element) => {
                this.unvalidISBNS.push(element);
            });
          }
          this.validElements = data.valid;
          
    });
  }
  /**
   * fase finale dell'importazione
   */
  public doImport() {
    let items = [];  
    this.validElements.forEach((element:Articolo) => {
          const e = _.find(this.records, { 'codiceISBN': Number(element.ean) });
          items.push({cod : element.codice, qta: e.quantita});
      });
      this.importedItems = items;
      this.imported = true;
  }


  /**
   * 
   * @param isbn 
   * @returns 
   */
  isValid(isbn):boolean {
    const i = _.indexOf(this.unvalidISBNS, isbn);
    if (i > -1) {
      return false;
    }
    return true;
  }

/**
 * 
 * @param message 
 * @param action 
 */
  openSnackBar(message: string, action: string) {
    this._snackBar.open(message, action);
  }

  ngOnInit(): void {
    this.import= 0;
    this.filteredOptions = this.rappresentante.valueChanges.pipe(
      startWith(''),
      map(value => this._filter(value))
    );
    this.getRapprentante();
    this.requestResoSuccess = false;
    //this.getResi();
  }
/**
 * 
 * @param value 
 * @returns 
 */
  private _filter(value: string): string[] {
    const filterValue = value.toLowerCase(); 
    return this.options.filter(option => option.toLowerCase().indexOf(filterValue) === 0);
  }

  getRapprentante(){
    this.documentservice.getRappresentanti().subscribe(data => {
      this.rappresentanti = data;
    });
  }

  buildPayload() {
    const payload = {
      colli: this.nr_colli,
      agente : this.agente,
      peso : this.peso,
      valore : this.valore,
      note : this.note || '',
      merce :this.importedItems
    }

    return payload;
  }

  sendResoRequest(){
    this.documentservice.createReso(this.buildPayload()).subscribe((response:any)=>{
      this.requestResoSuccess = response.success;
      //this.requestResoSuccess = true;
      if(this.requestResoSuccess){
        this.agente = ' ';
        this.nr_colli = ' ';
        this.peso = ' ';
        this.valore = ' ';
        this.note =' ';
        this.import = 1;
      }else{
        this.toast.error(`Errore durante la creaione del reso, riprova`);
      }
      //this.undoImport();
    });
  }
  
  selectTab(event) {
    this.selectedTab = event;
  }
}

