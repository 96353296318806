import { OauthService } from 'src/app/services/oauthservice.service';
import { Component, OnInit } from '@angular/core';
import { animate, state, style, transition, trigger } from '@angular/animations'; 
import { CardData } from 'src/app/models/carddata.model';
import { FormControl, Validators } from '@angular/forms';

@Component({
  selector: 'ali-profilo',
  templateUrl: './profilo.component.html',
  styleUrls: ['./profilo.component.css'],
  animations: [
    trigger('detailExpand', [
      state('collapsed', style({height: '0px', minHeight: '0'})),
      state('expanded', style({height: '*'})),
      transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
    ]),
  ],
})
export class ProfiloComponent implements OnInit {
  password1 = '';
  password2 = '';
  message   = '';
  class     = '';
  data: CardData = {
    imageId: "",
    state: "default"
  };
   

  constructor( 
    private oauth: OauthService ) { }

  ngOnInit(): void {
  }
   

  public  changePassword() {  
     this.oauth.changePwd(this.password1).subscribe((data) => {
          this.message = "La tua password è stata cambiata";
          this.class   = 'success';
        }, (e) => {
          this.message = "Qualcosa è andato storto";
          this.class   = 'error';
    }); 
  }



}
