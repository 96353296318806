import {Injectable, Injector} from '@angular/core';
import {environment} from 'src/environments/environment';
import {LocalStorageService} from './storage.service';
import {SuccessOautResponse} from '../models/oauth.model';
import {HttpHeaders, HttpClient, HttpParams} from '@angular/common/http';
 
@Injectable({
  providedIn: 'root'
})
export class CarrelloService {
  API_BASE: string = environment[environment.env].base_url;

  constructor(
      private injector: Injector,
      private httpclient: HttpClient,
      private localStorage: LocalStorageService,
  ) {

  }

  public setItemToCart(cod, qta) {
    const serviceHeaders = this.getHeaders();
    const options = { headers: serviceHeaders };
      const url = `${this.API_BASE}/cart/${cod}/${qta}`;
      return this.httpclient.put(url, {}, options);

  }

  public getCart() {
    const serviceHeaders = this.getHeaders();
    const options = { headers: serviceHeaders };
    const url = `${this.API_BASE}/cart`;
    return this.httpclient.get(url, options);
  }

  public delItemFromCart(cod) {
    const serviceHeaders = this.getHeaders();
    const options = { headers: serviceHeaders };
    const url = `${this.API_BASE}/cart/${cod}`;
    return this.httpclient.delete(url, options);
  }

  public emptyTrash(){
    const serviceHeaders = this.getHeaders();
    const options = { headers: serviceHeaders };
    const url = `${this.API_BASE}/cart`;
    return this.httpclient.delete(url, options);
  }

  public sendOrder(payload = {}){
    const serviceHeaders = this.getHeaders();
    const options = { headers: serviceHeaders };
    const url = `${this.API_BASE}/ordine/send`;
    return this.httpclient.post(url, payload,options);
  }

  private getHeaders(): HttpHeaders {
    const token: SuccessOautResponse = this.localStorage.get('token');
    if (token) {
      const header = new HttpHeaders({
        'Content-Type': 'application/json',
        Accept: 'application/json',
        Authorization: 'Bearer ' + token.access_token,
    }); 
    return header;
    }
    const header = new HttpHeaders({
        'Content-Type': 'application/json',
        Accept: 'application/json'
    }); 
    return header;
}
}
