<mat-progress-spinner *ngIf="false"></mat-progress-spinner>
<mat-card class="scheda m-auto" [style.background-image]="getImage()">
  <mat-card-content class="p-3 ">
    <input #file type="file" accept='image/jpeg,image/jpg'
           (change)="setContent('file', $event)"
           class="hide"/>
<!--    (change)="addImage($event)"-->
    <div class="imageContainer center">
      <button class="m-1" mat-mini-fab color="primary" matTooltip="Aggiungi immagine"
              (click)="file.click()">
        <mat-icon>add</mat-icon>
      </button>
    </div>
  </mat-card-content>
</mat-card>
