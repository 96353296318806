<h1>{{data.element.titolo | bookTitle}}</h1>
<div style="height:100%; width:100%; display: flex; flex-wrap: wrap;">

  <ali-progress-bar style=" width:100%;" [flagaudio]="flagaudio" [itemSize]="progressBar"></ali-progress-bar>

  <ali-media-block [loading]="uploadLoading"
                   (uploadedFile)="submit($event)"
                   style="height: 18.5%; width:100%;"
                   myclass="#4685F4" type="file"
                   icon="audio-upload"
                   acceptedMimeType="audio/mpeg"
  ></ali-media-block>
  <ali-media-block [loading]="uploadLoading" (uploadedFile)="submit($event)" style="height:18.5%; width:100%;"
                   type="file" myclass="#36a954"
                   icon="img-upload" acceptedMimeType="image/jpeg"></ali-media-block>
  <ali-media-block [loading]="uploadLoading" (uploadedFile)="submit($event)" style="height:18.5%; width:100%;"
                   myclass="#f9bb0b" type="file"
                   icon="pdf-upload" acceptedMimeType="application/pdf"></ali-media-block>
  <ali-media-block [loading]="uploadLoading"
                   (uploadedFile)="submit($event)"
                   style="height:18.5%; width:100%;"
                   myclass="#F34949"
                   type="text"
                   icon="2"></ali-media-block>

</div>
<!-- <br>
<br>
<hr>
<div mat-dialog-actions align="center">
    <button mat-button (click)="onClick()">Chiudi</button>
</div> -->
