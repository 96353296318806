import { MessageService, eventModel } from './../../../services/message.service';
import { CarrelloService } from './../../../services/carrello.service';
import { User } from './../../../models/user.model';
import { LocalStorageService } from './../../../services/storage.service';
import { Component, OnInit } from '@angular/core';
import { Carrello, Cart, CartItem } from 'src/app/models/cartItem.model';
import { Router } from '@angular/router';
import * as _ from 'lodash';

@Component({
  selector: 'ali-riepilogo',
  templateUrl: './riepilogo.component.html',
  styleUrls: ['./riepilogo.component.css']
})
export class RiepilogoComponent implements OnInit {
  user:User = null;
  loadingCart = null;
  carrelloItems:Array<CartItem>
  totaleOrdine = 0;
  totaleNetto = 0;
  numeroArticoli = 0;
  numeroPezzi = 0;
  indirizzo = {
    indirizzo : '',
    cap : '',
    citta: '',
    prov: '',
    destinatario: ''
  }
  changeAddress = false;
  orderSent = false;
  //indirizzo modificato
  modifiedAddress = false;
  constructor(
    private storage: LocalStorageService, 
    private carrelloservice: CarrelloService,
    private messageservice: MessageService,
    private router: Router,
    ) { 
    this.user = this.storage.get('usr');
    this.indirizzo.citta = this.user.address.citta;
    this.indirizzo.cap   = this.user.address.cap;
    this.indirizzo.indirizzo = this.user.address.indirizzo;
    this.indirizzo.prov = this.user.address.prov;
    this.indirizzo.destinatario = this.user.name;
  }

  ngOnInit(): void {
    this.messageservice.listenEvent().subscribe((data:eventModel) => {
        if (data.type==='close-cart') {
          this.carrelloItems = data.event 
        }
    });
    this.getCarrello();
  }

/**
 * const cart = new Cart(items);
      return cart.totals; 
 */

  editAddress() {
    this.changeAddress= !this.changeAddress;
  }

  //controllo se l'indirizzo e' stato modificato
  addressChange(){
    const {destinatario, ...newObj} = this.indirizzo;
    this.modifiedAddress = !_.isEqual(this.user.address, newObj);
  }

  getCarrello() {
    this.loadingCart = true;
    this.carrelloservice.getCart().subscribe((data : Carrello) => { 
      if (data.success) { 
        this.carrelloItems = data.items;
        const cart = new Cart(data.items);
        this.totaleOrdine = cart.totals; 
        this.totaleNetto = cart.netto;
        this.numeroArticoli = data.items.length;
        this.numeroPezzi = cart.pieces;
      }
      this.loadingCart = false;
    }); 
  }

  remove(itemCode) {
    this.loadingCart = true;
    this.carrelloservice.delItemFromCart(itemCode).subscribe((data:Carrello) => {
      this.carrelloItems = data.items;
      const cart = new Cart(data.items);
      this.messageservice.setEvent({type:'adj-cart', event: data});
      this.totaleOrdine = cart.totals; 
      this.totaleNetto  = cart.netto;
      this.numeroArticoli = data.items.length;
      this.numeroPezzi = cart.pieces;
      this.loadingCart = false; 
    });  
  }
 
  aggiornaCarrello($event:Carrello) { 
    const cart = new Cart($event.items);
    this.totaleOrdine = cart.totals;   
    this.messageservice.setEvent({type:'adj-cart', event: $event});
  }

  buyNow(){
    let indirizzo = this.indirizzo;
    //se l'indirizzo non e' stato modificato
    if(!this.modifiedAddress){
      indirizzo = {
        indirizzo : '',
        cap : '',
        citta: '',
        prov: '',
        destinatario: ''
      }
    }
    this.carrelloservice.sendOrder(indirizzo).subscribe((data: any) => { 
      this.orderSent = data.success;
      this.messageservice.setEvent({type:'cart', event: data});
    })
  }

}
//  this.messageservice.setEvent({type:'cart'});