import { HttpClient, HttpHeaders } from '@angular/common/http';
import {Injectable} from '@angular/core';
import { SuccessOautResponse } from '../models/oauth.model';
import {environment} from 'src/environments/environment';
import { LocalStorageService } from './storage.service';


@Injectable({
  providedIn: 'root'
})
export class MediaService {

  API_BASE: string = environment[environment.env].base_url;

  constructor( private httpclient: HttpClient, private localStorage: LocalStorageService,) {
  }

  private getHeaders(): HttpHeaders {
    const token: SuccessOautResponse = this.localStorage.get('token');
    if (token) {
      const header = new HttpHeaders({
        'Content-Type': 'application/json',
        Accept: 'application/json',
        Authorization: 'Bearer ' + token.access_token,
    });
    return header;
    }
    const header = new HttpHeaders({
        'Content-Type': 'application/json',
        Accept: 'application/json'
    });
    return header;
}

  /**
   * carica un documento multimediale per "tipo" e "codiceAmicoWin"
   * @param formData
   * @param type
   * @param code
   */
  public uploadMedia(formData: FormData, type: string, code: string|number) {
    const token: SuccessOautResponse = this.localStorage.get('token');
    const header = new HttpHeaders({
      Accept: 'application/json',
      Authorization: 'Bearer ' + token.access_token,
    });
    const options = {headers: header}; //
    return this.httpclient.post(`${this.API_BASE}/documenti/${type}/${code}`, formData,options);
  }

  /**
   * elimina un file dallo storage
   * @param code codice libro amicowin
   * @param path percorso relativo del file
   */
  public deleteMedia(code: any, path: string) {
    const token: SuccessOautResponse = this.localStorage.get('token');
    const header = new HttpHeaders({
      Accept: 'application/json',
      Authorization: 'Bearer ' + token.access_token,
    });
    const options = {headers: header, body: {mediapath: path}};
    return this.httpclient.delete(`${this.API_BASE}/documenti/${code}`, options);
  }

  /**
   * ottiene media da un codice amicowin
   * @param code
   */
  public getMediaCollection(code:string) {
    const serviceHeaders = this.getHeaders();
    const options = { headers: serviceHeaders};
    return this.httpclient.get(`${this.API_BASE}/catalogo/${code}/documenti`, options);
  }


  getAdminStats() {
    const serviceHeaders = this.getHeaders();
    const options = { headers: serviceHeaders};
    return this.httpclient.get(`${this.API_BASE}/documenti/stats`, options);
  }
}
