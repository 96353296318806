import { Pipe, PipeTransform } from '@angular/core';
import * as _ from 'lodash';
@Pipe({
  name: 'statoDescrizione'
})
export class StatoDescrizionePipe implements PipeTransform {
  states = [
    {cod: 'A' , desc: 'Annullato'},
    {cod: ' ', desc: 'In ordine'},
    {cod:'B', desc: 'In spedizione'},
    {cod: 'R', desc: 'Rimanenza'},
    {cod: 'D', desc: 'Da approvare'},
    {cod: 'E', desc: 'Evaso'}
  ];  

  transform(value: string, ...args: unknown[]): unknown {
    if (value ==='' || value ===' ' ) {
      return 'In ordine'
    }
    const s = _.find(this.states, { 'cod': value});
        if (s) {
            return s.desc;
        }
        return value;
  }

}
