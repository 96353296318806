<nav class="docs-navbar-header header" aria-label="Top Toolbar">
  <button mat-icon-button aria-label="Mostra/nascondi menu" (click)="toggleMenu()">
    <mat-icon>menu</mat-icon>
  </button> 
  <img class="docs-angular-logo"
       src="../../../assets/images/logo2.jpg"
       alt="Ali home page" (click)="alihomepage();">
  <span (click)="alihomepage();">Agenzia Libraria International S.r.l.</span>
  <div class="flex-spacer"></div>
  <span *ngIf="User" >
    {{User.name}}
  </span> 
  <button mat-icon-button aria-label="Profilo" routerLink="/profilo" matTooltip="Il tuo profilo" >
    <mat-icon>manage_accounts</mat-icon>
  </button>
  <button mat-icon-button aria-label="Help"  matTooltip="Manuale" style="line-height:0px" >
    <a href="https://stage.alilibri.it/help/Home.html" target="_blank"><mat-icon>help</mat-icon></a>
  </button>
  <button mat-icon-button aria-label="Carrello" (click)="toggleCart()"  matTooltip="Il tuo carrello" *ngIf="User.isCliente()">
    <mat-icon [@openClose]="isOpen ? 'open' : 'closed'"  *ngIf="!loading && items && items.length>0"  matBadge="{{items.length}}" matBadgeColor="warn" >shopping_cart</mat-icon>
    <mat-icon *ngIf="!loading && items && items.length==0" >shopping_cart</mat-icon> 
    <mat-icon *ngIf="loading" >swap_horiz</mat-icon>
  </button>
  
  <span class="separator">|</span>
  <button mat-icon-button aria-label="Esci" (click)="logout()">
    <mat-icon>logout</mat-icon>
  </button>
</nav>
