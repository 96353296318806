import { environment } from './../../../../environments/environment';
import {formatCurrency} from '@angular/common';
import {Component, Inject, Input, OnInit} from '@angular/core';
import {MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import {Articolo} from 'src/app/models/catalogo.model';
import moment from 'moment';
import {MediaService} from 'src/app/services/media.service';
import * as _ from 'lodash';
import {iUser} from 'src/app/models/user.model';
import {LocalStorageService} from 'src/app/services/storage.service';
import {ToastrService} from 'ngx-toastr';
import {MessageService} from '../../../services/message.service';

export interface DisplayTableData {
  info: any;
  element: Articolo;
}

@Component({
  selector: 'ali-dettaglio',
  templateUrl: './dettaglio.component.html',
  styleUrls: ['./dettaglio.component.css']
})

export class DettaglioComponent implements OnInit {

  constructor(
    private storage: LocalStorageService,
    private mediaservice: MediaService,
    private toastservice: ToastrService,
    public dialogRef: MatDialogRef<DettaglioComponent>,
    private messageservice: MessageService,
    @Inject(MAT_DIALOG_DATA) public data: DisplayTableData) {
  }

  private copertina = new FormData();
  uploadLoading = false;
  immagineCopertina = null;
  user: iUser = new iUser(this.storage.get('usr'));
  price;
  mediaItems: any;
  mediaTypes = [
    // {type: 'ARTIMG', label: 'Immagine', img: 'png'},
    {type: 'PDF', label: 'Scheda libro', img: 'pdf'},
    {type: 'AUDIO', label: 'Audio', img: 'mp3'},
    {type: 'VIDEO', label: 'Video', img: 'mp4'},
  ];
  loading = false;

  ngOnInit(): void {
    this.getMediaItems(this.data.element.codice);
    this.price = formatCurrency(Number(this.data.element.prezzo), 'IT', '');
  }

  formatData(data) {
    if (Number(data) > 19999999) {
      return moment(data, 'YYYYMMDD').format('DD-MM-YYYY');
    }
    return '';
  }

  onClick(): void {
    this.dialogRef.close();
  }

  // restituisce i contenuti multimediali di un articolo
  getMediaItems(code) {
    this.loading = true;
    this.mediaservice.getMediaCollection(code).subscribe((data: any) => {
      this.mediaItems = _.groupBy(data.data, 'tipo_doc');
      if (this.mediaItems.ARTIMG) { 
        this.immagineCopertina =  this.mediaItems.ARTIMG[0].path; 
      }
      this.loading = false;
    });
  }

  caricaCopertina(e) {
    this.immagineCopertina = e;
  }

  setCopertina(event): void {
    this.uploadLoading = true;
    this.mediaservice.uploadMedia(event.content, event.type, this.data.element.codice).subscribe(resp => {
      this.messageservice.setEvent({type: 'reload', event: this.data.element.codice});
      this.toastservice.success('File caricato con successo.');
      this.uploadLoading = false;
    }, (error) => {
      this.toastservice.error('Errore durante il caricamento del file, Riprova.');
      this.uploadLoading = false;
    });
  }

  // ritorna il basename formattato di un url
  basename(path) {
    let base = new String(path).substring(path.lastIndexOf('/') + 1);
    if (base.lastIndexOf('.') != -1) {
      base = base.substring(0, base.lastIndexOf('.'));
    }
    return base.replace(/[^a-zA-Z0-9]/g, ' ');
  }

  getIcon(ext: string) {
    return '/assets/file-iconset/32px/' + ext + '.png';
  }
}
