import { MessageService } from './../../services/message.service';
import { CarrelloService } from './../../services/carrello.service';
import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {Carrello, Cart, CartItem} from '../../models/cartItem.model';

@Component({
  selector: 'ali-cart-item',
  templateUrl: './cart-item.component.html',
  styleUrls: ['./cart-item.component.css']
})
export class CartItemComponent implements OnInit {
  @Input() item: CartItem;
  @Output() removeItemFromCart: EventEmitter<string> = new EventEmitter<string>();
  @Output() setItem: EventEmitter<Carrello> = new EventEmitter<Carrello>();
  lastevent;
  constructor(private carrelloservice:CarrelloService, private messageservice:MessageService) { }

  ngOnInit(): void {
  }
  
  delete(code: string){
    this.removeItemFromCart.emit(code);
  }

  qtaChanged($event) {  
    if (this.lastevent!=$event) {
      this.lastevent = $event;
    } 
    else {
      this.carrelloservice.setItemToCart(this.item.cod, $event).subscribe((data:Carrello) => {  
          this.setItem.emit(data);
          this.messageservice.setEvent({type:'adj-cart', event: data});
       }); 
    } 
  }

  sconto(item: CartItem) {
    const lordo =    (item.prezzo*item.qta);

    if (item.defiscalizzato > 0 ) {
      const lordodef = lordo - (lordo * item.defiscalizzato / 100);
      const netto = lordo - (lordodef * item.sconto/ 100);
      return netto;
    } else { 
      const netto = lordo - (lordo * item.sconto/ 100);
      return netto;
    }
    /**
     * 
     */
  }

}
