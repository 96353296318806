import {Component, ElementRef, EventEmitter, Input, OnInit, Output, SimpleChanges} from '@angular/core';
import {MediaService} from 'src/app/services/media.service';
import * as _ from 'lodash';
import {ToastrService} from 'ngx-toastr';
import {FormControl} from '@angular/forms';
import {ViewChild} from '@angular/core';

@Component({
  selector: 'ali-media-block',
  templateUrl: './media-block.component.html',
  styleUrls: ['./media-block.component.css']
})
export class MediaBlockComponent implements OnInit {
  @Input() myclass: '';
  @Input() color: 'white';
  @Input() type: '';
  @Input() title: '';
  @Input() icon: '';
  @Input() loading: false;
  @Input() loadingType = '';
  @Input() acceptedMimeType: '';
  @Output() uploadedFile: EventEmitter<any> = new EventEmitter<any>();
  formCtrl: FormControl = new FormControl();
  fileTypes = {
    'application/pdf': 'PDF',
     'image/jpeg': 'ARTIMG',
    'audio/mpeg': 'AUDIO',
  };
  videoInput: string = '';
  formData: FormData = new FormData();
  filename: string = '';
  disableSubmit: boolean = true;
  @ViewChild('file') inputFile: ElementRef;

  constructor(private mediaservice: MediaService, private toastservice: ToastrService) {
  }

  ngOnInit(): void {
    this.formCtrl.valueChanges.subscribe(data => {
      if (data.length > 0) {
        //const match = data.match();
        //this.formCtrl.hasError('/(?:(?:https?|ftp|file):\/\/|www\.|ftp\.)(?:\([-A-Z0-9+&@#\/%=~_|$?!:,.]*\)|[-A-Z0-9+&@#\/%=~_|$?!:,.])*(?:\([-A-Z0-9+&@#\/%=~_|$?!:,.]*\)|[A-Z0-9+&@#\/%=~_|$])/igm')
        //console.log(match);
        this.disableSubmit = false;
        this.videoInput = data;
      } else {
        this.disableSubmit = true;
      }

    });
  }

  /**
   *
   */
  ngOnChanges(changes: SimpleChanges): void {
    if (changes.loading !== undefined && changes.loading.currentValue) {
      this.loading = changes.loading.currentValue;
    }

  }

  submit(type: string) {
    if (type === 'file') {
      const file: any = this.formData.getAll('file')[0];
      this.uploadedFile.emit({type: this.fileTypes[file.type], title: this.title, content: this.formData});
      this.filename = '';
      this.disableSubmit = true;
      this.formData.delete('file');
      this.inputFile.nativeElement.value = '';
    } else {
      this.appendFormData('text', this.videoInput);
      this.uploadedFile.emit({type: 'VIDEO', title: this.title, content: this.formData});
      this.formCtrl.reset();
      this.formData.delete('text');
      this.videoInput = '';
      this.disableSubmit = true;
    }
  }

  //controlla il file dato in input
  setContent(key, content) {
    if (_.indexOf(this.acceptedMimeType.split(','), content.type) > -1) {
      this.filename = content.name;
      this.disableSubmit = false;
      this.appendFormData(key, content);
    } else {
      this.toastservice.warning('Il file non corrisponde al tipo selezionato');
    }

  }

  //imposta valore all'oggetto formData
  appendFormData(key, content) {
    if (this.formData.has(key)) {
      this.formData.delete(key);
    }
    this.formData.append(key, content);
  }

  undoFile(key) {
    if (this.formData.has(key)) {
      this.formData.delete(key);
      this.filename = '';
      this.disableSubmit = true;
    }
  }
}
